import axios from "axios";
import Key from "../clientVariables.json";
import moment from "moment";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";
import ToastMessage from "../Components/ToastMessage";
import { jsResumeDetails } from "../Components/Actions/resumeSearchActions";

const loginListeners = [];

class DashboardService {
  /**
   * Get logged user
   */

  async updateCandResumeDetails(profiledetails,jobtypeIds, resume_id, uid, session_token ,template_number ,updateTemplate) {
    console.log(updateTemplate)
    console.log(profiledetails.city)
    console.log(profiledetails)
    let resumeid = resume_id;
    let user_id = uid;
    let jsapplication_title = profiledetails.currentrole;
    let jsname = profiledetails.fullname;
    let jscountry = profiledetails.country;
    let jsstate = profiledetails.state;
    let jscity = profiledetails.city;
    let experience = profiledetails.experience;
    let jsstatus = 1;
    let jsvisatype = profiledetails.visatype;
    let jssalarytype = profiledetails.salarytype;
    let jssalary = profiledetails.salary;
    let jsjobtype=jobtypeIds;
    let templatenumber = template_number

    return new Promise((resolve, reject) => {
      axios
        .put(`${Key.domain}/resume/update_js_profile_details`, {
          user_id: user_id,
          resumeid: resumeid,
          jsapplication_title: jsapplication_title,
          jsname: jsname,
          jsrole: jsapplication_title,
          experience: experience,
          jsstate: jsstate,
          jscity: jscity,
          jscountry: jscountry,
          jsstatus: jsstatus,
          experience: experience,
          jobtype:jsjobtype,
          jsvisatype: jsvisatype,
          jssalarytype: jssalarytype,
          jssalary: jssalary,
          session_token: session_token,
          templatenumber : templatenumber
        })
        .then(async (response) => {
          console.log(response);
          this.updateResumeDetailsToSolr(resumeid, user_id, session_token);
          resolve(response.data);
          if(updateTemplate === true){
            let msg = "Template Updated Successfully ";
            toast(<ToastMessage message={msg} />);
          }
          else {
            let msg = "Profile Updated Successfully ";
            toast(<ToastMessage message={msg} />);
          }
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  async updateResumeContactDetails(
    contactdetails,
    resume_id,
    uid,
    session_token
  ) {
    let resumeid = resume_id;
    let user_id = uid;
    let phone = contactdetails.phoneno;
    let email_address = contactdetails.email;
    let skype_id = contactdetails.skype;
    let jssession_token = session_token;
    console.log(contactdetails);
    return new Promise((resolve, reject) => {
      axios
        .put(`${Key.domain}/resume/update_js_resume_contact_details`, {
          user_id: user_id,
          resumeid: resumeid,
          skype_id: skype_id,
          jsContactNumber: phone,
          jsemail: email_address,
          session_token: jssession_token,
        })
        .then(async (response) => {
          console.log(response);
          this.updateResumeDetailsToSolr(resume_id, user_id, jssession_token);
          resolve(response.data);

          let msg = "Contact Updated Successfully ";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  async updateResumeCareerDetails(
    careerDetails,
    resume_id,
    uid,
    session_token
  ) {
    console.log(careerDetails);
    let resumeid = resume_id;
    let user_id = uid;
    let careerObjective = careerDetails.careerobjective;
    let jssession_token = session_token;
    let updatedataof = "careerobj";
    return new Promise((resolve, reject) => {
      axios
        .post(`${Key.domain}/resume/update_obj_skills_summary`, {
          user_id: user_id,
          resumeid: resumeid,
          careerobj: careerObjective,
          updatedataof: updatedataof,
          session_token: jssession_token,
        })
        .then(async (response) => {
          console.log(response);
          this.updateResumeDetailsToSolr(resume_id, user_id, jssession_token);
          resolve(response.data);

          let msg = "Career Updated Successfully";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  async updateResumeSkills(skills, resume_id, uid, session_token) {
    let resumeid = resume_id;
    let user_id = uid;
    let section_skills = skills;
    let jssession_token = session_token;
    let updatedataof = "skills";
    console.log(skills, jssession_token, updatedataof);
    return new Promise((resolve, reject) => {
      axios
        .post(`${Key.domain}/resume/update_obj_skills_summary`, {
          user_id: user_id,
          resumeid: resumeid,
          skills: section_skills,
          updatedataof: updatedataof,
          session_token: jssession_token,
        })
        .then(async (response) => {
          console.log(response);
          this.updateResumeDetailsToSolr(resume_id, user_id, jssession_token);
          resolve(response.data);

          let msg = "Skills Updated Successfully";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  async updateResumeSummaryDetails(summary, resume_id, uid, session_token) {
    let resumeid = resume_id;
    let user_id = uid;
    let jssummary = summary.summary;
    let jssession_token = session_token;
    let updatedataof = "summary";
    console.log(summary);
    return new Promise((resolve, reject) => {
      axios
        .post(`${Key.domain}/resume/update_obj_skills_summary`, {
          user_id: user_id,
          resumeid: resumeid,
          summary: jssummary,
          updatedataof: updatedataof,
          session_token: jssession_token,
        })
        .then(async (response) => {
          console.log(response);
          this.updateResumeDetailsToSolr(resume_id, user_id, jssession_token);
          resolve(response.data);

          let msg = "Summary Updated Successfully ";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  async addResumeProjectDetails(projects, resume_id, uid, session_token) {
    // let resumeid = resume_id
    // let user_id = uid
    // let phone = contactdetails.phoneno
    // let email_address = contactdetails.email
    // let skype_id = contactdetails.skype
    // let jssession_token = session_token

    console.log(projects);
    return new Promise((resolve, reject) => {
      axios
        .post(`${Key.domain}/resume/add_new_js_project`, {
          user_id: uid,
          resumeid: resume_id,
          Client_name: projects.client,
          Project_title: projects.projectTitle,
          Pro_role: projects.Role,
          Pro_zipcode: 0,
          Pro_description: projects.projectDescription,
          Pro_responsibilities: projects.projectResponsibilitites,
          Pro_startdate: projects.startDate,
          Pro_state: projects.state,
          Pro_city: projects.city,
          country: projects.country,
          Pro_enddate: projects.endDate,
          session_token: session_token,
        })
        .then(async (response) => {
          console.log(response);
          this.updateResumeDetailsToSolr(resume_id, uid, session_token);
          resolve(response.data);

          let msg = "Project Added Successfully";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  async addResumeEducationDetails(
    educationdetails,
    resume_id,
    education_id,
    uid,
    session_token
  ) {
    console.log(educationdetails);
    return new Promise((resolve, reject) => {
      axios
        .post(`${Key.domain}/resume/add_or_update_js_education_details`, {
          user_id: uid,
          resumeid: resume_id,
          postdata: [
            {
              id: education_id !== "" ? education_id : "",
              edu_course: educationdetails.course,
              //edu_university: null,
              //edu_passing_year: null
            },
          ],
          candidateid: 0,
          session_token: session_token,
        })
        .then(async (response) => {
          console.log(response);
          this.updateResumeDetailsToSolr(resume_id, uid, session_token);
          resolve(response.data);

          let msg = "Education Added Successfully";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  async addResumeSkillsCategories(
    skilldetails,
    resume_id,
    categoryId,
    uid,
    session_token
  ) {
    console.log(skilldetails);
    return new Promise((resolve, reject) => {
      axios
        .post(`${Key.domain}/resume/add_or_update_skillsCategories`, {
          user_id: uid,
          resumeid: resume_id,
          postdata: [
            {
              id: categoryId !== "" ? categoryId : "",
              category_id: skilldetails.category_id,
              category_name: skilldetails.category_name,
              skills : skilldetails.skills
            },
          ],
          candidateid: 0,
          session_token: session_token,
        })
        .then(async (response) => {
          console.log(response);
          this.updateResumeDetailsToSolr(resume_id, uid, session_token);
          resolve(response.data);

          let msg = "Skills Added Successfully";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  async addResumeCertificateDetails(
    certificatedetails,
    resume_id,
    certificateid,
    uid,
    session_token
  ) {
    console.log(certificatedetails);
    return new Promise((resolve, reject) => {
      axios
        .post(`${Key.domain}/resume/add_or_update_js_certificate_details`, {
          user_id: uid,
          resumeid: resume_id,
          postdata: [
            {
              id: certificateid !== "" ? certificateid : "",
              cer_name: certificatedetails.certificateName,
              cer_year: certificatedetails.yearofCertificate,
            },
          ],
          candidateid: 0,
          session_token: session_token,
        })
        .then(async (response) => {
          console.log(response);
          this.updateResumeDetailsToSolr(resume_id, uid, session_token);
          resolve(response.data);

          let msg = "Certification Added Successfully";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  async updateResumeProjectDetails(
    projects,
    projectid,
    resume_id,
    uid,
    session_token
  ) {
    // let resumeid = resume_id
    // let user_id = uid
    // let phone = contactdetails.phoneno
    // let email_address = contactdetails.email
    // let skype_id = contactdetails.skype
    // let jssession_token = session_token
    console.log("projects" , projects);
    console.log(projectid, uid, session_token);
  
    
    return new Promise((resolve, reject) => {
      axios
        .put(`${Key.domain}/resume/update_js_project_details`, {
          user_id: uid,
          projectid: projectid,
          jsapplication_title: projects.Role,
          client: projects.client,
          title: projects.projectTitle,
          role: projects.Role,
          description: projects.projectDescription,
          Pro_Responsibilities: projects.projectResponsibilitites,
          startdate: projects.startDate,
          stateid: projects.state,
          city: projects.city,
          country: projects.country,
          enddate: projects.checked === true ? "Present" :  projects.endDate,
          session_token: session_token,
        })
        .then(async (response) => {
          console.log(response);
          resolve(response.data);

          let msg = "Project Updated Successfully ";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  getmyfilesResumeDetails(resume_id, uid, session_token) {
    var details = new Promise((resolve, reject) => {
      axios
        .post(`${Key.domain}/resume/get_js_resume_details`, {
          resumeid: resume_id,
          user_id: uid,
          session_token: session_token,
        })
        .then((response) => {
          console.log(response);
          var resume = response.data.data;
          console.log(resume);
          if (!resume.message) {
            sessionStorage.setItem("myfiledetails", JSON.stringify(resume));
            resolve(resume);
          } else {
            var res = null;
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });

    return details;
  }

  deleteProject(projectid, resume_id, user_id, session_token) {
    console.log(projectid, resume_id, user_id, session_token);
    return new Promise((resolve, reject) => {
      fetch(`${Key.domain}/resume/delete_js_resume_projects`, {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          projectid: projectid,
          session_token: session_token,
        }),
      })
        .then(async (response) => {
          resolve(response.data);

          let msg = "Project Deleted Successfully ";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  deleteResumeEducation(education_id, resume_id, user_id, session_token) {
    console.log(education_id, resume_id, user_id, session_token);
    return new Promise((resolve, reject) => {
      fetch(`${Key.domain}/resume/delete_js_resume_education`, {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          educationid: [education_id],
          session_token: session_token,
        }),
      })
        .then(async (response) => {
          resolve(response.data);

          let msg = "Education Deleted Successfully ";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  deleteResumeEducation(education_id, resume_id, user_id, session_token) {
    console.log(education_id, resume_id, user_id, session_token);
    return new Promise((resolve, reject) => {
      fetch(`${Key.domain}/resume/delete_js_resume_education`, {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          educationid: [education_id],
          session_token: session_token,
        }),
      })
        .then(async (response) => {
          resolve(response.data);

          let msg = "Successfully Deleted";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  deleteResumeCertificate(certificate_id, resume_id, user_id, session_token) {
    console.log(certificate_id, resume_id, user_id, session_token);
    return new Promise((resolve, reject) => {
      fetch(`${Key.domain}/resume/delete_js_resume_certificates`, {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          certificateid: [certificate_id],
          session_token: session_token,
        }),
      })
        .then(async (response) => {
          resolve(response.data);

          let msg = "Certification Deleted Successfully ";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  deleteResumeSkillCategory(category_id, resume_id, user_id, session_token) {
    console.log(category_id, resume_id, user_id, session_token);
    return new Promise((resolve, reject) => {
      fetch(`${Key.domain}/resume/delete_js_resume_skill_category`, {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          categoryid: [category_id],
          session_token: session_token,
        }),
      })
        .then(async (response) => {
          resolve(response.data);

          let msg = "Category Deleted Successfully ";
          toast(<ToastMessage message={msg} />);
        })
        .catch((error) => {
          reject(error);

          let msg = "Something went wrong!";
          toast(<ToastMessage message={msg} />);
        });
    });
  }

  getFileDetails() {
    if (sessionStorage.getItem("myfiledetails")) {
      try {
        const resume = JSON.parse(sessionStorage.getItem("myfiledetails"));
        console.log(resume);
        if (resume !== null) {
          ////console.log.log(resume)
          return resume;
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    } else {
      var data = {
        resumedetails: {},
        projectdetails: [],
        educationdetails: [],
        certificatedetails: [],
      };
      return data;
    }
  }

  async updateResumeDetailsToSolr(
    resume_id,
    uid,
    session_token,
    FromBulkResume
  ) {
    let resumeid = resume_id;
    let user_id = uid;
    let jssession_token = session_token;

    axios
      .post(`${Key.domain}/resume/get_js_resume_details`, {
        user_id: user_id,
        resumeid: resumeid,
        session_token: jssession_token,
      })
      .then(async (response) => {
        console.log(response);
        if (
          response &&
          response.status === 200 &&
          response.data.data &&
          response.data.data.resumedetails
        ) {
          var resumedetails = response.data.data.resumedetails;
          console.log("resumedetails", resumedetails);
          axios
            .post(`${Key.domain}/resume/js_add_resume_to_solr`, {
              created:
                resumedetails.created &&
                resumedetails.created !== undefined &&
                resumedetails.created !== null
                  ? resumedetails.created
                  : null,
              user_id: user_id,
              session_token: jssession_token,
              resume_id: resumeid,
              application_title: resumedetails.application_title,
              email_address: resumedetails.email_address || "",
              search_flag: resumedetails.isPrimary,
              full_name: resumedetails.full_name || "",
              res_fileName: resumedetails.filename.split('.').slice(0, -1).join('.') + ".pdf" || "",
              location_state: resumedetails.location_state || "",
              address_city: resumedetails.address_city || "",
              searchable: resumedetails.searchable === 1 ? true : false,
              section_skills: resumedetails.section_skills || "",
              ai_skills: resumedetails.ai_skills || "",
              summary: resumedetails.summary || "",
              home_phone: resumedetails.home_phone || "",
              jobtype: resumedetails.jobtype || "",
              total_experience: resumedetails.total_experience || "",
              visatype: resumedetails.visatype || "",
              OPT_issued_year: "2000",
              grad_year: resumedetails.graduation_year || "",
              res_stateName: resumedetails.statename || "",
              res_cityName: resumedetails.cityname || "",
              res_jobtypeName: resumedetails.jobtypeNames || "",
              skillName: resumedetails.skillNames || "",
              profile_rating: resumedetails.profile_rating || "3.5",
              country: resumedetails.country_id || "",
              qualificationId: resumedetails.heighestfinisheducation || "",
              linkedin_url: resumedetails.linkedin_url || "",
              salary: resumedetails.desired_salary || "",
              salary_type: resumedetails.salary_type || "",
              res_countryName: resumedetails.countryname || "",
              res_shortRegion: resumedetails.shortRegion || "",
              res_visatypeName: resumedetails.visatype_name || "",
              res_salarrytypeName: resumedetails.jobsalaryrangetypeName || "",
              qualificationTitle: resumedetails.qualififcationtitle || "",
              willingToRelocate:
                resumedetails.willing_to_relocate !== undefined &&
                resumedetails.willing_to_relocate === 1
                  ? true
                  : false,
              viewed_by:
                resumedetails.viewed_by && resumedetails.viewed_by !== undefined
                  ? resumedetails.viewed_by
                  : "",
            })
            .then(async (response) => {
              console.log(response);
              

              const history = createBrowserHistory();

              history.push(
                sessionStorage.getItem("usertype") &&
                  sessionStorage.getItem("usertype") === "3"
                  ? "/resume/edit/resume_"+resumeid
                  : "/resume/edit/resume_"+resumeid
              ); 
            //    let msg = "Resume Updated to Solr Successfully";
            //   toast(<ToastMessage message={msg} />);
            

              console.log(history);
              if (FromBulkResume === "FromBulkResume") {
                history.push({
                  pathname: "/bs_filemanager",
                });
                window.location.reload();
              }
            });
        }
      })
      .catch((error) => {
        let msg = "Something went wrong!";
        toast(<ToastMessage message={msg} />);
        if (FromBulkResume === "FromBulkResume") {
          this.props.history.push({
            pathname: "/bs_filemanager",
          });
        }
      });
  }
  

  async updateParsedResumeDetailsToSolr(resume_id, fromParsedResumesList) {
    let resumeid = resume_id;

    axios
      .post(`${Key.domain}/parser/get_parser_resume_details`, {
        resumeid: resumeid,
      })
      .then(async (response) => {
        console.log(response);
        if (
          response &&
          response.status === 200 &&
          response.data.data &&
          response.data.data.resumedetails
        ) {
          var resumedetails = response.data.data.resumedetails;
          console.log("resumedetails", resumedetails);
          await axios
            .post(`${Key.domain}/resume/js_add_resume_to_solr`, {
              created:
                resumedetails.created &&
                resumedetails.created !== undefined &&
                resumedetails.created !== null
                  ? resumedetails.created
                  : null,
              user_id: resumedetails.uid,
              // session_token: jssession_token,
              resume_id: resumeid,
              lastmodified: fromParsedResumesList,
              application_title: resumedetails.application_title,
              email_address: resumedetails.email_address || "",
              search_flag: 1,
              full_name: resumedetails.full_name || "",
              res_fileName: resumedetails.filename.split('.').slice(0, -1).join('.') + ".pdf" || "",
              location_state: resumedetails.location_state || "",
              address_city: resumedetails.address_city || "",
              searchable: resumedetails.searchable === 1 ? true : false,
              section_skills: resumedetails.section_skills || "",
              ai_skills: resumedetails.ai_skills || "",
              summary: resumedetails.summary || "",
              home_phone: resumedetails.home_phone || "",
              jobtype: resumedetails.jobtype || "",
              total_experience: resumedetails.total_experience || "",
              visatype: resumedetails.visatype || "",
              OPT_issued_year: "2000",
              grad_year: resumedetails.graduation_year || "",
              res_stateName: resumedetails.statename || "",
              res_cityName: resumedetails.cityname || "",
              res_jobtypeName: resumedetails.jobtypeNames || "",
              skillName: resumedetails.skillNames || "",
              profile_rating: resumedetails.profile_rating || "3.5",
              country: resumedetails.country_id || "",
              qualificationId: resumedetails.heighestfinisheducation || "",
              linkedin_url: resumedetails.linkedin_url || "",
              salary: resumedetails.desired_salary || "",
              salary_type: resumedetails.salary_type || "",
              res_countryName: resumedetails.countryname || "",
              res_shortRegion: resumedetails.shortRegion || "",
              res_visatypeName: resumedetails.visatype_name || "",
              res_salarrytypeName: resumedetails.jobsalaryrangetypeName || "",
              qualificationTitle: resumedetails.qualififcationtitle || "",
              willingToRelocate:
                resumedetails.willing_to_relocate !== undefined &&
                resumedetails.willing_to_relocate === 1
                  ? true
                  : false,
              viewed_by:
                resumedetails.viewed_by && resumedetails.viewed_by !== undefined
                  ? resumedetails.viewed_by
                  : "",
            })
            .then(async (response) => {
              console.log(response);
            });
        }
      })
      .catch((error) => {
        let msg = "Something went wrong!";
        toast(<ToastMessage message={msg} />);
      });
  }
}

export default new DashboardService();
