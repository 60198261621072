export const UPDATE_RESUME_SEARCH_PARAMS = 'UPDATE_RESUME_SEARCH_PARAMS';
export const UPDATE_RESUME_FILTER_PARAMS = 'UPDATE_RESUME_FILTER_PARAMS';
export const UPDATE_RESUME_PAGINATION_PARAMS = 'UPDATE_RESUME_PAGINATION_PARAMS';
export const FETCH_RESUME_RESULTS_SUCCESS = 'FETCH_RESUME_RESULTS_SUCCESS';
export const FETCH_RESUME_RESULTS_FAILURE = 'FETCH_RESUME_RESULTS_FAILURE';
export const SET_SELECTED_RESUME = 'SET_SELECTED_RESUME';
export const CLEAR_RESUME_SEARCH_RESULTS = 'CLEAR_RESUME_SEARCH_RESULTS';
export const SOLR_QUERY_WITHOUT_FILTERS = 'SOLR_QUERY_WITHOUT_FILTERS'; 
export const JS_RESUME_DETAILS_SUCCESS = 'JS_RESUME_DETAILS_SUCCESS'
export const JS_RESUME_DETAILS_FAIL = 'JS_RESUME_DETAILS_FAIL';



export const UPDATE_JOB_SEARCH_PARAMS = 'UPDATE_JOB_SEARCH_PARAMS';
export const UPDATE_JOB_FILTER_PARAMS = 'UPDATE_JOB_FILTER_PARAMS';
export const UPDATE_JOB_PAGINATION_PARAMS = 'UPDATE_JOB_PAGINATION_PARAMS';
export const FETCH_JOB_RESULTS_SUCCESS = 'FETCH_JOB_RESULTS_SUCCESS';
export const FETCH_JOB_RESULTS_FAILURE = 'FETCH_JOB_RESULTS_FAILURE';
export const SET_SELECTED_JOB = 'SET_SELECTED_JOB';
export const CLEAR_JOB_SEARCH_RESULTS = 'CLEAR_JOB_SEARCH_RESULTS';
export const JOB_DETAILS = 'JOB_DETAILS';
export const JOB_DETAILS_FAIL = 'JOB_DETAILS_FAIL';
