import { Avatar, Box, Grid, Paper, Switch, Typography , IconButton,Alert,Button,Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'react-toastify';
import { useResponsiveStyles } from '../../../../Styles/responsiveStyles';
import palette from '../../../../theme/palatte';
import { getResumeLocation } from '../../../../utils/getLocation';
import PdfViewer from '../../../searchResumes/PdfViewer';
import { TiSocialLinkedin } from 'react-icons/ti';
import OverView from './components/Overview';
import { AirplanemodeActiveRounded, BusinessCenterRounded, CallRounded, EmailRounded, HowToRegRounded, LocationOnRounded } from '@mui/icons-material';
import ResumeSkills from './components/ResumeSkills';
import ResumeSummary from './components/ResumeSummary';
import ResumeWorks from './components/ResumeWorks';
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MailCandidate from '../../../searchResumes/sendcandidatetomail';
import Messagepopup from '../../../searchResumes/Messagepopup';
import CloseIcon from "@mui/icons-material/Close";
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import Key from '../../../../../src/clientVariables.json'



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DetailsView = ({limitExceeded, resumepackageExpired}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchState = useSelector((state) => state.searchResumeReducer);
  const { searchParams = {}, filterParams = {}, results = [], numFound,selectedResume = null, error } = searchState || {};
  const currentUser = useSelector((state) => state.UserReducer.user);
  const [customerDetailsData, setCustomerDetailsData] = useState(null);
  const [value, setValue] = useState(0);
  const styles = useResponsiveStyles();
  const [active, setActive] = useState(true);
  const [tomail, settomail] = useState("");
  const [candfullname, setcandfullname] = useState("");
  const [mailshow, setmailShow] = useState(false);
  const [messageshow, setmessageShow] = useState(false);
  const [savedLoading, setSavedLoading] = useState(false);
  const [resumeSavedStatus, setResumeSavedStatus] = useState(false);

  useEffect(()=> {
    getSaveResumeStatus(selectedResume?.id)
  },[selectedResume])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlemailClose = () => {
    setmailShow(false);
  };
  const handlemessageClose = () => {
    setmessageShow(false);
  };
  const handlemailShow = (mail, name) => {
    if (sessionStorage.getItem("id") !== null) {
      settomail(mail);
      setmailShow(true);
      setcandfullname(name);
    } else {
      history.push({
        pathname: "/EmpLogin",
      });
    }
  };
  const handlemessageShow = () => {
    {
      if(limitExceeded || resumepackageExpired) {
        if(limitExceeded){
          toast.error("Your Limit is Exceeded");
        }else{
          toast.error("Your Package is Expired");
        }
      }else {
        if (sessionStorage.getItem("id") !== null) {
          setmessageShow(true);
        } else {
          history.push({
            pathname: "/EmpLogin",
          });
        }
      }
    }
    
  };

  const handleClick = () => {
    if (selectedResume?.linkedin_url !== null && selectedResume?.linkedin_url !== undefined && selectedResume?.linkedin_url !== "") {
      let url = selectedResume?.linkedin_url;
      if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
        url = 'https://' + url;
      }
      window.open(url, '_blank', 'noopener noreferrer');
    }  else {
      let msg = "Please upload LinkedinUrL";
      // toast(
      //   <Alert variant="filled" severity="warning">
      //         {msg}
      //       </Alert>
      // );
      toast.warning(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  
 
  const getSaveResumeStatus = async (resId ) => {
    const response = await axios.post(
      `${Key.domain}/resume/get_saved_and_shortlist_status`,
      {
        resume_id: resId,
        job_id: ""
      }
    );
    if (response.status === 200) {
      
      if (response.data.data.savedStatus) {
        setResumeSavedStatus(true);
      } else {
        setResumeSavedStatus(false);
      }
      // if (response.data.data.shortListStatus) {
      //   setResumeShortlistedStatus(true);
      // } else {
      //   setResumeShortlistedStatus(false);
      // }
    } else {
      console.log(
        "response.status === 200 false : get_saved_and_shortlist_status"
      );
    }
  };
  
  const saveResume = async () => {
    setSavedLoading(true);
    const response = await axios.post(
      `${Key.domain}/resume/js_save_resume`,
      {
        user_id: currentUser.id,
        session_token: currentUser.session_token,
        resumeid: selectedResume?.id 
      }
    );
    if (response.status === 200) {
      setSavedLoading(false);
      let msg = "Saved Successfully";
      toast(
        <Alert variant="filled" severity="success">
          {msg}
        </Alert>
      );
      
      getSaveResumeStatus(selectedResume?.id);
    } else {
      setSavedLoading(false);
      let msg = "Something Went Wrong";
      toast(
        <Alert variant="filled" severity="success">
          {msg}
        </Alert>
      );
      
    }
  };

  const unSaveResume = async () => {
    setSavedLoading(true);
    //console.log("resumeid", resume)
    const response = await axios.post(
      `${Key.domain}/resume/js_unsave_resume`,
      {
        user_id: currentUser.id,
        session_token: currentUser.session_token,
        resume_id: selectedResume?.id ,
      }
    );
    if (response.status === 200) {
      // if(location.pathname === "/employer/saved-resumes" || location.pathname === "/recruiter/saved-resumes"){
      //   //handleStatusChange()
      // }
     
      setSavedLoading(false);
      let msg = "Unsaved Successfully";
      toast(
        <Alert variant="filled" severity="success">
          {msg}
        </Alert>
      );
      getSaveResumeStatus(selectedResume?.id);
    } else {
      setSavedLoading(false);
      let msg = "Something Went Wrong";
      toast(
        <Alert variant="filled" severity="success">
          {msg}
        </Alert>
      );
    }
  };


  const text1Styles = {
    m: 0,
    fontSize: styles.fontSize12,
    fontWeight: 500,
    color: palette.grey[600],
    textTransform: 'capitalize',
  };

  const iconStyles = {
    fontSize: styles.icon20,
    color: palette.grey[500],
  };
  const box1Styles = { display: "flex", gap: "8px", p: "6px", alignItems: 'center' };


  return (
    <Grid container spacing={0} sx={{ border: `1px solid ${palette.grey[300]}` }}>
      {
        selectedResume && 
        <Grid item xs={12}>
        <Box sx={{ p: '10px', display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Tooltip title={`#${selectedResume?.id}`} followCursor>
            <Typography paragraph variant="h5" sx={{ m: 0, color: palette.grey[700], textTransform: 'capitalize' }}>
              {selectedResume?.application_title}
              {/* <span style={{fontSize:'12px', fontWeight:500}}>{`(#${selectedResume?.id})`}</span> */}
            </Typography>
            </Tooltip>
            <Box sx={{ display: 'flex', gap: '6px' }}>
              {/* <Avatar /> */}
              {/* <Box>
                <Typography paragraph sx={{ ...text1Styles, fontSize: styles.fontSize16, fontWeight:600 }}>
                  {selectedResume?.full_name.toLowerCase()}
                </Typography>
                <Typography paragraph sx={{ ...text1Styles }}>
                  {getResumeLocation(selectedResume)}
                </Typography>
              </Box> */}
              <Grid container spacing={0}>
                <Grid item xs={4}>
                <Box sx={box1Styles}>
                  <HowToRegRounded sx={iconStyles} />
                  <Typography paragraph sx={{ ...text1Styles }}>
                    {selectedResume?.full_name && selectedResume.full_name !== undefined ? `${selectedResume.full_name.toLowerCase()}` : ''}
                  </Typography>
                </Box>
                </Grid>
                <Grid item xs={4}>
                <Box sx={box1Styles}>
                  <EmailRounded sx={iconStyles} />
                  <Typography paragraph sx={{ ...text1Styles , "&:hover": {
                    color: "#406882",
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
               }}
               
                  onClick={() => {
                    if(limitExceeded || resumepackageExpired) {
                      if(limitExceeded){
                        toast.error("Your Limit Exceeded");
                      }else{
                        toast.error("Your Package is Expired");
                      }
                    }else {
                    handlemailShow(selectedResume?.email_address, selectedResume?.full_name)
                    }
                  }
              }>
                  {selectedResume.email_address}
                  </Typography>
                </Box>
                </Grid>
                {
                  selectedResume?.home_phone !== "N/A" ? (
                    <Grid item xs={4}> 
                    <Box sx={box1Styles}>
                      <CallRounded sx={iconStyles} />
                      <Typography paragraph sx={{ ...text1Styles , "&:hover": {
                        color: "#406882",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }, }}
                       onClick={handlemessageShow} 
                       >
                      {selectedResume.home_phone}
                      </Typography>
                    </Box>
                    </Grid>
                  ) : ""
                }
                
               
                <Grid item xs={4}>
                <Box sx={box1Styles}>
                  <LocationOnRounded sx={iconStyles} />
                  <Typography paragraph sx={{ ...text1Styles }}>
                  {getResumeLocation(selectedResume)}
                  </Typography>
                </Box>
                </Grid>
                <Grid item xs={4}>
                <Box sx={box1Styles}>
                  <BusinessCenterRounded sx={iconStyles} />
                  <Typography paragraph sx={{ ...text1Styles }}>
                  {Array.isArray(selectedResume.res_jobtypeName) ? selectedResume.res_jobtypeName.join(' '): selectedResume.res_jobtypeName}
                  </Typography>
                </Box>
                </Grid>
                <Grid item xs={4}>
                <Box sx={box1Styles}>
                  <AirplanemodeActiveRounded sx={iconStyles} />
                  <Typography paragraph sx={{ ...text1Styles }}>
                  {Array.isArray(selectedResume.res_visatypeName) ? selectedResume.res_visatypeName.join(' ') : selectedResume.res_visatypeName
                    }
                  </Typography>
                </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center",  gap: 2 }}>
               <Box
                sx={{
                  border: "1px solid #ddd",
                  height : "30px",
                  borderRadius: "6px",
                  "&:hover": {
                   // bgcolor: "#406882",
                    ".inicon": {
                      color: "#fff",
                      cursor: "pointer",
                    },
                  },
                  ".inicon": {
                    color: "#406882",
                  },
                }}
              >
                {/* {
                  savedLoading === true ? (
                    <Tooltip title={resumeSavedStatus ? "Unsave Resume" : "Save Resume"}>
                    <TurnedInNotIcon className="inicon"
                    style={{ height: styles.icon20, width: '26px',height : "30px", cursor: 'pointer' }}
                    onClick={() => resumeSavedStatus ? unSaveResume() : saveResume()}
                  />
                  </Tooltip>
                  ) : (
                    <Tooltip title={resumeSavedStatus ? "Unsave Resume" : "Save Resume"}>
                    <TurnedInIcon className="inicon"
                    style={{ height: styles.icon20, width: '26px', height : "30px", cursor: 'pointer' ,color: "#406882" }}
                    onClick={() => resumeSavedStatus ? unSaveResume() : saveResume()}
                    />
                    </Tooltip>
                  )
                } */}
                  
                   {savedLoading === true ? (
              <Button
                variant="outlined"
                disabled
                size="small"
                onClick={() => resumeSavedStatus ? unSaveResume() : saveResume()}
                sx={{ textTransform: "capitalize", borderRadius: "4px"  }}
              >
                saving...
              </Button>
              
            ) : (
              <Button
                //variant="outlined"
                size="small"
                style={{ textTransform: "capitalize", borderRadius: "4px", fontSize: styles.fontSize13 , color:"white" , backgroundColor:"#406882" }}
                onClick={() => resumeSavedStatus ? unSaveResume() : saveResume()}
              >
                {resumeSavedStatus ? "Resume Saved" : "Save Resume"}
              </Button>
            )}
                  </Box>
             <Box
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  height : "30px",
                  "&:hover": {
                    bgcolor: "#406882",
                    ".inicon": {
                      color: "#fff",
                      cursor: "pointer",
                    },
                  },
                  ".inicon": {
                    color: "#406882",
                  },
                }}
              >
                  <TiSocialLinkedin className="inicon"
                    style={{ height: styles.icon20, width: '26px', cursor: 'pointer' }}
                    onClick={handleClick}
                  /></Box>
                   

          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderTop: `1px solid ${palette.grey[300]}`,
              borderBottom: `1px solid ${palette.grey[300]}`,
              boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"            
              sx={{
                minHeight: '42px',
                '& .MuiTab-root': {
                  fontSize: styles.fontSize12,
                  minHeight: '42px', // Ensure individual tabs also decrease in height
                  padding: '6px 12px', // Adjust padding
                  minWidth: '60px', // Decrease tab width
                  textTransform: 'uppercase', 
                  fontWeight: 500, 
                },
                '& .Mui-selected': {
                  color: '#1976d2 !important',
                  // backgroundColor: '#e3f2fd',
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: '#1976d2',
                },
              }}
            >
              {/* <Tab label="Overview" {...a11yProps(0)}  /> */}
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Summary" {...a11yProps(1)} />
              <Tab label="Skills" {...a11yProps(2)} />
               <Tab label="Works" {...a11yProps(3)} />
             {/* <Tab label="Queries" {...a11yProps(5)} />
              <Tab label="Activity" {...a11yProps(6)} />
              <Tab label="Actions" {...a11yProps(7)} /> */}
            </Tabs>
          </Box>
          {/* <CustomTabPanel value={value} index={0}>
            <OverView />
          </CustomTabPanel> */}
          <CustomTabPanel value={value} index={0}>
            <PdfViewer resumeDetails={selectedResume} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
             <ResumeSummary/>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ResumeSkills/>
            {/* <CustomerPackageInfo 
            customerId={customerDetailsData?.userDetails?.id}
            companyDetails={customerDetailsData?.companyDetails}
            packageDetails={customerDetailsData?.packageDetails}  /> */}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <ResumeWorks/>
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={5}>
          item 6
          </CustomTabPanel>
          <CustomTabPanel value={value} index={6}>
            item 7
          </CustomTabPanel>
          <CustomTabPanel value={value} index={7}>
            item 8
          </CustomTabPanel> */}
        </Box>
      </Grid>
      }
         {sessionStorage.getItem("id") !== null ? (
            <Dialog
              // open={mailshow}
              open={mailshow}
              centered
              size="lg"
              fullWidth
              onClose={handlemailClose}
              style={{ borderRadius: "4px" }}
            >
              {/* <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: "18px" }}>
                  Send Mail To Candidate
                </Modal.Title>
              </Modal.Header> */}

              <DialogTitle
                id="responsive-dialog-title"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <DialogContentText variant="h6">
                  Send Mail To Candidate
                </DialogContentText>
                <IconButton aria-label="close" onClick={handlemailClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent>
                <MailCandidate
                  handlemailClose={handlemailClose}
                  tomail={tomail}
                  candfullname={candfullname}
                 // selectedResumes={selectedResume}
                  fromempEmail={currentUser.username}
                />
              </DialogContent>
            </Dialog>
          ) : null}
            {numFound !== 0 ||
                sessionStorage.getItem("resumesCount") !== "0" ? (
                <Dialog
                  open={messageshow}
                  centered
                  onClose={handlemessageClose}
                  style={{ borderRadius: "4px" }}
                  maxWidth="sm"
                  fullWidth
                >
                  {/* <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "18px" }}>
                      Message
                    </Modal.Title>
                  </Modal.Header> */}

                  <DialogTitle
                    id="responsive-dialog-title"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <DialogContentText
                      variant="h6"
                      style={{ fontSize: "18px" }}
                    >
                      Message
                    </DialogContentText>
                    <IconButton aria-label="close" onClick={handlemessageClose}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <Messagepopup handlemessageClose={handlemessageClose} />
                  </DialogContent>
                </Dialog>
              ) : (
                ""
              )}
    </Grid>
  );
};

export default DetailsView;

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));
