import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const columns = [
  { field: "year", headerName: "Year", width: 100 },
  { field: "certified", headerName: "Certified", width: 120 },
  {
    field: "certifiedWithdrawn",
    headerName: "Certified Withdrawn",
    width: 120,
  },
  { field: "withdrawn", headerName: "Withdrawn", width: 120 },
  { field: "denied", headerName: "Denied", width: 120 },
  { field: "approval", headerName: "Approval (%)", width: 120 },
  { field: "denial", headerName: "Denial (%)", width: 120 },
];

export default function LCA_Approvals_Denails({ companyData }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    console.log(companyData?.lca_data);
    const newRows = companyData.lca_data.map((data) => {
      const totalApplications =
        data.Certified +
        data.Certified_Withdrawn +
        data.Denied +
        data.Withdrawn;
      const approvalRate =
        totalApplications > 0
          ? ((data.Certified / totalApplications) * 100).toFixed(2) + "%"
          : "-";
      const denialRate =
        totalApplications > 0
          ? ((data.Denied / totalApplications) * 100).toFixed(2) + "%"
          : "-";

      return {
        id: data?.id,
        year: data?.year || "-",
        certified: data?.Certified ?? "-",
        certifiedWithdrawn: data?.Certified_Withdrawn ?? "-",
        withdrawn: data?.Withdrawn ?? "-",
        denied: data?.Denied ?? "-",
        approval: approvalRate,
        denial: denialRate,
      };
    });
    setRows(newRows);
  }, [companyData]);

  const handleExport = () => {
    const csvData = rows.map((row) => ({
      ID: row.id,
      Year: row.year,
      Certified: row.certified,
      CertifiedWithdrawn: row.certifiedWithdrawn,
      Withdrawn: row.withdrawn,
      Denied: row.denied,
      Approval: row.approval,
      Denial: row.denial,
    }));

    const csvContent = [
      Object.keys(csvData[0]).join(","),
      ...csvData.map((row) => Object.values(row).join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `H1B_LCA_Approvals_${companyData?.name || ""}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Box component={Paper} sx={{ height: "auto", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        getRowId={(row) => row.id}
        // checkboxSelection
        disableRowSelectionOnClick
        // slots={{
        //   toolbar: () => (
        //     <div
        //       style={{
        //         display: "flex",
        //         justifyContent: "space-between",
        //         padding: "10px",
        //       }}
        //     >
        //       <GridToolbar />
        //       {/* https://upload.wikimedia.org/wikipedia/commons/7/73/Microsoft_Excel_2013-2019_logo.svg */}
        //       {/* <Button variant="contained" onClick={handleExport}>
        //     Export to CSV
        //   </Button> */}
        //       <Box
        //         component={Button}
        //         sx={
        //           {
        //             //   border: "1px solid #ddd",
        //             //   p: "5px 10px",
        //             //   borderRadius: "8px",
        //             // "&:hover": {
        //             //   boxShadow: "0px 10px 20px -5px rgba(6, 36, 92, 0.06)",
        //             //   transform: "translateY(-5px)",
        //             //   transition: "all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1)",
        //             // },
        //           }
        //         }
        //         onClick={handleExport}
        //       >
        //         {/* <FaLinkedin style={{ color: "#2867B2", fontSize: "30px" }} /> */}
        //         <img
        //           src="https://upload.wikimedia.org/wikipedia/commons/7/73/Microsoft_Excel_2013-2019_logo.svg"
        //           width={20}
        //         />
        //       </Box>
        //     </div>
        //   ),
        // }}
      />
    </Box>
  );
}
