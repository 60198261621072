import * as ActionTypes from "../Components/Actions/actionTypes";

const initialState = {
  searchParams: {},
  filterParams: {},
  paginationParams: { start: 0, rows: 10, currentPage: 1, init: 1 },
  results: [],
  numFound: 0,
  selectedJob: null,
  queryWithoutFilters: null,
  error: null,
  selectedJobDetails: null,
};

const searchJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_JOB_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...action.payload,
        },
      };
    case ActionTypes.UPDATE_JOB_FILTER_PARAMS:
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          ...action.payload,
        },
      };
    case ActionTypes.UPDATE_JOB_PAGINATION_PARAMS:
      return {
        ...state,
        paginationParams: {
          ...state.paginationParams,
          ...action.payload,
        },
      };
    case ActionTypes.FETCH_JOB_RESULTS_SUCCESS:
      return {
        ...state,
        results: action.payload.response,
        numFound: action.payload.numFound,
        facet_counts: action.payload.facet_counts,
        error: null,
      };
    case ActionTypes.FETCH_JOB_RESULTS_FAILURE:
      return {
        ...state,
        results: [],
        error: action.payload,
      };
    case ActionTypes.SET_SELECTED_JOB:
      return {
        ...state,
        selectedJob: action.payload,
      };
    //   case ActionTypes.SOLR_QUERY_WITHOUT_FILTERS:
    //     return {
    //       ...state,
    //       queryWithoutFilters: action.payload,
    //     };
    case ActionTypes.CLEAR_JOB_SEARCH_RESULTS:
      return {
        ...state,
        ...initialState,
      };
    case ActionTypes.JOB_DETAILS:
      return {
        ...state,
        selectedJobDetails: action.payload,
      };
    case ActionTypes.JOB_DETAILS_FAIL:
      return {
        ...state,
        selectedJobDetails: action.payload,
      };
    default:
      return state;
  }
};

export default searchJobReducer;
