const { Configuration, OpenAIApi } = require("openai");
const { openai_key } = require("../../../clientVariables.json");

export const openAIExtracteSearchValues = async (message, callback) => {
  const configuration = new Configuration({
    apiKey: openai_key,
  });


// const prompt = `Extract the following components from the job posting or search query: job title, location state, job type, visa type, total experience, ID, email, and company name. The job posting is: "${message}".

// 1. **Job Title**: Extract the job title (e.g., Software Engineer, Data Analyst, Sales Manager, Salesforce). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 2. **Location State**: Identify the state where the job is located (e.g., Texas, New York). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 3. **Job Type**: Standardize the job type to one of the following: "Full-Time", "Part-Time", "Contract", "Internship". Match alternative forms (e.g., "fulltime" → "Full-Time"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 4. **Visa Type**: Standardize the visa type to one of the following: "H1", "OPT", "F1", "TN", "EAD", "L1", "H1B", "CPT". Match alternative forms (e.g., "h1b" → "H1B"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 5. **Total Experience**: Extract the required years of experience as a number (e.g., "3 years of experience in X"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 6. **ID**: Extract the job ID, if present, which may be explicitly mentioned in the job posting (e.g., "Job ID: #12345"). Return \`null\` if not found.
// 7. **Email**: Extract the email address to contact for the job, which contains an \`@\` symbol (e.g., "hr@company.com"). Return \`null\` if not found.
// 8. **Company Name**: Extract the company name where the job is offered (e.g., "Google", "Amazon"). Return \`null\` if not found or if specified as "Not specified" or "N/A".

// Return the extracted components in the following format:
// {
//   "title": "<Job Title>",
//   "state": "<Location State>",
//   "jobType": "<Job Type>",
//   "visaType": "<Visa Type>",
//   "experience": "<Total Experience>",
//   "id": "<ID>",
//   "email": "<Email>",
//   "companyName": "<Company Name>"
// }`;

const prompt = `Extract the following components from the job posting or search query: job title, location state, job type, visa type, total experience, ID, email, and company name. The job posting is: "${message}".

1. **Job Title**: Extract the job title (e.g., Software Engineer, Data Analyst, Sales Manager, Salesforce). Include technical terms like programming languages (e.g., Java, Python, SQL) or tools (e.g., ServiceNow, Salesforce, AWS) as valid job titles. Return \`null\` if not found or if specified as "Not specified" or "N/A".
2. **Location State**: Identify the state where the job is located (e.g., Texas, New York). Return \`null\` if not found or if specified as "Not specified" or "N/A".
3. **Job Type**: Standardize the job type to one of the following: "Full-Time", "Part-Time", "Contract", "Internship". Match alternative forms (e.g., "fulltime" → "Full-Time"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
4. **Visa Type**: Standardize the visa type to one of the following: "H1", "OPT", "F1", "TN", "EAD", "L1", "H1B", "CPT". Match alternative forms (e.g., "h1b" → "H1B"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
5. **Total Experience**: Extract the required years of experience as a number (e.g., "3 years of experience in X"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
6. **ID**: Extract the job ID, if present, which may be explicitly mentioned in the job posting (e.g., "Job ID: #12345"). Return \`null\` if not found.
7. **Email**: Extract the email address to contact for the job, which contains an \`@\` symbol (e.g., "hr@company.com"). Return \`null\` if not found.
8. **Company Name**: Extract the company name where the job is offered (e.g., "Google", "Amazon"). Return \`null\` if not found or if specified as "Not specified" or "N/A".

Return the extracted components in the following format:
{
  "title": "<Job Title>",
  "state": "<Location State>",
  "jobType": "<Job Type>",
  "visaType": "<Visa Type>",
  "experience": "<Total Experience>",
  "id": "<ID>",
  "email": "<Email>",
  "companyName": "<Company Name>"
}`;




  try {
    const openai = new OpenAIApi(configuration);
    const chatCompletion = await openai.createChatCompletion({
      model: "gpt-4o-mini",
      messages: [{ role: "user", content: prompt }],
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      temperature: 0,
    });

    let result = chatCompletion.data.choices[0].message.content;
    console.log("API Response:", result);

    const jsonMatch = result.match(/\{[\s\S]*\}/);
    let parsedResult;

    if (jsonMatch) {
      try {
        parsedResult = JSON.parse(jsonMatch[0]); 
      } catch (err) {
        console.error("Failed to parse extracted JSON:", err);
        return callback(new Error("Failed to parse API response. The JSON extraction failed."));
      }
    } else {
      console.error("No JSON found in the API response.");
      return callback(new Error("No valid JSON found in the API response."));
    }

    const fallbackData = {
      title: null,
      state: null,
      jobType: null,
      visaType: null,
      experience: null,
      id: null,
      email: null,
      companyName: null,
    };

    const data = {
      ...fallbackData,
      ...parsedResult,
    };

    data.title = data.title === "Not specified" || data.title === "N/A" || data.title === "null" ? null : data.title;
    data.state = data.state === "Not specified" || data.state === "N/A" || data.state === "null" ? null : data.state;
    data.jobType = data.jobType === "Not specified" || data.jobType === "N/A" || data.jobType === "null" ? null : data.jobType;
    data.visaType = data.visaType === "Not specified" || data.visaType === "N/A" || data.visaType === "null" ? null : data.visaType;
    data.experience = data.experience === "Not specified" || data.experience === "N/A" || data.experience === "null" ? null : data.experience;
    data.id = data.id === "Not specified" || data.id === "N/A" || data.id === "null" ? null : data.id;
    data.email = data.email === "Not specified" || data.email === "N/A" || data.email === "null" ? null : data.email;
    data.companyName = data.companyName === "Not specified" || data.companyName === "N/A" || data.companyName === "null" ? null : data.companyName;

    return callback(null, data);
  } catch (e) {
    console.error("Error with OpenAI API call:", e);
    return callback(e);
  }
};