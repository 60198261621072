import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  Avatar,
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  Radio,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Modal,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  tableCellClasses,
} from "@mui/material";
import { Button, Grid, Box } from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Key from "../../../clientVariables.json";
import JobRowsSelect from "../../Jobseeker/JobRowsSelect";
import PaginationComponent from "../../Jobseeker/PaginationComponent";
import moment from "moment";
import { getJobLocation } from "../../../utils/getLocation";

const JobList = ({setSelectedJob, companyData}) => {
  const location = useLocation();
  //   const jobs = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageStartNum, setPageStartNum] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [totalJobs, setTotalJobs] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    let startNum = rowsPerPage * newPage - rowsPerPage + 1;
    setPageStartNum(startNum);
  };
  const handleRowsChange = (selectedRows) => {
    setRowsPerPage(selectedRows);
    setCurrentPage(1);
  };

  useEffect(() => {
    if(companyData?.id){
      getJobsByCompanyID(companyData?.id);
    }
  }, [
    pageStartNum,
    currentPage,
    rowsPerPage,
    companyData,
  ]);
  useEffect(() => {
    setCurrentPage(1);
    setPageStartNum(0);
  }, [totalPages]);

  const getJobsByCompanyID = async (id) => {
    const urlSolr = `${Key.Solr_url}/${Key.solrJobs_collection}/select?&fq=company_id:(${id})&rows=${rowsPerPage}&start=${pageStartNum}&fq=status:(1)&fq=industry_type:"IT"&sort=modified%20desc&wt=json`;
    try {
      const response = await axios.post(`${Key.domain}/solr/solr_jobs`, {
        url: urlSolr,
      });
      if (response.status === 200) {
        if(response.data.data.response.docs.length > 0){
          setSelectedJob(response.data.data.response.docs[0]);
        }
        setJobs(response.data.data.response.docs);
        setTotalJobs(response.data.data.response.numFound);
        let totalPages = Math.ceil(
          response.data.data.response.numFound / rowsPerPage
        );
        setTotalPages(totalPages);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Paper elevation={2} sx={{ borderRadius: "8px" }}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          minHeight: "48px !important",
        }}
      >
        <Box
          sx={{
            flex: "1 1 100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontWeight: 500, margin: 0 }}
            // variant="h6"
            paragraph
            id="tableTitle"
            component="div"
          >
            Jobs {`(${totalJobs} jobs found)`}
          </Typography>
        </Box>
        <Divider sx={{ margin: 0 }} />
      </Toolbar>
      <Divider />
      {/* <List sx={{ height: "700px", overflow: "scroll" }}>
        {true ? (
          jobs?.map(
            (job, index) => (
              // <ListItem sx={{borderBottom:'1px solid #ddd'}}>
              <ListItemButton
                // onClick={() => setSelectedItem(index)}
                key={index}
                // selected={selectedItem === index}
                disableRipple
                sx={{ borderBottom: "1px solid #ddd" }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <Avatar
                      src="https://img.freepik.com/premium-photo/free-vector-colorful-bird-illustration-gradient-bird-colorful-logo-gradient-vector_971166-58958.jpg?w=740"
                      sx={{
                        height: isDesktopLarge ? "40px" : "30px",
                        width: isDesktopLarge ? "40px" : "30px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Stack direction="column" sx={{ width: "100%" }}>
                      <Typography
                        paragraph
                        sx={{
                          margin: 0,
                          color: "rgba(0,0,0,0.7)",
                          fontWeight: 600,
                          fontSize: "0.85rem",
                        }}
                        // onClick={() => setJobdetailview(true)}
                      >
                        Java Developer
                      </Typography>
                      <Typography
                        paragraph
                        sx={{
                          margin: 0,
                          color: "rgba(0, 0, 0, 0.56)",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        Nitya Software Solutions
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography
                          paragraph
                          sx={{
                            margin: 0,
                            color: "rgba(0,0,0,0.5)",
                            fontSize: "12px",
                          }}
                        >
                          New York
                        </Typography>
                        <Typography
                          paragraph
                          sx={{
                            margin: 0,
                            color: "rgba(0,0,0,0.5)",
                            fontWeight: 500,
                            fontSize: "12px",
                          }}
                        >
                          3 days ago
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItemButton>
            )
            // </ListItem>
          )
        ) : (
          <></>
        )}
      </List> */}

      <List sx={{ height: window.innerHeight - 200, overflow: "scroll" }}>
        {jobs?.length > 0 ? (
          jobs.map(
            (job, index) => (
              // <ListItem sx={{borderBottom:'1px solid #ddd'}}>
              <ListItemButton
                onClick={() => setSelectedJob(job)}
                key={index}
                // selected={selectedItem === index}
                disableRipple
                sx={{ borderBottom: "1px solid #ddd" }}
                // onClick={() => {
                //   jobCardClickHandler(job, index);
                //   setSelectedItem(index);
                // }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <Avatar
                      src={`${Key.domain}/company/company_logo/${job?.company_id}/getcompanynamebyquery`}
                      sx={{
                        height: isDesktopLarge ? "40px" : "30px",
                        width: isDesktopLarge ? "40px" : "30px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Stack direction="column" sx={{ width: "100%" }}>
                      <Typography
                        paragraph
                        sx={{
                          margin: 0,
                          color: "rgba(0,0,0,0.7)",
                          fontWeight: 600,
                          fontSize: "0.85rem",
                        }}
                      >
                        {job?.title}
                      </Typography>
                      {/* <Typography
                        paragraph
                        sx={{
                          margin: 0,
                          color: "rgba(0, 0, 0, 0.56)",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        {job?.company_name}
                      </Typography> */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography
                          paragraph
                          sx={{
                            margin: 0,
                            color: "rgba(0,0,0,0.5)",
                            fontSize: "12px",
                          }}
                        >
                          {job && getJobLocation(job)}
                        </Typography>
                        <Typography
                          paragraph
                          sx={{
                            margin: 0,
                            color: "rgba(0,0,0,0.5)",
                            fontWeight: 500,
                            fontSize: "12px",
                          }}
                        >
                          {job.modified !== undefined && job.modified !== null
                            ? moment(job.modified).fromNow() !== "Invalid date"
                              ? moment(job.modified).fromNow()
                              : moment("2022-03-31T06:26:04.138Z").fromNow()
                            : moment("2022-03-31T06:26:04.138Z").fromNow()}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItemButton>
            )
            // </ListItem>
          )
        ) : (
          <></>
        )}
      </List>

      <Stack direction="column" component={Card} sx={{ p: 1 }} gap={2}>
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            paragraph
            sx={{
              margin: 0,
              fontSize: "0.65rem",
            }}
          >
            Page <strong>{currentPage}</strong> of <strong>{totalJobs}</strong>{" "}
            Jobs
          </Typography>
          <JobRowsSelect
            rowsPerPage={rowsPerPage}
            onRowsChange={handleRowsChange}
          />
        </Box>
      </Stack>
    </Paper>
  );
};

export default JobList;
