const { Configuration, OpenAIApi } = require("openai");
const { openai_key } = require("../../../../clientVariables.json");

export const openAIExtracteAISearchValues = async (message, callback) => {
    const configuration = new Configuration({
        apiKey: openai_key,
    });

    // Combine prompt with the message
    // const promptMessage = `This is job description contains role, skills, qualifications, years of experience, job description, job duties etc. Please extract job_title (simple job title without any special characters with exact title based on skills), job_roles (make alternate job titles with 8 more similar job titles with similar roles and don't give unrelated or dissimilar titles), state (if state is present make state in strings or else empty strings), city (if city or state is present make nearby cities more than 8 in array or else empty strings), experience (total experience in years (integer only no other words like years, months or unknown words)), skills (skills as list without description, with technologies used and competencies and add relevant IT skills to job title that are not mentioned, combine all and make as single list without description). Please ensure that no other information and make it as JSON. ${message}`;
    const promptMessage = `This is job description contains role, skills, qualifications, years of experience, job description, job duties etc. Please extract job_title (simple job title without any special characters with exact title based on skills), job_roles (make alternate job titles with 8 more similar job titles with similar roles and don't give unrelated or dissimilar titles), state (if state is present make state in strings or else empty strings), city (if city or state is present make nearby cities more than 8 in array or else empty strings), experience (total experience in years (integer only no other words like years, months or unknown words)), skills (skills as list without description, with technologies used and competencies and add relevant IT skills to job title that are not mentioned, combine all and make as single list without description), jobtype (extract the job type if present, e.g., full-time, part-time, etc.), visatype (extract visa type if mentioned, e.g., H1B, OPT, etc.). Please ensure that no other information and make it as JSON. ${message}`;
    const prompt = [{ role: "user", content: promptMessage }];

    try {
        const openai = new OpenAIApi(configuration);
        const chatCompletion = await openai.createChatCompletion({
            model: "gpt-3.5-turbo-0125",
            messages: prompt,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
            temperature: 0,
        });

        const result = chatCompletion.data.choices[0].message.content;
        const resultIsJSON = isJSON(result);

        if (resultIsJSON) {
            const aidata = JSON.parse(result);
            const data = aidata?.data || aidata || {};

            // Processing fields (job_title, state, etc.)
            const processedData = processExtractedData(data);

            // Optionally store the response in session storage
            sessionStorage.setItem("OpenAIresponse", JSON.stringify(processedData));

            return callback(null, data, processedData, resultIsJSON);
        } else {
            // Handle non-JSON responses
            const sanitizedMessage = sanitizeMessage(message);
            return callback(null, null, sanitizedMessage, resultIsJSON);
        }
    } catch (error) {
        // handleOpenAIError(error);
        return callback(error.message);
    }
};

// Utility function to check if a string is valid JSON
function isJSON(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
}

function processExtractedData(data) {
  const regex = /[`!@$^*()_\-=\[\]{};'".:\\|\/?~]/g;
  const unWantedWords = ["undefined", "Unknown", "null", "none", "not specified", "0", "empty", "unknown", "missing", "absent", "n/a", "na", "not applicable", "not available", "unassigned", "void", "undetermined", "blank", "uncertain", "not provided", "no value", "not set"];
  const commonWords = ["developer", "consultant"];

  const job_title = data.job_title;
  const state = data.state && !unWantedWords.includes(data.state.toLowerCase()) ? data.state.replace(regex, ' ') : "";
  const experience = data.experience && !unWantedWords.includes(data.experience) ? parseInt(data.experience) : "";
  const visatype = data.visatype && !unWantedWords.includes(data.visatype.toLowerCase()) ? data.visatype.replace(regex, ' ') : "";
  const jobtype = data.jobtype && !unWantedWords.includes(data.jobtype.toLowerCase()) ? data.jobtype.replace(regex, ' ') : "";
  const qualifications = data.qualifications && !unWantedWords.includes(data.qualifications.toLowerCase()) ? data.qualifications.replace(regex, ' ') : "";
  const city = data.city;
  const skills = data.skills;
  const job_roles = data.job_roles;

  return {
      job_title: job_title ? `"${job_title}"` : "",
      state: state && state.toLowerCase() !== "remote" && state.toLowerCase() !== "us" && state.toLowerCase() !== "usa" ? `"${state}"` : "",
      shortRegion: state.length > 2 ? "" : state,
      skills: skills || "",
      experience: experience ? `[${experience} TO *]` : "",
      city: city || "",
      qualifications: qualifications ? `"${qualifications}"` : "",
      job_roles: job_roles || "",
      visatype: visatype || "",
      jobtype: jobtype || "",
  };
}


// Function to sanitize a non-JSON message
function sanitizeMessage(message) {
    const commonWords1 = ["developer", "Developer", "programmer", "architect", "designer", "consultant", "administrator", "coach", "lead", "manager", "master", "specialist", "modeler", "director", "hacker"];
    return commonWords1.reduce((acc, word) => acc.replace(new RegExp(`\\b${word}\\b`, 'gi'), ' '), message).replace(/\s+/g, ' ');
}

// Function to handle OpenAI API errors
// function handleOpenAIError(error) {
//     console.error("OpenAI API error:", error.message);

//     axios.post(`${domain}/crm/save_aisearch_record`, {
//         uid: sessionStorage.getItem("id"),
//         content: sessionStorage.getItem("OpenAIMessage"),
//         openai_response: sessionStorage.getItem("OpenAIresponse"),
//         solr_query: "",
//         result_count: "",
//         status: "error",
//         error_msg: error.message || error.response?.data || "Unknown error",
//     }).then((response) => {
//         console.log("Error logged successfully");
//     }).catch((logError) => {
//         console.error("Error logging failed:", logError.message);
//     });
// }






// const { Configuration, OpenAIApi } = require("openai");
// const { openai_key } = require("../../../../clientVariables.json");

// export const openAIExtracteSearchValues = async (message, callback) => {
//     const configuration = new Configuration({
//       apiKey: openai_key,
//     });
  
//     const prompt = `Extract the following components from the search query: job title, location state, job type, visa type, total experience, ID, email, willingness to relocate, related titles, and skills. The search query is: "${message}".
  
//   1. **Job Title**: Extract the job title (e.g., SQL, Java, Python, ServiceNow). Return \`null\` if not found or if specified as "Not specified" or "N/A".
//   2. **Location State**: Identify the state (e.g., Texas). Return \`null\` if not found or if specified as "Not specified" or "N/A".
//   3. **Job Type**: Standardize the job type to one of the following: "Full-Time", "Part-Time", "Contract", "Internship". Match alternative forms (e.g., "fulltime" → "Full-Time"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
//   4. **Visa Type**: Standardize the visa type to one of the following: "H1", "OPT", "F1", "TN", "EAD", "L1", "H1B", "CPT". Match alternative forms (e.g., "h1b" → "H1B"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
//   5. **Total Experience**: Extract the total years of experience as a number. Return \`null\` if not found or if specified as "Not specified" or "N/A".
//   6. **ID**: Extract the ID, which is prefixed with a \`#\` symbol (e.g., "#745676"). Return \`null\` if not found.
//   7. **Email**: Extract the email address, which contains an \`@\` symbol (e.g., "example@mail.com"). Return \`null\` if not found.
//   8. **Willing to Relocate**: Extract the willingness to relocate (e.g., "Yes", "No"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
//   9. **Related Titles**: Extract any related job titles or positions mentioned. Return \`null\` if not found.
//   10. **Skills**: Extract a list of skills mentioned. Return \`null\` if not found.
  
//   Return the extracted components in the following format:
//   {
//     "title": "<Job Title>",
//     "state": "<Location State>",
//     "jobType": "<Job Type>",
//     "visaType": "<Visa Type>",
//     "experience": "<Total Experience>",
//     "id": "<ID>",
//     "email": "<Email>",
//     "willing_to_relocate": "<Willing to Relocate>",
//     "related_titles": "<Related Titles>",
//     "skills": "<Skills>"
//   }`;
  
//     try {
//       const openai = new OpenAIApi(configuration);
//       const chatCompletion = await openai.createChatCompletion({
//         model: "gpt-4o-mini",
//         messages: [{ role: "user", content: prompt }],
//         top_p: 1,
//         frequency_penalty: 0,
//         presence_penalty: 0,
//         temperature: 0,
//       });
  
//       let result = chatCompletion.data.choices[0].message.content;
//       console.log("API Response:", result);
  
//       const jsonMatch = result.match(/\{[\s\S]*\}/);
//       let parsedResult;
  
//       if (jsonMatch) {
//         try {
//           parsedResult = JSON.parse(jsonMatch[0]); 
//         } catch (err) {
//           console.error("Failed to parse extracted JSON:", err);
//           return callback(new Error("Failed to parse API response. The JSON extraction failed."));
//         }
//       } else {
//         console.error("No JSON found in the API response.");
//         return callback(new Error("No valid JSON found in the API response."));
//       }
  
//       const fallbackData = {
//         title: null,
//         state: null,
//         jobType: null,
//         visaType: null,
//         experience: null,
//         id: null,
//         email: null,
//         willing_to_relocate: null,
//         related_titles: null,
//         skills: null,
//       };
  
//       const data = {
//         ...fallbackData,
//         ...parsedResult,
//       };
  
//       const fields = ['title', 'state', 'jobType', 'visaType', 'experience', 'id', 'email', 'willing_to_relocate', 'related_titles', 'skills'];
//       fields.forEach(field => {
//         if (data[field] === "Not specified" || data[field] === "N/A") {
//           data[field] = null;
//         }
//       });
  
//       return callback(null, data);
//     } catch (e) {
//       console.error("Error with OpenAI API call:", e);
//       return callback(e);
//     }
//   };
  
