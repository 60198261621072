import React, { useEffect, useRef } from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  BusinessCenterOutlined,
  BusinessCenterRounded,
  CalendarMonth,
  CalendarMonthOutlined,
  DashboardOutlined,
  DashboardRounded,
  Groups,
  GroupsOutlined,
  LocationCityOutlined,
  LocationCityRounded,
  ManageSearch,
  ManageSearchOutlined,
  Message,
  Notifications,
  CheckCircleOutline,
  CheckCircleRounded,
  PersonSearchOutlined,
  PersonSearchRounded,
  WhatshotRounded,
  WhatshotOutlined,
  ContactEmergencyRounded,
  InfoOutlined,
  KeyboardReturnRounded,
  HistoryRounded,
  DeleteOutlineRounded,
} from "@mui/icons-material";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import lightLog from "../../Images/logo/logo-light-bg.png";
import { useState } from "react";
import {
  Autocomplete,
  Avatar,
  Backdrop,
  Badge,
  Button,
  Fade,
  Menu,
  MenuItem,
  Modal,
  Popover,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Key from "../../clientVariables.json";
import moment from "moment";
import axios from "axios";
import Empgeneralsearch from "./Empgeneralsearch";
import "./Styles/muiNavBarStyles.css";
import { useLocation } from "react-router-dom";
import userService from "../../services/user.service";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SearchComponent from "./ResumeSearch/general/SearchComonent";
import {
  fetchResumeResultsV1,
  updateResumeFilterParams,
  updateResumePaginationParams,
  updateResumeSearchParams,
} from "../Actions/resumeSearchActions";
import { job_types_data, us_states_data } from "../../utils/commonData";
import { openAIExtracteSearchValues } from "./ResumeSearch/general/components/extractedSearchValues";
import { hideLoader, showLoader } from "../Actions/loader.actions";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";

const drawerWidth = 240;

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.9),
  // '&:hover': {
  //   backgroundColor: alpha(theme.palette.common.white, 0.6),
  // },
  color: alpha(theme.palette.common.black, 0.9),
  marginLeft: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchHistory = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  // boxShadow: 3,
  border: 'none',
  zIndex: 10,
  maxHeight: '250px',
  overflowY: 'auto',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#fff',
  // '&:hover': {
  //   backgroundColor: alpha(theme.palette.common.white, 0.6),
  // },
  color: alpha(theme.palette.common.black, 0.9),
  // marginLeft: 0,
  // width: '100%',
  // display: 'flex',
  // alignItems: 'center',
  // [theme.breakpoints.up('sm')]: {
  //   marginLeft: theme.spacing(1),
  //   width: 'auto',
  // },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  flexGrow: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '50ch',
      // '&:focus': {
      //   width: '70ch',
      // },
    },
    '&::placeholder': {
      color: '#888',
      opacity: 1,
    },
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  color: '#888',
  '&:hover': {
    color: '#406882',
    cursor: 'pointer',
  },
}));

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.9),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.6),
//   },
//   color: alpha(theme.palette.common.black, 0.9),
//   marginLeft: 0,
//   width: "100%",
//   display: "flex", // Added to allow items to align in a row
//   alignItems: "center", // Aligns items vertically center
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(1),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   flexGrow: 1,
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // paddingLeft: `calc(1em + ${theme.spacing(2)})`,
//     transition: theme.transitions.create("width"),
//     [theme.breakpoints.up("sm")]: {
//       width: "50ch",
//       "&:focus": {
//         width: "70ch",
//       },
//     },
//     "&::placeholder": {
//       color: "#888",
//       opacity: 1,
//     },
//   },
// }));

// const IconWrapper = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   padding: theme.spacing(0, 1),
//   color: "#888",
//   "&:hover": {
//     color: "#406882",
//     cursor: "pointer",
//   },
// }));


const StyledInputBase2 = styled(TextField)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "40ch",
      },
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => {
  // console.log("Current theme breakpoints:", theme.breakpoints);
  return {
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "30ch",
        "&:focus": {
          width: "40ch",
          border: "0px solid #fff",
        },
      },
    },
  };
});

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerStyle = {
  ".css-12i7wg6-MuiPaper-root-MuiDrawer-paper": {
    background: "#f0f0f0 !important",
    width: 'calc(70px)'
  },
};
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // width: drawerWidth,

  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const jobTitles = [
  "java developer",
  "frontend developer",
  "backend engineer",
  "python developer",
  "sql",
]; // List of job titles
const locations = ["california", "new york", "texas"]; // List of locations

const ITEM_HEIGHT = 48;
const options = ["Option 1", "Option 2", "Option 3"];
export default function Empnavbar({ children }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectNavItem, setSelectNavItem] = useState("Dashboard");
  const [openMobile, setOpenMobile] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const openNotification = Boolean(anchorElNotification);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [formOpen, setFormOpen] = useState(false);
  const [sideNavItemIndex, setSideNavItemIndex] = useState(0);
  const location = useLocation();
  const currentUser = useSelector((state) => state.UserReducer.user);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [jobTitlesData, setJobTitlesData] = useState([]);
  const [openResumeSearchComponent, setOpenResumeSearchComponent] =
    useState(false);
  const [formData, setFormData] = useState({
    location: null,
    title: null,
    skills: null,
  });
  const [query, setQuery] = useState("");
  const [title, setTitle] = useState("");
  const [location_search, setLocation] = useState("");
  const [parsedQuery, setParsedQuery] = useState({ title: "", state: "" });
  const dispatch = useDispatch();
  const [anchorElInfo, setAnchorElInfo] = useState(null);
  const [searchHistory, setSearchHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const searchRef = useRef(null);




  const handleFocus = () => {
    setShowHistory(true);
    getSearchHistory();
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowHistory(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (query) {
      updateSearchHistory();
    }
  }, [query]);

  const computeMatchScore = (item) => {
    const keyword = item.keyword.toLowerCase();
    const lowerQuery = query.toLowerCase();

    if (keyword.includes(lowerQuery)) {
      return 1;
    }
    
    return 0; 
  };

  const updateSearchHistory = () => {
    setSearchHistory(prevHistory => {
      return prevHistory
        .map(item => ({
          ...item,
          score: computeMatchScore(item),
        }))
        .sort((a, b) => b.score - a.score) 
        .map(({ score, ...item }) => item);
    });
  };


  const handleInfoClick = (event) => {
    setAnchorElInfo(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorElInfo(null);
  };

  const openInfo = Boolean(anchorElInfo);
  const idInfo = open ? "info-popover" : undefined;

  const handleSearchChange = (event) => {
    const searchQuery = event.target.value;
    setQuery(searchQuery);

  };

  const facetEx = async (query) => {
    const solrBaseUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=*:*&rows=10&start=0&fq=search_flag:(1)&sort=lastmodified desc&wt=json`;
    // const solrBaseUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=${query}&rows=10&start=0&fq=search_flag:(1)&wt=json`;
    // const query = '&facet=true&facet.pivot=location_state,jobtype';
    try {
      dispatch(fetchResumeResultsV1(`${solrBaseUrl}${query}`));
    } catch (error) {
      console.error(error);
    }
  };

  // const handleSearchSubmit = async() => {
  //   await insertSearchText(query);
  //   if (query && !searchHistory.includes(query)) {
  //     setSearchHistory([query, ...searchHistory]);
  //   }
  //   setShowHistory(false);
  // };
  
  const insertSearchText = async(search) => {
    try {
      const response =await axios.post(`${Key.domain}/empdashboard/insert_emp_saved_searches`, {
        user_id: currentUser.id,
        session_token: currentUser.session_token,
        keyword: search || '',
        email: '',
        location: '',
        jobtype: '',
        query: '',
      });
      if(response.status === 200){
        console.log("Search Inserted");
      }
    } catch (error) {
      throw error
    }
  }

  const getSearchHistory = async() => {
    try {
      const response =await axios.post(`${Key.domain}/empdashboard/get_emp_saved_searches`, {
        user_id: currentUser.id,
        session_token: currentUser.session_token,
      });
      if(response.status === 200){
        console.log("Search History", response.data.data);
        const searchHistory = response.data.data.map((data) => data.search_title);
        // setSearchHistory([query, ...searchHistory]);
        setSearchHistory(response.data.data);
      }
    } catch (error) {
      throw error
    }
  }

  const deleteSearchHistory = async(data) => {
    // console.log('deleteSearchHistory', data);
    
    try {
      const response =await axios.post(`${Key.domain}/empdashboard/delete_emp_saved_searches`, {
        user_id: currentUser.id,
        session_token: currentUser.session_token,
        id: data.id,
      });
      if(response.status === 200){
        setSearchHistory(prevHistory => prevHistory.filter(item => item.id !== data.id));
      }
    } catch (error) {
      console.error("Error deleting search history:", error);
    }
  }

  const handleSearchSubmit = async (isNew, search_text) => {
    setShowHistory(false);
    if(isNew === "new_text" && search_text){
     await insertSearchText(search_text);
    }
    dispatch(showLoader());
    try {
      // Wrap the openAIExtracteSearchValues in a promise to use async/await
      const data = await new Promise((resolve, reject) => {
        openAIExtracteSearchValues(search_text, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });

      // console.log("Extracted Data:", data);
      const { jobType, state, title, visaType } = data;
      await dispatch(updateResumeSearchParams(data));

      await dispatch(
        updateResumeFilterParams({
          jobtype: "*:*",
          visatype: "*:*",
          state: "*:*",
          city: "*:*",
          total_experience: "*:*",
          lastmodified: null,
        })
      );
    await dispatch(updateResumePaginationParams({ start: 0, rows: 10, currentPage: 1, init:1 }));
      history.push("/employer/resumes");
      dispatch(hideLoader());
    } catch (error) {
      console.error("Error:", error);
      // dispatch(hideLoader());
    }
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };
  
  const highlightMatch = (text, query) => {
    const escapedQuery =query && escapeRegExp(query?.trim());
    
    if (query && escapedQuery.toLowerCase().includes('c\\+\\+') || escapedQuery?.toLowerCase().includes('c \\+\\+')) {
      return <span key={text}>{text}</span>;
    }
  
    const regex = new RegExp(`(${escapedQuery})`, 'gi');
  
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span
          key={index}
          className="highlighted"
          style={{ color: '#1E88E5' }}
        >
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };
  

  const handleClickOpenResumeSearchComponent = () => {
    setOpenResumeSearchComponent(true);
  };

  const handleCloseResumeSearchComponent = () => {
    setOpenResumeSearchComponent(false);
  };

  const handleChange = async (field, value) => {
    // console.log(`handleChange called with field: ${field}, value: ${value}`);
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
    if (field === "location") {
      try {
        const response = await axios.post(
          `${Key.domain}/Jobs/get_joblocations`,
          {
            statename: value,
          }
        );
        if (response.status === 200) {
          setLocationData(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleInputChange = (inputValue) => {
    getLocationByCity(inputValue);
    // console.log("Input Value", inputValue);
  };
  const handleJobTitleInputChange = (inputValue) => {
    getJobTitles(inputValue);
    // console.log("Input Value", inputValue);
  };

  const searchResumeHandler = async () => {
    // await history.push('/employer/resumes');
    // setIsOpenSearch(true);
    handleClickOpenResumeSearchComponent();
  };

  const handleClose = (v) => {
    setFormOpen(v);
  };

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const handleDrawerToggle = () => {
    setOpenMobile((prevState) => !prevState);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const accountHandler = () => {
    setAnchorElUser(null);
  };
  const navItemOnClickHandler = (title, index) => {
    setSelectNavItem(title);
    setSideNavItemIndex(index);
  };
  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null);
  };
  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const logoutHandler = () => {
    setAnchorElUser(null);
    userService.logout();
  };

  // React.useEffect(() => {
  //   getLocationByCity("Ohio");
  // }, [])

  const getLocationByCity = async (value) => {
    try {
      const response = await axios.post(`${Key.domain}/Jobs/get_joblocations`, {
        statename: value,
      });
      if (response.status === 200) {
        // console.log(response.data.data);
        setLocationData(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // const getJobTitles = async(value) => {
  //   try {
  //     const response = await axios.post(`${Key.domain}/Jobs/get_jobtitles`, {
  //       jobtitle: value,
  //     });
  //     if(response.status === 200){
  //       console.log(response.data.data);
  //       setJobTitlesData(response.data.data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  const getJobTitles = async (value) => {
    try {
      const response = await axios.post(
        `${Key.domain}/mysql/get_resume_titles`,
        {
          title: value,
        }
      );
      if (response.status === 200) {
        const titles = response.data.data;
        setJobTitlesData(titles);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getNotifications = () => {
    axios
      .post(`${Key.domain}/Notifications/get_notifications`, {
        user_id: sessionStorage.getItem("id"),
      })
      .then((response) => {
        setNotifications(response.data.data.notifications);
        setNotificationsCount(response.data.data.notificationsCount);
      });
  };

  const updateNotificationReadStatus = (
    notificationid,
    job_id,
    notification_type
  ) => {
    // props.showLoader();
    axios
      .post(`${Key.domain}/Notifications/update_notification_readstatus`, {
        user_id: sessionStorage.getItem("id"),
        notificationId: notificationid,
      })
      .then((response) => {
        getNotifications();

        axios
          .post(`${Key.domain}/Jobs/get_job_details`, { id: job_id })
          .then((response) => {
            if (
              response &&
              response.data !== undefined &&
              response.data !== null &&
              response.data.data !== null &&
              response.data.data !== undefined &&
              response.data.data[0] !== null &&
              response.data.data[0] !== undefined &&
              response.data.data[0].jobDetails
            ) {
              // props.hideLoader();

              // setTimeout(() => {
              if (notification_type === "crm_notes") {
                sessionStorage.setItem("crm_notes", "true");
                //sessionStorage.setItem('notificationsDisplayedBefore', 'true');
                history.push("/Empaccountsettings");
                handleCloseNotificationMenu();
              } else {
                history.push({
                  pathname: "/myJobs",
                  state: {
                    myJobs: "fromNotification",
                    jobid: job_id,
                    jobDetails: response.data.data[0].jobDetails,
                  },
                });
                handleCloseNotificationMenu();
              }
              // }, 1000);
            }
          });
      });
  };
  const viewAllNotificationsHandler = () => {
    handleCloseNotificationMenu();
    history.push("/Notificationslist");
  };
  // const pushtohome = () => {
  //   history.push("/home");
  //   sessionStorage.removeItem("resumeTitle");
  //   sessionStorage.removeItem("cityname");
  // };
  const dashboardClicked = () => {
    sessionStorage.removeItem("resumeTitle");
    sessionStorage.removeItem("cityname");
    sessionStorage.removeItem("inputResumeTitle");
    sessionStorage.removeItem("skillNDescriptionSearch");
    sessionStorage.removeItem("resumeCityName");
  };
  const navItemsEmp = [
    // {title:'Home', to:'/', icon1:<HomeRounded />, icon2:<HomeOutlined />},
    {
      title: "Dashboard",
      to: "/Empdashboard",
      icon1: (
        <DashboardRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/Empdashboard" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <DashboardOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    // {
    //   title: "Create",
    //   to: "/postJob",
    //   icon1: <AddCircle sx={{fontSize:'20px', color: sideNavItemIndex === 1 ? '#2196f3' : '#9e9e9e' }} />,
    //   icon2: <AddCircleOutline sx={{fontSize:'20px', color: '#9e9e9e'}} />,
    // },
    {
      title: "AI Search",
      to: "/employer/ai-search",
      icon1: (
        <ManageSearch
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/ai-search"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <ManageSearchOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Jobs",
      to: "/myJobs",
      icon1: (
        <BusinessCenterRounded
          sx={{
            fontSize: "20px",
            color: location.pathname === "/myJobs" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <BusinessCenterOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Hotlist",
      to: "/employer/hotlist-candidates",
      icon1: (
        <WhatshotRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/hotlist-candidates"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: <WhatshotOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    {
      title: "Contacts",
      to: "/employer/js-contacts",
      icon1: (
        <PersonSearchRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/js-contacts"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <PersonSearchOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Company",
      to:
        sessionStorage.getItem("companyid") &&
        parseInt(sessionStorage.getItem("companyid")) !== 0 &&
        sessionStorage.getItem("companyid") !== null &&
        sessionStorage.getItem("companyid") !== "null" &&
        sessionStorage.getItem("companyid") !== undefined &&
        sessionStorage.getItem("companyid") !== "undefined"
          ? "/company/view/comp_" + sessionStorage.getItem("companyid")
          : "/mycompanies",
      icon1: (
        <LocationCityRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname ===
              `${
                sessionStorage.getItem("companyid") &&
                parseInt(sessionStorage.getItem("companyid")) !== 0 &&
                sessionStorage.getItem("companyid") !== null &&
                sessionStorage.getItem("companyid") !== "null" &&
                sessionStorage.getItem("companyid") !== undefined &&
                sessionStorage.getItem("companyid") !== "undefined"
                  ? "/company/view/comp_" + sessionStorage.getItem("companyid")
                  : "/mycompanies"
              }`
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <LocationCityOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Recruiters",
      to: "/EmpRecruiters",
      icon1: (
        <Groups
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/EmpRecruiters" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <GroupsOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    // {
    //   title: "Resumes",
    //   to: "/role-resumes",
    //   icon1: <LinkedIn sx={{fontSize:'20px', color: (location.pathname === "/EmpRecruiters") ? '#2196f3' : '#9e9e9e'}} />,
    //   icon2: <LinkedIn sx={{fontSize:'20px', color: '#9e9e9e'}} />,
    // },
    {
      title: "Calendar",
      to: "/calendar",
      icon1: (
        <CalendarMonth
          sx={{
            fontSize: "20px",
            color: location.pathname === "/calendar" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <CalendarMonthOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },

    {
      title: "Todo",
      to: "/todo",
      icon1: (
        <CalendarMonth
          sx={{
            fontSize: "20px",
            color: location.pathname === "/todo" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <CalendarMonthOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Saved",
      to: parseInt(currentUser?.usertype) === 2 && "/employer/saved-resumes",
      icon1: (
        <CheckCircleRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/saved-resumes"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: <CheckCircleOutline sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    {
      title: "My Contacts",
      to: parseInt(currentUser?.usertype) === 2 && "/employer/my-contacts",
      icon1: (
        <PeopleAltRoundedIcon
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/my-contacts"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <PeopleAltOutlinedIcon sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
  ];
  const navItemsRecruiter = [
    // {title:'Home', to:'/', icon1:<HomeRounded />, icon2:<HomeOutlined />},
    {
      title: "Dashboard",
      to: "/Empdashboard",
      icon1: (
        <DashboardRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/Empdashboard" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <DashboardOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    // {
    //   title: "Create",
    //   to: "/postJob",
    //   icon1: <AddCircle sx={{fontSize:'20px', color: sideNavItemIndex === 1 ? '#2196f3' : '#9e9e9e' }} />,
    //   icon2: <AddCircleOutline sx={{fontSize:'20px', color: '#9e9e9e'}} />,
    // },
    {
      title: "AI Search",
      to: "/employer/ai-search",
      icon1: (
        <ManageSearch
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/ai-search"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <ManageSearchOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Jobs",
      to: "/myJobs",
      icon1: (
        <BusinessCenterRounded
          sx={{
            fontSize: "20px",
            color: location.pathname === "/myJobs" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <BusinessCenterOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Hotlist",
      to: "/employer/hotlist-candidates",
      icon1: (
        <WhatshotRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/hotlist-candidates"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
        //   <img
        //   src={hotlistIcon}
        //   alt="Job Icon"
        //   style={{
        //     width: "24px",
        //     height: "24px",
        //     filter: location.pathname === "/employer/js-contacts" ? "grayscale(0%)" : "grayscale(100%)",
        //   }}
        // />
      ),
      icon2: <WhatshotOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    {
      title: "Contacts",
      to: "/employer/js-contacts",
      icon1: (
        <PersonSearchRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/js-contacts"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <PersonSearchOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Calendar",
      to: "/calendar",
      icon1: (
        <CalendarMonth
          sx={{
            fontSize: "20px",
            color: location.pathname === "/calendar" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <CalendarMonthOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Todo",
      to: "/todo",
      icon1: (
        <CalendarMonth
          sx={{
            fontSize: "20px",
            color: location.pathname === "/todo" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <CalendarMonthOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Saved",
      to: parseInt(currentUser?.usertype) === 4 && "/recruiter/saved-resumes",
      icon1: (
        <CheckCircleRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/recruiter/saved-resumes"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: <CheckCircleOutline sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
  ];
  const navItemsRecruiterAdmin = [
    // {title:'Home', to:'/', icon1:<HomeRounded />, icon2:<HomeOutlined />},
    {
      title: "Dashboard",
      to: "/Empdashboard",
      icon1: (
        <DashboardRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/Empdashboard" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <DashboardOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    // {
    //   title: "Create",
    //   to: "/postJob",
    //   icon1: <AddCircle sx={{fontSize:'20px', color: sideNavItemIndex === 1 ? '#2196f3' : '#9e9e9e' }} />,
    //   icon2: <AddCircleOutline sx={{fontSize:'20px', color: '#9e9e9e'}} />,
    // },
    {
      title: "AI Search",
      to: "/employer/ai-search",
      icon1: (
        <ManageSearch
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/ai-search"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <ManageSearchOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Jobs",
      to: "/myJobs",
      icon1: (
        <BusinessCenterRounded
          sx={{
            fontSize: "20px",
            color: location.pathname === "/myJobs" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <BusinessCenterOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Hotlist",
      to: "/employer/hotlist-candidates",
      icon1: (
        <WhatshotRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/hotlist-candidates"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: <WhatshotOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    {
      title: "Contacts",
      to: "/employer/js-contacts",
      icon1: (
        <PersonSearchRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/js-contacts"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <PersonSearchOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Recruiters",
      to: "/EmpRecruiters",
      icon1: (
        <Groups
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/EmpRecruiters" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <GroupsOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    // {
    //   title: "Resumes",
    //   to: "/role-resumes",
    //   icon1: <LinkedIn sx={{fontSize:'20px', color: (location.pathname === "/EmpRecruiters") ? '#2196f3' : '#9e9e9e'}} />,
    //   icon2: <LinkedIn sx={{fontSize:'20px', color: '#9e9e9e'}} />,
    // },
    {
      title: "Calendar",
      to: "/calendar",
      icon1: (
        <CalendarMonth
          sx={{
            fontSize: "20px",
            color: location.pathname === "/calendar" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <CalendarMonthOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },

    {
      title: "Todo",
      to: "/todo",
      icon1: (
        <CalendarMonth
          sx={{
            fontSize: "20px",
            color: location.pathname === "/todo" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <CalendarMonthOutlined sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
    {
      title: "Saved",
      to:
        parseInt(currentUser?.usertype) === 2
          ? "/employer/saved-resumes"
          : parseInt(currentUser?.usertype) === 12 &&
            "/recruiter/saved-resumes",
      icon1: (
        <CheckCircleRounded
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/saved-resumes"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: <CheckCircleOutline sx={{ fontSize: "20px", color: "#9e9e9e" }} />,
    },
    {
      title: "My Contacts",
      to:
        parseInt(currentUser?.usertype) === 2 ||
        (parseInt(currentUser?.usertype) === 12 && "/employer/my-contacts"),
      icon1: (
        <PeopleAltRoundedIcon
          sx={{
            fontSize: "20px",
            color:
              location.pathname === "/employer/my-contacts"
                ? "#2196f3"
                : "#9e9e9e",
          }}
        />
      ),
      icon2: (
        <PeopleAltOutlinedIcon sx={{ fontSize: "20px", color: "#9e9e9e" }} />
      ),
    },
  ];

  const navItems =
    parseInt(currentUser?.usertype) === 2
      ? navItemsEmp
      : currentUser?.usertype == 12
      ? navItemsRecruiterAdmin
      : navItemsRecruiter;
  React.useEffect(() => {
    const currentPath = location.pathname;
    const selectedIndex = navItems.findIndex((item) => item.to === currentPath);
    setSideNavItemIndex(selectedIndex !== -1 ? selectedIndex : null);
    if (location.pathname === "/Empdashboard") {
      dashboardClicked();
    }
  }, [location.pathname, sideNavItemIndex]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ py: 1, bgcolor: "#406882" }}>
        <Link to="/">
        <img src={lightLog} width={150} />
        </Link>
      </Typography>
      <Divider />
      <List sx={{}}>
        {navItems?.map((item) => (
          <ListItem key={item.title} disablePadding>
            <Link to={item.to}>
              <ListItemButton>
                <ListItemIcon>{item.icon1}</ListItemIcon>
                <ListItemText
                  primary={item.title}
                  sx={{ color: "rgba(0, 0, 0, 0.7)" }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ boxShadow: "none", 
          bgcolor: "#c6c6c6",
         }}
      >
        <Toolbar sx={{ minHeight: "62px !important" }}>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
           
          <img src={lightLog} width={180} />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }} />
          <>
            {isOpenSearch ? (
              <Box sx={{ display: "flex", gap: "5px" }}>
                <Search sx={{ flexGrow: 1, display: "flex" }}>
                  {/* <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper> */}
                  <Autocomplete
                    // freeSolo
                    size="small"
                    autoFocus
                    disablePortal
                    options={jobTitlesData}
                    getOptionLabel={(option) => option.title}
                    value={formData.title}
                    onInputChange={(event, newInputValue) => {
                      // console.log(
                      //   "onInputChange called with value:",
                      //   newInputValue
                      // );
                      handleJobTitleInputChange(newInputValue);
                    }}
                    onChange={(event, newValue) =>
                      handleChange("title", newValue)
                    }
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        placeholder="Search for title"
                        autoFocus
                        // inputProps={{ ...params.inputProps, 'aria-label': 'search' }}
                      />
                    )}
                    id="title"
                    // sx={{width:'25ch', '&:hover': {width:'50ch'}}}
                  />
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Autocomplete
                    // freeSolo
                    size="small"
                    disablePortal
                    options={locationData}
                    getOptionLabel={(option) =>
                      `${option.cityName}, ${option.stateName}`
                    }
                    value={formData.skills}
                    // onInputChange={(event, newInputValue) => {
                    //   console.log('onInputChange called with value:', newInputValue);
                    //   handleInputChange(newInputValue);
                    // }}
                    onChange={(event, newValue) =>
                      handleChange("skills", newValue)
                    }
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        placeholder="Search for skills"
                        // inputProps={{ ...params.inputProps, 'aria-label': 'search' }}
                      />
                    )}
                    id="skills"
                    // sx={{width:'25ch', '&:hover': {width:'50ch'}}}
                  />
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Autocomplete
                    // freeSolo
                    size="small"
                    disablePortal
                    options={locationData}
                    getOptionLabel={(option) =>
                      `${option.cityName}, ${option.stateName}`
                    }
                    value={formData.location}
                    onInputChange={(event, newInputValue) => {
                      // console.log(
                      //   "onInputChange called with value:",
                      //   newInputValue
                      // );
                      handleInputChange(newInputValue);
                    }}
                    onChange={(event, newValue) =>
                      handleChange("location", newValue)
                    }
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        placeholder="Search for location"
                        // inputProps={{ ...params.inputProps, 'aria-label': 'search' }}
                      />
                    )}
                    id="location"
                    // sx={{width:'25ch', '&:hover': {width:'50ch'}}}
                  />
                </Search>
              </Box>
            ) : (
              // <Search>
              //   <SearchIconWrapper>
              //     <SearchIcon />
              //   </SearchIconWrapper>
              //   <StyledInputBase
              //     placeholder="Search"
              //     inputProps={{ "aria-label": "search" }}
              //     value={query}
              //     onChange={(e) => setQuery(e.target.value)}
              //     onKeyDown={(e) => e.key === "Enter" && handleSearchSubmit()}
              //   />
              //   <IconWrapper onClick={handleInfoClick}>
              //     <InfoOutlined />
              //   </IconWrapper>
              //   <IconWrapper>
              //     <Button
              //       variant="contained"
              //       disableElevation
              //       size="small"
              //       color="primary"
              //       onMouseDown={handleSearchSubmit}
              //       sx={{ paddingInline: "7px", fontSize: "12px" }}
              //     >
              //       Search
              //     </Button>
              //   </IconWrapper>
              //   <Popover
              //     id={idInfo}
              //     open={openInfo}
              //     anchorEl={anchorElInfo}
              //     onClose={handleInfoClose}
              //     anchorOrigin={{
              //       vertical: "bottom",
              //       horizontal: "left",
              //     }}
              //     transformOrigin={{
              //       vertical: "top",
              //       horizontal: "left",
              //     }}
              //   >
              //     <Box sx={{ p: 2 }}>
              //       <Typography sx={{ p: "6px" }}>Eg:</Typography>
              //       <Typography sx={{ p: "2px" }}>
              //         1. Looking for a Java developer in California with
              //         fulltime and H1B visa
              //       </Typography>
              //       <Typography sx={{ p: "2px" }}>
              //         2. Seeking Python engineers in New York with TN visa
              //       </Typography>
              //       <Typography sx={{ p: "2px" }}>
              //         3. I want SQL profiles in Texas with fulltime and USC visa
              //       </Typography>
              //       <Typography sx={{ p: "2px" }}>
              //         4. Looking for AWS roles with contract and OPT visa
              //       </Typography>
              //       <Typography sx={{ p: "2px" }}>
              //         5. Looking for profiles in Arizona with fulltime work and
              //         EAD visa
              //       </Typography>
              //       <Typography sx={{ p: "2px" }}>
              //         6. python developer 
              //       </Typography>
              //       <Typography sx={{ p: "2px" }}>
              //         7. texas profiles
              //       </Typography>
              //       <Typography sx={{ p: "2px" }}>
              //         8. fulltime work profiles
              //       </Typography>
              //       <Typography sx={{ p: "2px" }}>
              //         9. USC Visa profiles
              //       </Typography>
              //       <Typography sx={{ p: "2px" }}>
              //         10. Search with ID Need to add # before ID {`(#309132)`}
              //       </Typography>
              //     </Box>
              //   </Popover>
              // </Search>
              <Search ref={searchRef} sx={{
                borderBottomRightRadius: showHistory && 0,
                borderBottomLeftRadius: showHistory && 0,
                backgroundColor: showHistory && '#fff',
              }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                value={query}
                onChange={(e) => handleSearchChange(e)}
                onFocus={handleFocus}
                onKeyDown={(e) => e.key === 'Enter' && handleSearchSubmit('new_text', query)}
              />
              {showHistory && (
                <SearchHistory component={List}
                sx={{
                  borderTopRightRadius: showHistory ? 0 : '',
                  borderTopLeftRadius: showHistory ? 0 : '',
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
                  // sx={{
                  //   position: 'absolute',
                  //   top: '100%',
                  //   left: 0,
                  //   right: 0,
                  //   bgcolor: 'background.paper',
                  //   boxShadow: 3,
                  //   zIndex: 10,
                  //   maxHeight: '200px',
                  //   overflowY: 'auto',
                  //   borderRadius: '3px',
                  // }}
                >
                  <Box sx={{pl:2, pr:2}}>
              <Divider />
                  </Box>
              {searchHistory.length > 0 &&
                searchHistory.map((data, index) => (
                  <ListItem
                    key={data.id}
                    sx={{
                      // border: "1px solid rgba(229, 234, 242, 0.5)",
                      // mb: "6px",
                      backgroundColor: "#f3f6f966",
                      // borderRadius: "10px",
                      cursor: "pointer",
                      pt: '3px',
                      pb: '3px',
                      ".keyboardIcon": {
                        display: "none",
                      },
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        // border: "1px solid #64b5f6",
                        ".keyboardIcon": {
                          display: "block",
                        },
                        "& .highlighted": {
                          textDecoration: "underline",
                        },
                        "& .hoverColor": {
                            color:'#1E88E5'
                        },
                      },
                    }}
                  >
                    <HistoryRounded className="hoverColor"
                      sx={{ fontSize: 18, mr: 2, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatch(data.keyword)}
                      primaryTypographyProps={{ fontSize: "13px" }}
                      onClick={() => {
                        setQuery(data.keyword);
                        handleSearchSubmit("old_text", data.keyword);
                      }}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    {/* <KeyboardReturnRounded
                      sx={{ color: "#1E88E5" }}
                      className="keyboardIcon"
                    /> */}
                    <DeleteOutlineRounded 
                      className="keyboardIcon"
                      sx={{ color: "#1E88E5",fontSize: 18, "&:hover":{color:'#d32f2f'} }}
              onClick={(e) => {
                e.stopPropagation();
                deleteSearchHistory(data);
              }}
                    /> 
                    {/* <Button variant="text" color="primary" size="small" 
                      className="keyboardIcon"
                    sx={{
                      textTransform: 'capitalize',
                      padding: 0,
                      // paddingInline: '7px',
                      }}>
                      Delete
                    </Button> */}
                  </ListItem>
                ))}
                  {/* {searchHistory.map((item, index) => (
                    <Typography
                      key={index}
                      sx={{
                        padding: theme => theme.spacing(1),
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: alpha('#406882', 0.1),
                        },
                      }}
                      onClick={() => {
                        setQuery(item);
                        handleSearchSubmit();
                      }}
                    >
                      {item}
                    </Typography>
                  ))} */}
                </SearchHistory>
              )}
              <IconWrapper onClick={handleInfoClick}>
                <InfoOutlined />
              </IconWrapper>
              <IconWrapper>
                <Button
                  variant="contained"
                  disableElevation
                  size="small"
                  color="primary"
                  onMouseDown={() => handleSearchSubmit("new_text", query)}
                  sx={{ paddingInline: '7px', fontSize: '12px' }}
                >
                  Search
                </Button>
              </IconWrapper>
              <Popover
                  id={idInfo}
                  open={openInfo}
                  anchorEl={anchorElInfo}
                  onClose={handleInfoClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box sx={{ p: 2 }}>
                    <Typography sx={{ p: "6px" }}>Eg:</Typography>
                    <Typography sx={{ p: "2px" }}>
                      1. Looking for a Java developer in California with
                      fulltime and H1B visa
                    </Typography>
                    <Typography sx={{ p: "2px" }}>
                      2. Seeking Python engineers in New York with TN visa
                    </Typography>
                    <Typography sx={{ p: "2px" }}>
                      3. I want SQL profiles in Texas with fulltime and USC visa
                    </Typography>
                    <Typography sx={{ p: "2px" }}>
                      4. Looking for AWS roles with contract and OPT visa
                    </Typography>
                    <Typography sx={{ p: "2px" }}>
                      5. Looking for profiles in Arizona with fulltime work and
                      EAD visa
                    </Typography>
                    <Typography sx={{ p: "2px" }}>
                      6. python developer 
                    </Typography>
                    <Typography sx={{ p: "2px" }}>
                      7. texas profiles
                    </Typography>
                    <Typography sx={{ p: "2px" }}>
                      8. fulltime work profiles
                    </Typography>
                    <Typography sx={{ p: "2px" }}>
                      9. USC Visa profiles
                    </Typography>
                    <Typography sx={{ p: "2px" }}>
                      10. Search with ID Need to add # before ID {`(#309132)`}
                    </Typography>
                  </Box>
                </Popover>
            </Search>
        
            )}
          </>

          <Box sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }} />

          {/* <Box sx={{ display: { xs: "block", md: "none" }, flexGrow: 1 }} /> */}
          <Box sx={{ display: { xs: "flex", md: "flex" }, alignItems: 'center', gap: 2 }}>
            {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <Mail />
              </Badge>
            </IconButton> */}
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleOpenNotificationMenu}
            >
              <Badge badgeContent={notificationsCount} color="error">
                <Notifications />
              </Badge>
            </IconButton> */}
             <Badge badgeContent={notificationsCount} color="error">
                <IoNotificationsOutline style={{ color: "#585858", height:'26px', width:'26px', cursor: 'pointer' }}  onClick={handleOpenNotificationMenu} />
              </Badge>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorElNotification}
              open={openNotification}
              onClose={handleCloseNotificationMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 5.5,
                  width: "40ch",
                  // padding: "4px",
                },
              }}
              // title="Notifications"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "4px",
                }}
              >
                <p>Notifications</p>
                <p
                  onClick={viewAllNotificationsHandler}
                  style={{ cursor: "pointer", color: "#406882" }}
                >
                  View All
                </p>
              </div>
              <Divider sx={{ margin: 0 }} />
              {notifications.map((notify) => (
                <MenuItem
                  key={notify.id}
                  onClick={() =>
                    notify.notification_subject !==
                    "Your recruiter deleted a Job"
                      ? updateNotificationReadStatus(
                          notify.id,
                          notify.job_id,
                          notify.notification_type
                        )
                      : ""
                  }
                >
                  <Stack direction="row" spacing={1}>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={notify.notification_read.includes(
                        sessionStorage.getItem("id")
                      )}
                    >
                      <Message sx={{ color: "rgba(0,0,0,0.4)" }} />
                    </Badge>
                    <Stack direction="column" spacing={0}>
                      <p
                        style={{
                          overflowWrap: "break-word",
                          whiteSpace: "normal",
                          margin: 0,
                          fontSize: "14px",
                        }}
                      >
                        {notify.notification_subject}
                      </p>
                      <small>{moment(notify.created).fromNow()}</small>
                    </Stack>
                  </Stack>
                </MenuItem>
              ))}
            </Menu>

            {/* <Box sx={{ flexGrow: 0 }}> */}
            {/* <Tooltip title="My Account">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={currentUser?.full_name}
                  src={
                    sessionStorage.getItem("profileimg") &&
                    sessionStorage.getItem("profileimg") !== "null" &&
                    `${Key.s3_files_url}/user_${sessionStorage.getItem(
                      "id"
                    )}/profileimages/${sessionStorage.getItem("profileimg")}`
                  }
                  sx={{ height: "34px", width: "34px" }}
                />
              </IconButton>
            </Tooltip> */}
             <Box sx={{ display: "flex", gap: "4px", cursor: 'pointer' }} onClick={handleOpenUserMenu}>
             <Avatar
                  alt={currentUser?.full_name}
                  src={
                    sessionStorage.getItem("profileimg") &&
                    sessionStorage.getItem("profileimg") !== "null" &&
                    `${Key.s3_files_url}/user_${sessionStorage.getItem(
                      "id"
                    )}/profileimages/${sessionStorage.getItem("profileimg")}`
                  }
                  sx={{border:'2px solid white' }}
                />
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Tooltip title={currentUser?.full_name}>
                <Typography variant="body1"
                  sx={{
                    // color: "#05264C",
                    color: "#000",
                    m: 0,
                    fontSize: "14px",
                    fontWeight: 600,
                    maxWidth: 150,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: 'capitalize'
                  }}
                >
                  {currentUser?.full_name}
                </Typography>
                </Tooltip>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography variant="body1"
                  sx={{ 
                    color:  "#000",//"#66789C", 
                    m: 0, 
                    fontSize: "12px", 
                    maxWidth: 150,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: 'capitalize'
                   }}
                >
                  { currentUser?.role !== undefined &&
                      currentUser?.role !== null &&
                      currentUser?.role !== ""
                    ? currentUser.role
                   : "Employee"}
                  
                </Typography>
                <IoMdArrowDropdown
                    style={{ color: "#05264C", fontSize: "14px" }}
                  />
                </Box>
              </Box>
            </Box>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))} */}
              <MenuItem key="account" onClick={accountHandler}>
                <Typography textAlign="center">
                  <Link to="/Empaccountsettings">Account</Link>
                </Typography>
              </MenuItem>
              <MenuItem key="logout" onClick={logoutHandler}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
            {/* </Box> */}
          </Box>
        </Toolbar>
        <Modal open={formOpen} onClose={() => setFormOpen(false)}>
          <Empgeneralsearch formOpen={formOpen} handleClose={handleClose} />
        </Modal>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{ ...DrawerStyle, display: { xs: "none", md: "block" }, }}
      >
        {/* <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader> */}
        {/* <Divider /> */}
        <List sx={{ pt: "68px" }}>
          {navItems.map((item, index) => (
            <ListItem key={item} disablePadding sx={{ display: "block" }}>
              <Tooltip title={item?.title} placement="right">
                <Link to={item?.to}>
                  <ListItemButton
                    onClick={() => navItemOnClickHandler(item?.title, index)}
                    key={index}
                    selected={sideNavItemIndex === index}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      //   px: 2.5,
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "10px",
                      color: "#9e9e9e",
                      borderLeft:
                        sideNavItemIndex === index
                          ? "2px solid #2196f3"
                          : "none",
                      marginLeft: "5px",
                      padding: "5px",
                      fontWeight: 600
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : 'auto',
                        justifyContent: "center",
                      }}
                    >
                      {sideNavItemIndex === index ? item.icon1 : item.icon2}
                    </ListItemIcon>
                    {/* <ListItemText primary={text} sx={{fontSize:'10px'}}
                // sx={{ opacity: open ? 1 : 0 }}
                 /> */}
                    {item.title}
                  </ListItemButton>
                </Link>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        {/* <Divider /> */}
      </Drawer>
      <MuiDrawer
        // anchor={anchor}
        open={openMobile}
        onClose={handleDrawerToggle}
        sx={{ zIndex: 1301 }}
      >
        {drawer}
      </MuiDrawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          background:'#f8f8f8',
          // minHeight:'100vh'
          // mt: 2,
          pl: 1.5,
          pt: 3,
          pb: 1,
          pr: 1,
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openResumeSearchComponent}
        onClose={handleCloseResumeSearchComponent}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openResumeSearchComponent}>
          <Box sx={styleModal}>
            <SearchComponent closeHandler={handleCloseResumeSearchComponent} />
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "0",
  borderRadius: "8px",
  boxShadow: 24,
  p: 0,
};
