import axios from "axios";

import Key from "../../clientVariables.json";
import { resumeQueryBuilder } from "../../utils/resumeQueryBuilder";
import {
  CLEAR_JOB_SEARCH_RESULTS,
  FETCH_JOB_RESULTS_FAILURE,
  FETCH_JOB_RESULTS_SUCCESS,
  JOB_DETAILS,
  JOB_DETAILS_FAIL,
  SET_SELECTED_JOB,
  UPDATE_JOB_FILTER_PARAMS,
  UPDATE_JOB_PAGINATION_PARAMS,
  UPDATE_JOB_SEARCH_PARAMS,
} from "./actionTypes";

export const updateJobSearchParams = (params) => ({
  type: UPDATE_JOB_SEARCH_PARAMS,
  payload: params,
});

export const updateJobFilterParams = (params) => ({
  type: UPDATE_JOB_FILTER_PARAMS,
  payload: params,
});

export const updateJobPaginationParams = (params) => ({
  type: UPDATE_JOB_PAGINATION_PARAMS,
  payload: params,
});

// export const solrQueryWithoutFilters = (query) => ({
//   type: SOLR_QUERY_WITHOUT_FILTERS,
//   payload: query,
// });

export const fetchJobResultsV1 = (url) => async (dispatch, getState) => {
  try {
    // console.log("Fetch Results: url", url);
    const response = await axios.post(`${Key.domain}/solr/solr_jobs`, {
      url: url,
    });
    // console.log("Fetch Results: 123", response.data.data);

    // if (response.data.data.response.docs.length > 0) {
      dispatch({
        type: FETCH_JOB_RESULTS_SUCCESS,
        payload: {
          response: response.data.data.response.docs,
          numFound: response.data.data.response.numFound,
          facet_counts: response.data.data.facet_counts,
        },
      });
      dispatch(setSelectedJob(response.data.data.response.docs[0]));
      dispatch(getJobDetailsAction(response.data.data.response.docs[0].id));
    // }
    // else{
    //     dispatch(clearJobSearchResults());
    // }
  } catch (error) {
    dispatch({
      type: FETCH_JOB_RESULTS_FAILURE,
      payload: error.message,
    });
  }
};

export const setSelectedJob = (job) => ({
  type: SET_SELECTED_JOB,
  payload: job,
});

export const clearJobSearchResults = () => ({
  type: CLEAR_JOB_SEARCH_RESULTS,
});

export const getJobDetailsAction = (jobId) => async (dispatch) => {
  try {
    const response = await axios.post(`${Key.domain}/Jobs/get_job_details`, {
      id: jobId,
    });
    dispatch({
      type: JOB_DETAILS,
      payload: response.data?.data[0],
    });
  } catch (err) {
    dispatch({
      type: JOB_DETAILS_FAIL,
      payload: err.message,
    });
  }
};

