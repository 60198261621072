const { Configuration, OpenAIApi } = require("openai");
const { openai_key } = require("../../../../../clientVariables.json");

export const openAIExtracteSearchValues = async (message, callback) => {
  const configuration = new Configuration({
    apiKey: openai_key,
  });

  const prompt = `Extract the following components from the search query: job title, location state, job type, visa type, total experience, ID, and email. The search query is: "${message}".

1. **Job Title**: Extract the job title (e.g., SQL, Java, Python, ServiceNow, salesforce, dotnet, .net, c#). Return \`null\` if not found or if specified as "Not specified" or "N/A".
2. **Location State**: Identify the state (e.g., Texas, New york). Return \`null\` if not found or if specified as "Not specified" or "N/A".
3. **Job Type**: Standardize the job type to one of the following: "Full-Time", "Part-Time", "Contract", "Internship". Match alternative forms (e.g., "fulltime" → "Full-Time"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
4. **Visa Type**: Standardize the visa type to one of the following: "H1", "OPT", "F1", "TN", "EAD", "L1", "H1B", "CPT". Match alternative forms (e.g., "h1b" → "H1B"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
5. **Total Experience**: Extract the total years of experience as a number. Return \`null\` if not found or if specified as "Not specified" or "N/A".
6. **ID**: Extract the ID, which is prefixed with a \`#\` symbol (e.g., "#745676"). Return \`null\` if not found.
7. **Email**: Extract the email address, which contains an \`@\` symbol (e.g., "example@mail.com"). Return \`null\` if not found.

Return the extracted components in the following format:
{
  "title": "<Job Title>",
  "state": "<Location State>",
  "jobType": "<Job Type>",
  "visaType": "<Visa Type>",
  "experience": "<Total Experience>",
  "id": "<ID>",
  "email": "<Email>"
}`;

  try {
    const openai = new OpenAIApi(configuration);
    const chatCompletion = await openai.createChatCompletion({
      model: "gpt-4o-mini",
      messages: [{ role: "user", content: prompt }],
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      temperature: 0,
    });

    let result = chatCompletion.data.choices[0].message.content;
    console.log("API Response:", result);

    const jsonMatch = result.match(/\{[\s\S]*\}/);
    let parsedResult;

    if (jsonMatch) {
      try {
        parsedResult = JSON.parse(jsonMatch[0]); 
      } catch (err) {
        console.error("Failed to parse extracted JSON:", err);
        return callback(new Error("Failed to parse API response. The JSON extraction failed."));
      }
    } else {
      console.error("No JSON found in the API response.");
      return callback(new Error("No valid JSON found in the API response."));
    }

    const fallbackData = {
      title: null,
      state: null,
      jobType: null,
      visaType: null,
      experience: null,
      id: null,
      email: null,
    };

    const data = {
      ...fallbackData,
      ...parsedResult,
    };

    data.title = data.title === "Not specified" || data.title === "N/A" ? null : data.title;
    data.state = data.state === "Not specified" || data.state === "N/A" ? null : data.state;
    data.jobType = data.jobType === "Not specified" || data.jobType === "N/A" ? null : data.jobType;
    data.visaType = data.visaType === "Not specified" || data.visaType === "N/A" ? null : data.visaType;
    data.experience = data.experience === "Not specified" || data.experience === "N/A" ? null : data.experience;
    data.id = data.id === "Not specified" || data.id === "N/A" ? null : data.id;
    data.email = data.email === "Not specified" || data.email === "N/A" ? null : data.email;

    return callback(null, data);
  } catch (e) {
    console.error("Error with OpenAI API call:", e);
    return callback(e);
  }
};

// export const openAIExtracteSearchValues = async (message, callback) => {
//   const configuration = new Configuration({
//     apiKey: openai_key,
//   });

//   const prompt = `Extract the following components from the search query: job title, location state, job type, visa type, and total experience. The search query is: "${message}".

// 1. **Job Title**: Extract the job title (e.g., SQL, Java, Python, ServiceNow). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 2. **Location State**: Identify the state (e.g., Texas). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 3. **Job Type**: Standardize the job type to one of the following: "Full-Time", "Part-Time", "Contract", "Internship". Match alternative forms (e.g., "fulltime" → "Full-Time"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 4. **Visa Type**: Standardize the visa type to one of the following: "H1", "OPT", "F1", "TN", "EAD", "L1", "H1B", "CPT". Match alternative forms (e.g., "h1b" → "H1B"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 5. **Total Experience**: Extract the total years of experience as a number. Return \`null\` if not found or if specified as "Not specified" or "N/A".

// Return the extracted components in the following format:
// {
//   "title": "<Job Title>",
//   "state": "<Location State>",
//   "jobType": "<Job Type>",
//   "visaType": "<Visa Type>",
//   "experience": "<Total Experience>"
// }`;

//   try {
//     const openai = new OpenAIApi(configuration);
//     const chatCompletion = await openai.createChatCompletion({
//       model: "gpt-4o-mini",
//       messages: [{ role: "user", content: prompt }],
//       top_p: 1,
//       frequency_penalty: 0,
//       presence_penalty: 0,
//       temperature: 0,
//     });

//     let result = chatCompletion.data.choices[0].message.content;
//     console.log("API Response:", result);

//     const jsonMatch = result.match(/\{[\s\S]*\}/);
//     let parsedResult;

//     if (jsonMatch) {
//       try {
//         parsedResult = JSON.parse(jsonMatch[0]); 
//       } catch (err) {
//         console.error("Failed to parse extracted JSON:", err);
//         return callback(new Error("Failed to parse API response. The JSON extraction failed."));
//       }
//     } else {
//       console.error("No JSON found in the API response.");
//       return callback(new Error("No valid JSON found in the API response."));
//     }

//     const fallbackData = {
//       title: null,
//       state: null,
//       jobType: null,
//       visaType: null,
//       experience: null,
//     };

//     const data = {
//       ...fallbackData,
//       ...parsedResult,
//     };

//     data.title = data.title === "Not specified" || data.title === "N/A" ? null : data.title;
//     data.state = data.state === "Not specified" || data.state === "N/A" ? null : data.state;
//     data.jobType = data.jobType === "Not specified" || data.jobType === "N/A" ? null : data.jobType;
//     data.visaType = data.visaType === "Not specified" || data.visaType === "N/A" ? null : data.visaType;
//     data.experience = data.experience === "Not specified" || data.experience === "N/A" ? null : data.experience;

//     return callback(null, data);
//   } catch (e) {
//     console.error("Error with OpenAI API call:", e);
//     return callback(e);
//   }
// };


// export const openAIExtracteSearchValues = async (message, callback) => {
//   const configuration = new Configuration({
//     apiKey: openai_key,
//   });

//   //   const prompt = `
//   //     Extract the following components from the search query: job title, location state, job type, visa type, and total experience. The search query is: "${message}"

//   //     1. Job Title: Extract the specific job title mentioned (e.g., SQL, Java, Python). If not found or if it says "Not specified" or "N/A", return null.
//   //     2. Location State: Identify the state mentioned in the query (e.g., Texas). If not found or if it says "Not specified" or "N/A", return null.
//   //     3. Job Type: Identify the job type mentioned (e.g., fulltime, parttime, contract, internship). If not found or if it says "Not specified" or "N/A", return null.
//   //     4. Visa Type: Identify the visa type mentioned (e.g., H1, OPT, F1, TN, EAD, L1, H1B, CPT). If not found or if it says "Not specified" or "N/A", return null.

//   //     Return the extracted components in the following format:
//   //     {
//   //       "title": "<Job Title>",
//   //       "state": "<Location State>",
//   //       "jobType": "<Job Type>",
//   //       "visaType": "<Visa Type>"
//   //     }
//   //     `;

//   // const prompt = `
//   // Extract the following components from the search query: job title, location state, job type, visa type, and total experience. The search query is: "${message}"

//   // 1. Job Title: Extract the specific job title mentioned (e.g., SQL, Java, Python, servicenow). If not found or if it says "Not specified" or "N/A", return null.
//   // 2. Location State: Identify the state mentioned in the query (e.g., Texas). If not found or if it says "Not specified" or "N/A", return null.
//   // 3. Job Type: Identify the job type mentioned. Standardize the job type to one of the following forms: "Full-Time", "Part-Time", "Contract", "Internship". Match any alternative forms to the standard form (e.g., "fulltime", "full time" → "Full-Time"; "parttime", "part time" → "Part-Time"). If not found or if it says "Not specified" or "N/A", return null.
//   // 4. Visa Type: Identify the visa type mentioned. Standardize the visa type to one of the following forms: "H1", "OPT", "F1", "TN", "EAD", "L1", "H1B", "CPT". Match any alternative forms to the standard form (e.g., "h1b" → "H1B"; "opt" → "OPT"). If not found or if it says "Not specified" or "N/A", return null.
//   // 5. Total Experience: Identify the total years of experience mentioned. If not found or if it says "Not specified" or "N/A", return null it must return number.

//   // Return the extracted components in the following format:
//   // {
//   //   "title": "<Job Title>",
//   //   "state": "<Location State>",
//   //   "jobType": "<Job Type>",
//   //   "visaType": "<Visa Type>",
//   //   "experience": "<Total Experience>"
//   // }
//   // `;

//   const prompt = `Extract the following components from the search query: job title, location state, job type, visa type, and total experience. The search query is: "${message}".

// 1. **Job Title**: Extract the job title (e.g., SQL, Java, Python, ServiceNow). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 2. **Location State**: Identify the state (e.g., Texas). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 3. **Job Type**: Standardize the job type to one of the following: "Full-Time", "Part-Time", "Contract", "Internship". Match alternative forms (e.g., "fulltime" → "Full-Time"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 4. **Visa Type**: Standardize the visa type to one of the following: "H1", "OPT", "F1", "TN", "EAD", "L1", "H1B", "CPT". Match alternative forms (e.g., "h1b" → "H1B"). Return \`null\` if not found or if specified as "Not specified" or "N/A".
// 5. **Total Experience**: Extract the total years of experience as a number. Return \`null\` if not found or if specified as "Not specified" or "N/A".

// Return the extracted components in the following format:
// {
//   "title": "<Job Title>",
//   "state": "<Location State>",
//   "jobType": "<Job Type>",
//   "visaType": "<Visa Type>",
//   "experience": "<Total Experience>"
// }`;

//   try {
//     const openai = new OpenAIApi(configuration);
//     const chatCompletion = await openai.createChatCompletion({
//       model: "gpt-4o-mini",
//       messages: [{ role: "user", content: prompt }],
//       top_p: 1,
//       frequency_penalty: 0,
//       presence_penalty: 0,
//       temperature: 0,
//     });

//     const result = chatCompletion.data.choices[0].message.content;

//     const parsedResult = JSON.parse(result);

//     const data = {
//       title:
//         parsedResult.title === "Not specified" || parsedResult.title === "N/A"
//           ? null
//           : parsedResult.title,
//       state:
//         parsedResult.state === "Not specified" || parsedResult.state === "N/A"
//           ? null
//           : parsedResult.state,
//       jobType:
//         parsedResult.jobType === "Not specified" ||
//         parsedResult.jobType === "N/A"
//           ? null
//           : parsedResult.jobType,
//       visaType:
//         parsedResult.visaType === "Not specified" ||
//         parsedResult.visaType === "N/A"
//           ? null
//           : parsedResult.visaType,
//       experience:
//         parsedResult.experience === "Not specified" ||
//         parsedResult.experience === "N/A"
//           ? null
//           : parsedResult.experience,
//     };

//     return callback(null, data);
//   } catch (e) {
//     return callback(e);
//   }
// };
