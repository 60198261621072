import axios from "axios";
import Key from '../../../../../src/clientVariables.json'

// Action Types
export const OPEN_EDIT_BOX = 'OPEN_EDIT_BOX';
export const CLOSE_EDIT_BOX = 'CLOSE_EDIT_BOX';
export const SET_CURRENT_SECTION = 'SET_CURRENT_SECTION'; 
export const SET_ACTION_TYPE = 'SET_ACTION_TYPE'
export const JS_FULL_RESUME_DETAILS_SUCCESS = 'JS_FULL_RESUME_DETAILS_SUCCESS';
export const JS_FULL_RESUME_DETAILS_FAIL = 'JS_FULL_RESUME_DETAILS_FAIL'

// Action Creators
export const openEditBox = (templateId) => ({
  type: OPEN_EDIT_BOX,
  payload: { templateId},
});

export const closeEditBox = () => ({
  type: CLOSE_EDIT_BOX,
});


export const setCurrentSection = (section ,id) => ({ 
    type: SET_CURRENT_SECTION,
    payload: {section ,id },
  });

  export const setActionType = (resumeActionType) => ({  
    type: SET_ACTION_TYPE,
    payload: resumeActionType,
  });


  export const jsResumeDetails = (resumeId) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${Key.domain}/resume/get_js_resume_details`,
        {
          user_id:  null,
          resumeid: resumeId,
          session_token:  null,
        }
      );
      dispatch({
        type: JS_FULL_RESUME_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });
       return response
    } catch (err) {
      dispatch({
        type: JS_FULL_RESUME_DETAILS_FAIL,
        payload: err.message,
      });
    }
  };