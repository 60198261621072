import { Avatar, Box, Button, Grid, Link, Paper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Key from "../../../clientVariables.json";
import { useLocation } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { getContrastingTextColor } from "../imigration/contrastColorGenerator";
import {
  EmailOutlined,
  LocalPhoneOutlined,
  LocationOnOutlined,
  StarsRounded,
  TagRounded,
  VerifiedRounded,
} from "@mui/icons-material";
import { FaLinkedin } from "react-icons/fa6";
import EmployeeListView from "./EmployeeListView";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const Employees = ({ companyData }) => {
  const [employeeData, setEmployeeData] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState();
  const [view, setView] = useState("quilt");

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  useEffect(() => {
    setLoading(true);
    getEmployeesData(companyData?.id);
  }, [companyData]);

  const getEmployeesData = async (id) => {
    try {
      const response = await axios.post(
        `https://crmapi.jobsnprofiles.com/company/get_Company_Contacts`,
        {
          company_id: id,
          page: 1,
          state: "",
        }
      );
      console.log("getEmployeesData", response.data.data.companyContacts);
      setEmployeeData(response.data.data.companyContacts);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Paper
      elevation={1}
      sx={{
        height: window.innerHeight - 100,
        overflow: "scroll",
        bgcolor: "transparent",
        p: "8px",
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box sx={{}}>
            <Typography variant="body1">Loading...</Typography>
          </Box>
        </Box>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <ToggleButtonGroup
                size="small"
                value={view}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton value="list" aria-label="list">
                  <ViewListIcon />
                </ToggleButton>
                <ToggleButton value="quilt" aria-label="quilt">
                  <ViewQuiltIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
          {
            view === "list" ? 
          <Grid item xs={12}>
            <EmployeeListView
              companyData={companyData}
              employeeData={employeeData}
            />
          </Grid>
         : <Grid item container xs={12} spacing={2}>
         {companyData?.visa_contacts_data?.map((data) => (
            <Grid item xs={4}>
              <ActionAreaCardVisaContact employee={data} />
            </Grid>
          ))}
          {employeeData.map((data) => (
            <Grid item xs={4}>
              <ActionAreaCard employee={data} />
            </Grid>
          ))}
         </Grid>
}
        </Grid>
      )}
    </Paper>
  );
};

export default Employees;

function ActionAreaCard1({ employee }) {
  return (
    <Card
      sx={{
        maxWidth: 345,
        // boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
        boxShadow: "rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Box sx={{ position: "absolute", left: 5, top: 5 }}>
          <StarsRounded sx={{ color: "#FAAF00", fontSize: "30px" }} />
        </Box>
      </Box>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ position: "relative" }}>
            <Avatar
              src={
                employee?.EMP_PHOTO_URL ? employee?.EMP_PHOTO_URL : ""
                // "https://img.freepik.com/premium-photo/sleek-icon-portraying-chinese-male-with-testimonial-theme_1283595-14525.jpg?w=740"
              }
              sx={{ height: 100, width: 100 }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 4,
                left: 4,
                border: "2px solid white",
                height: 93,
                width: 93,
                borderRadius: "50%",
                zIndex: 1100,
              }}
            />
          </Box>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: "#05264C",
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "capitalize",
            textAlign: "center",
            mb: 1,
          }}
        >
          {employee?.contact_name?.toLowerCase()}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#A0ABB7",
            fontSize: "12px",
            textAlign: "center",
            mb: 1,
          }}
        >
          {employee.contact_email !== "email_not_unlocked@domain.com"
            ? employee?.contact_email
            : "N/A"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              textTransform: "capitalize",
              // color: '#506781',
              bgcolor: "#BBDEFB",
              paddingInline: "15px",
              borderRadius: "10px",
              color: getContrastingTextColor("#BBDEFB"),
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {employee?.title?.toLowerCase()}
          </Typography>
        </Box>
      </CardContent>

      {/* <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={employee?.EMP_PHOTO_URL}
          alt={employee?.EMP_CONTACT_NAME}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {employee?.EMP_CONTACT_NAME}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea> */}
    </Card>
  );
}
function ActionAreaCardVisaContact({ employee }) {
  function formatAddress(data) {
    const {
      contact_address,
      contact_city,
      contact_state,
      contact_zipcode,
      contact_country,
    } = data;

    const isValid = (value) => value && value !== "N/A";

    const addressParts = [
      isValid(contact_address) ? contact_address : null,
      isValid(contact_city) ? contact_city : null,
      isValid(contact_state) ? contact_state : null,
      isValid(contact_zipcode) ? contact_zipcode : null,
      isValid(contact_country) ? contact_country : null,
    ];

    const formattedAddress = addressParts.filter((part) => part).join(", ");

    return formattedAddress || "Address not available";
  }
  return (
    <Card
      sx={{
        maxWidth: 345,
        // boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
        // boxShadow: "rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
        // boxShadow: "none",
        border: "1px solid #EFF2FB",
        borderBottom: "3px solid #4DAA57",
        borderRadius: "4px",
        // padding: "21px 25px",
        // transition: "transform 0.2s ease-in-out",
        "&:hover": {
          // transform: "scale(1.05)",
          // boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 16px 0px",
          border: "1px solid #B4C0E0",
          boxShadow: "0px 10px 20px -5px rgba(6, 36, 92, 0.06)",
          transform: "translateY(-3px)",
          transition: "all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1)",
        },
      }}
    >
      {/* <Box sx={{ position: "relative" }}>
          <Box
            component={Link}
            sx={{ position: "absolute", right: 8, top: 5 }}
            href={employee?.EMP_LINKEDIN_URL}
            target="_blank"
          >
            <FaLinkedin style={{ color: "#2867B2", fontSize: "30px" }} />
          </Box>
        </Box> */}
      <CardContent>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <Box sx={{ position: "relative", height: 70, width: 70 }}>
              <Avatar
                src={
                  ""
                  // "https://img.freepik.com/premium-photo/sleek-icon-portraying-chinese-male-with-testimonial-theme_1283595-14525.jpg?w=740"
                }
                sx={{ height: 70, width: 70 }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 1.5,
                  left: 1.5,
                  border: "2px solid white",
                  height: 67,
                  width: 67,
                  borderRadius: "50%",
                  zIndex: 1100,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "2px",
                  right: 1,
                  zIndex: 1101,
                }}
              >
                <VerifiedRounded sx={{ color: "#4DAA57", fontSize: "16px" }} />
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                color: "#05264C",
                fontSize: "16px",
                fontWeight: 600,
                textTransform: "capitalize",
                // textAlign: "center",
                m: 0,
              }}
            >
              {employee?.contact_name?.toLowerCase()}
            </Typography>
            {/* <Typography
              variant="body2"
              sx={{
                color: "#A0ABB7",
                fontSize: "14px",
                fontWeight: 500,
                m: 0,
              }}
            >
              {employee?.title?.toLowerCase()}
            </Typography> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  textTransform: "capitalize",
                  // color: '#506781',
                  // bgcolor: "#BBDEFB",
                  bgcolor: "#F1FAFF",
                  paddingInline: "15px",
                  borderRadius: "10px",
                  // color: getContrastingTextColor("#BBDEFB"),
                  // color: getContrastingTextColor("#F1FAFF"),
                  color: "#06A0F7",
                  border: "1px solid #bee8ff",
                  fontSize: "14px",
                  fontWeight: 500,
                  mt: "4px",
                }}
              >
                {employee?.title?.toLowerCase()}
              </Typography>
            </Box>
            {/* <Box sx={{display: 'flex', gap: '2px', alignItems: 'start'}}>
            <LocationOnOutlined sx={{fontSize: '20px', 
              color: "#A0ABB7",}} />
          <Typography
            variant="body2"
            sx={{
              color: "#A0ABB7",
              fontSize: "12px",
              mb: 1,
            }}
          >
            {employee && formatContactAddress(employee) || "N/A"}
          </Typography>
          </Box> */}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              bgcolor: "#f3f5fc",
              p: 1,
              border: "1px solid #eff2fb",
              mt: 1,
              borderRadius: "8px",
              width: "85%",
            }}
          >
            <Box sx={boxStyles}>
              <TagRounded sx={iconStyles} />
              <Typography variant="body2" sx={contactP_Styles}>
                #{employee?.id || "--"}
              </Typography>
            </Box>
            <Box sx={boxStyles}>
              <EmailOutlined sx={iconStyles} />
              <Typography variant="body2" sx={contactP_Styles}>
                {employee?.contact_email !== "email_not_unlocked@domain.com"
                  ? employee?.contact_email
                  : "N/A"}
              </Typography>
            </Box>
            <Box sx={boxStyles}>
              <LocalPhoneOutlined sx={iconStyles} />
              <Typography variant="body2" sx={contactP_Styles}>
                {employee?.contact_phone || "N/A"}
              </Typography>
            </Box>
            <Box sx={boxStyles}>
              <LocationOnOutlined sx={iconStyles} />
              <Typography variant="body2" sx={contactP_Styles}>
                {(employee && formatAddress(employee)) || "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                textTransform: "capitalize",
                // color: '#506781',
                bgcolor: "#BBDEFB",
                paddingInline: "15px",
                borderRadius: "10px",
                color: getContrastingTextColor("#BBDEFB"),
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {employee?.EMP_DECL_TITLE?.toLowerCase()}
            </Typography>
          </Box> */}
      </CardContent>

      {/* <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={employee?.EMP_PHOTO_URL}
            alt={employee?.EMP_CONTACT_NAME}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {employee?.EMP_CONTACT_NAME}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Lizards are a widespread group of squamate reptiles, with over 6,000
              species, ranging across all continents except Antarctica
            </Typography>
          </CardContent>
        </CardActionArea> */}
    </Card>
  );
}

function ActionAreaCard({ employee }) {
  function formatContactAddress(contact) {
    const {
      EMP_CONTACT_ADDRESS_1,
      EMP_CONTACT_ADDRESS_2,
      EMP_CONTACT_CITY,
      EMP_CONTACT_STATE_PROVINCE,
      EMP_CONTACT_COUNTRY,
    } = contact;

    const addressParts = [
      EMP_CONTACT_ADDRESS_1,
      EMP_CONTACT_ADDRESS_2,
      EMP_CONTACT_CITY,
      EMP_CONTACT_STATE_PROVINCE,
      EMP_CONTACT_COUNTRY,
    ];

    const filteredAddressParts = addressParts.filter(
      (part) => part !== null && part !== ""
    );

    return filteredAddressParts.join(", ");
  }
  return (
    <Card
      sx={{
        maxWidth: 345,
        // boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
        // boxShadow: "rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
        boxShadow: "none",
        border: "1px solid #EFF2FB",
        borderRadius: "8px",
        // padding: "21px 25px",
        // transition: "transform 0.2s ease-in-out",
        "&:hover": {
          // transform: "scale(1.05)",
          // boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 16px 0px",
          border: "1px solid #B4C0E0",
          boxShadow: "0px 10px 20px -5px rgba(6, 36, 92, 0.06)",
          transform: "translateY(-3px)",
          transition: "all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1)",
        },
      }}
    >
      {/* <Box sx={{ position: "relative" }}>
        <Box
          component={Link}
          sx={{ position: "absolute", right: 8, top: 5 }}
          href={employee?.EMP_LINKEDIN_URL}
          target="_blank"
        >
          <FaLinkedin style={{ color: "#2867B2", fontSize: "30px" }} />
        </Box>
      </Box> */}
      <CardContent>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <Box sx={{ position: "relative", height: 70, width: 70 }}>
              <Avatar
                src={
                  employee?.EMP_PHOTO_URL ? employee?.EMP_PHOTO_URL : ""
                  // "https://img.freepik.com/premium-photo/sleek-icon-portraying-chinese-male-with-testimonial-theme_1283595-14525.jpg?w=740"
                }
                sx={{ height: 70, width: 70 }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 1.5,
                  left: 1.5,
                  border: "2px solid white",
                  height: 67,
                  width: 67,
                  borderRadius: "50%",
                  zIndex: 1100,
                }}
              />
              {/* <Box
                sx={{
                  position: "absolute",
                  bottom: "2px",
                  right: 1,
                  zIndex: 1101,
                }}
              >
                <VerifiedRounded sx={{ color: "#198754", fontSize: "16px" }} />
              </Box> */}
            </Box>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                color: "#05264C",
                fontSize: "16px",
                fontWeight: 600,
                textTransform: "capitalize",
                // textAlign: "center",
                m: 0,
              }}
            >
              {employee?.EMP_CONTACT_NAME?.toLowerCase()}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#A0ABB7",
                fontSize: "14px",
                fontWeight: 500,
                m: 0,
              }}
            >
              {/* {employee?.EMP_CONTACT_EMAIL} */}
              {employee?.EMP_DECL_TITLE?.toLowerCase()}
            </Typography>
            {/* <Box sx={{display: 'flex', gap: '2px', alignItems: 'start'}}>
          <LocationOnOutlined sx={{fontSize: '20px', 
            color: "#A0ABB7",}} />
        <Typography
          variant="body2"
          sx={{
            color: "#A0ABB7",
            fontSize: "12px",
            mb: 1,
          }}
        >
          {employee && formatContactAddress(employee) || "N/A"}
        </Typography>
        </Box> */}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              bgcolor: "#f3f5fc",
              p: 1,
              border: "1px solid #eff2fb",
              mt: 1,
              borderRadius: "8px",
              width: "85%",
            }}
          >
            <Box sx={boxStyles}>
              <TagRounded sx={iconStyles} />
              <Typography variant="body2" sx={contactP_Styles}>
                #{employee?.ID || "--"}
              </Typography>
            </Box>
            <Box sx={boxStyles}>
              <EmailOutlined sx={iconStyles} />
              <Typography variant="body2" sx={contactP_Styles}>
                {employee.EMP_CONTACT_EMAIL !== "email_not_unlocked@domain.com"
                  ? employee?.EMP_CONTACT_EMAIL
                  : "N/A"}
              </Typography>
            </Box>
            <Box sx={boxStyles}>
              <LocalPhoneOutlined sx={iconStyles} />
              <Typography variant="body2" sx={contactP_Styles}>
                {employee?.EMP_CONTACT_PHONE || "N/A"}
              </Typography>
            </Box>
            <Box sx={boxStyles}>
              <LocationOnOutlined sx={iconStyles} />
              <Typography variant="body2" sx={contactP_Styles}>
                {(employee && formatContactAddress(employee)) || "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              textTransform: "capitalize",
              // color: '#506781',
              bgcolor: "#BBDEFB",
              paddingInline: "15px",
              borderRadius: "10px",
              color: getContrastingTextColor("#BBDEFB"),
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {employee?.EMP_DECL_TITLE?.toLowerCase()}
          </Typography>
        </Box> */}
        <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
          <Box
            component={Link}
            sx={{
              border: "1px solid #ddd",
              p: "5px 10px",
              borderRadius: "8px",
              "&:hover": {
                boxShadow: "0px 10px 20px -5px rgba(6, 36, 92, 0.06)",
                transform: "translateY(-5px)",
                transition: "all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1)",
              },
            }}
            href={employee?.EMP_LINKEDIN_URL}
            target="_blank"
          >
            {/* <FaLinkedin style={{ color: "#2867B2", fontSize: "30px" }} /> */}
            <img
              src="https://cdn.uconnectlabs.com/wp-content/uploads/sites/46/2022/08/Linkedin-Logo-e1660320077673-300x93.png"
              width={60}
            />
          </Box>
        </Box>
      </CardContent>

      {/* <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={employee?.EMP_PHOTO_URL}
          alt={employee?.EMP_CONTACT_NAME}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {employee?.EMP_CONTACT_NAME}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea> */}
    </Card>
  );
}

const boxStyles = {
  display: "flex",
  gap: "4px",
  alignItems: "start",
};

const iconStyles = {
  fontSize: "14px",
  color: "#6c757d",
};

const contactP_Styles = {
  fontSize: "12px",
  color: "#6c757d",
};
