import React from 'react'
import {  useSelector } from 'react-redux';
import { useResponsiveStyles } from '../../../../../Styles/responsiveStyles';
import { Card, Grid, Typography } from '@mui/material';
import parse from "html-react-parser";

function ResumeSummary() {
  const styles = useResponsiveStyles();
  const searchState = useSelector((state) => state.searchResumeReducer);
  const {
    searchParams = {},
    filterParams = {},
    paginationParams = {},
    results = [],
    numFound,
    selectedResume = null,
    error,
  } = searchState || {};
  
  
  const resumeSummary = selectedResume?.summary
 
  return (
     <>
         <Grid item xs={12}>
                      <Card sx={{ boxShadow: "none", padding: "10px" }}>
                        <Typography
                          paragraph
                          sx={{ fontWeight: 500, fontSize: styles.fontSize16 }}
                        >
                        
                        </Typography>
                        <Typography
                          paragraph
                          style={{ fontSize: "16px !important" }}
                        >
                          {resumeSummary !== null &&
                          resumeSummary !== undefined &&
                          resumeSummary
                            ? !resumeSummary.includes("ahref") &&
                              !resumeSummary.includes("divclass") &&
                              !resumeSummary.includes("aclass") &&
                              !resumeSummary.includes("pclass") &&
                              !resumeSummary.includes("liclass")
                              ? parse(resumeSummary)
                              : resumeSummary.replace(/<[^>]+>/g, "")
                            : ""}
                        </Typography>
                      </Card>
                    </Grid>

     </>
  )
}

export default ResumeSummary