import React, { useState,useEffect }  from 'react'
import {  useSelector } from 'react-redux';
import { useResponsiveStyles } from '../../../../../Styles/responsiveStyles';
import { Box, Card , CardContent, CardHeader, Typography ,Stack, Avatar, Breadcrumbs, CircularProgress} from '@mui/material';
import { styled } from "@mui/material/styles";
import moment from "moment";
import axios from "axios";
import {

  LocationCityOutlined,
 
} from "@mui/icons-material";
import Key from "../../../../../../src/clientVariables.json";
import { useDispatch } from 'react-redux';
import { jsResumeDetails } from '../../../../../Components/Actions/resumeSearchActions';
function ResumeWorks() {
  const styles = useResponsiveStyles();
  const searchState = useSelector((state) => state.searchResumeReducer);
  const currentUser = useSelector((state) => state.UserReducer.user);
  const [projects,setProjects] = useState([]);
  const dispatch = useDispatch();
  const {
    selectedResume = null ,
    selectedResumeDetails,
  } = searchState || {};    
  console.log("user",currentUser);
  console.log("Praveen Test",selectedResumeDetails);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData =  () => {
    
        const response =  dispatch(
          jsResumeDetails(selectedResume.id)
        );
        setTimeout(() => {
          setLoading(false); // Stop loading
        }, 2300);
    };

    fetchData();
  }, [selectedResume]); 

  const projectDetails  = selectedResumeDetails?.projectdetails;

  
  const certificateDetails = selectedResumeDetails?.certificatedetails


  const Bullet = styled("span")({
    display: "inline-block",
    width: "3px",
    height: "3px",
    borderRadius: "50%",
    backgroundColor: "currentColor",
  });
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
 
  return (
     <>
           <Card sx={{ border: "1px solid #ddd" }}>
                  <CardHeader
                    title={
                      <Typography
                        paragraph
                        style={{ fontSize: styles.fontSize18, fontWeight: 500, margin: 0 }}
                      >
                        Projects
                      </Typography>
                    }
                    sx={{ borderBottom: "1px solid #ddd" }}
                  />
                  <CardContent>
                    {projectDetails?.length > 0 ?
                      projectDetails.map((data, index) => (
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            bgcolor: "background.paper",
                            color: "text.secondary",
                            "& svg": {
                              m: 1.5,
                            },
                            border: "1px solid #ddd",
                            borderRadius: "6px",
                            p: 1,
                            mb: 1,
                          }}
                        >
                          <Avatar sx={{ height: styles.Avatar40, width: styles.Avatar40, }}>
                            <LocationCityOutlined
                              sx={{ height: styles.avatarIcon24, width: styles.avatarIcon24, }}
                            />
                          </Avatar>
                          <Box>
                            <Typography
                              variant="body2"
                              component="p"
                              // variant="subtitle1"
                              fontWeight={500}
                              sx={{
                                margin: 0,
                                fontSize: styles.fontSize16,
                                color: "rgba(0,0,0,0.8)",
                                lineHeight: 1.5,
                              }}
                            >
                              {data?.pro_role}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Breadcrumbs
                                separator="-"
                                aria-label="breadcrumb"
                              >
                                <Typography
                                  variant="body2"
                                  component="p"
                                  sx={{
                                    fontSize: styles.fontSize16,
                                    lineHeight: 1.42,
                                    color: "rgba(0,0,0,0.6)",
                                  }}
                                >
                                  {data?.pro_client}
                                </Typography>
                                {/* <Typography
                               variant="body2"
                               component="p"
                               sx={{
                                 fontSize: "1rem",
                                 lineHeight: 1.42,
                                 color: "rgba(0,0,0,0.6)",
                               }}
                             >
                               Full Time
                             </Typography> */}
                              </Breadcrumbs>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Breadcrumbs
                                separator={<Bullet />}
                                aria-label="breadcrumb"
                              >
                                <Typography
                                  variant="body2"
                                  component="p"
                                  sx={{
                                    fontSize: styles.fontSize14,
                                    color: "rgba(0,0,0,0.5)",
                                  }}
                                >
                                  {moment(data?.pro_startdate).format("ll")} -{" "}
                                  {moment(data?.pro_enddate).format("ll") ==
                                  "Invalid date"
                                    ? "Till Date"
                                    : moment(data?.pro_enddate).format("ll")}
                                </Typography>
                                {/* <Typography
                               variant="body2"
                               component="p"
                               sx={{ fontSize: "14px", color: "rgba(0,0,0,0.5)" }}
                             >
                               2 yrs 5 mos
                               {moment.duration(moment(data?.pro_enddate).diff(moment(data?.pro_startdate)))}
                             </Typography> */}
                              </Breadcrumbs>
                            </Box>
                            <Typography
                              variant="body2"
                              component="p"
                              sx={{
                                fontSize: styles.fontSize14,
                                color: "rgba(0,0,0,0.5)",
                              }}
                            >
                              {data?.cityname !== null &&
                              data?.statename !== null
                                ? `${data?.cityname}, ${data?.statename}, ${data?.shortRegion}`
                                : "Remote"}
                            </Typography>
                          </Box>
                        </Stack>
                      ))  : (
                        <Card>No Projects To Show</Card>
                      )
                    }

                  </CardContent>
                </Card>
                <Card sx={{ border: "1px solid #ddd", mt: 2 }}>
                  <CardHeader
                    title={
                      <Typography
                        paragraph
                        style={{ fontSize: styles.fontSize18, fontWeight: 500, margin: 0 }}
                      >
                        Certifications
                      </Typography>
                    }
                    sx={{ borderBottom: "1px solid #ddd" }}
                  />
                  <CardContent>
                  {certificateDetails && certificateDetails.length > 0 ? (
                            <Stack direction="column">
                              {certificateDetails.some(cer => cer.cer_name === "undefined") ? (
                                <Typography>No Certifications to show</Typography>
                              ) : (
                                certificateDetails.map((cer, index) => (
                                  <Typography key={index} paragraph sx={{ m: 0 }}>
                                    {`${index + 1}. ${cer.cer_name || ""}`}
                                  </Typography>
                                ))
                              )}
                            </Stack>
                          ) : (
                            <Card>No Certifications to show</Card>
                          )}
                  </CardContent>
                </Card>

     </>
  )
}

export default ResumeWorks