import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useResponsiveStyles } from "../../../Styles/responsiveStyles";
import H1B_Approvals from "./H1B_Approvals";
import LCA_Data from "./LCA_Data";
import H1B_Salaries from "./H1B_Salaries";
import Grade from "./Grade";
import JobTitles from "./JobTitles";
import Button from "@mui/material/Button";
import GC_Approvals_Denails from "./GC_Approvals_Denails";
import GC from "./GC";
import GC_Data from "./GC_Data";
import GC_JobTiltes from "./GC_JobTiltes";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 3, height: window.innerHeight - 120, overflow: "scroll" }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function Main({ companyData }) {
  const styles = useResponsiveStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [h1b, setH1B] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const h1bHandler = () => {
    setH1B((prev) => !prev);
    setValue(0);
  };

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          p: "5px",
          bgcolor: "#f0f4f4",
          borderBottom: "1px solid #c4d4d4",
        }}
      >
        <Button
          variant={h1b ? "contained" : "outlined"}
          sx={{ p: 0, paddingInline: "20px", borderRadius: "10px" }}
          disableElevation
          onClick={h1bHandler}
        >
          H1B
        </Button>
        <Button
          variant={h1b ? "outlined" : "contained"}
          sx={{ p: 0, paddingInline: "20px", borderRadius: "10px" }}
          disableElevation
          onClick={h1bHandler}
        >
          Green Card
        </Button>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <AppBar
          position="static"
          sx={{ bgcolor: "#f0f4f4", padding: 0, minWidth: 500 }}
          elevation={0}
        >
          {h1b ? (
            <Tabs
              sx={{
                minHeight: "auto",
                "& .MuiTab-root": {
                  fontSize: styles.fontSize13,
                  minHeight: "auto",
                  padding: "6px 12px",
                  minWidth: "60px",
                  textTransform: "capitalize",
                  fontWeight: 500,
                  // letterSpacing: 0.8
                  borderRight: "1px solid #c4d4d4",
                },
              }}
              value={value}
              onChange={handleChange}
              indicatorColor=""
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="H1B Approvals" {...a11yProps(0)} />
              <Tab label="H1B LCA's" {...a11yProps(1)} />
              {/* <Tab label="H1B Salaries" {...a11yProps(2)} /> */}
              {/* <Tab label="Grade" {...a11yProps(2)} /> */}
              <Tab label="Job Titles" {...a11yProps(2)} />
            </Tabs>
          ) : (
            <Tabs
              sx={{
                minHeight: "auto",
                "& .MuiTab-root": {
                  fontSize: styles.fontSize13,
                  minHeight: "auto",
                  padding: "6px 12px",
                  minWidth: "60px",
                  textTransform: "capitalize",
                  fontWeight: 500,
                  // letterSpacing: 0.8
                  borderRight: "1px solid #c4d4d4",
                },
              }}
              value={value}
              onChange={handleChange}
              indicatorColor=""
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="PERM LCA Approvals" {...a11yProps(0)} />
              <Tab label="PERM LCA's" {...a11yProps(1)} />
              <Tab label="Job Titles" {...a11yProps(2)} />
            </Tabs>
          )}
        </AppBar>
      </Box>
      <TabPanel value={value} index={0} dir={theme.direction}>
        {h1b ? (
          <H1B_Approvals companyData={companyData} />
        ) : (
          <GC companyData={companyData} />
        )}
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        {h1b ? (
          <LCA_Data companyData={companyData} />
        ) : (
          <GC_Data companyData={companyData} />
        )}
      </TabPanel>
      {/* <TabPanel value={value} index={2} dir={theme.direction}>
        {h1b ? (
          <H1B_Salaries companyData={companyData} />
        ) : (
          <GC_JobTiltes companyData={companyData} />
        )}
      </TabPanel> */}
      <TabPanel value={value} index={2} dir={theme.direction}>
        {/* <Grade companyData={companyData} /> */}
        {h1b ? (
          // <Grade companyData={companyData} />
          <JobTitles companyData={companyData} />
        ) : (
          <GC_JobTiltes companyData={companyData} />
        )}
      </TabPanel>
      {/* <TabPanel value={value} index={3} dir={theme.direction}>
        <JobTitles companyData={companyData} />
      </TabPanel> */}
    </Box>
  );
}
