import {
  Box,
  tableCellClasses,
  Tabs,
  Tab,
  Typography,
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Grid,
  Divider,
  Stack,
  useMediaQuery,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";

import PropTypes from "prop-types";
import parse from "html-react-parser";

import { useTheme } from "@mui/material";
import { useResponsiveStyles } from "../../../Styles/responsiveStyles";
import { getComapanyLocation } from "../../../utils/getLocation";
import {
  PlaceRounded,
  PersonRounded,
  LocalPhoneRounded,
  PublicRounded,
  MapRounded,
} from "@mui/icons-material";
import AddressMap from "./AddressMap";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Overview = ({ companyData }) => {
  const styles = useResponsiveStyles();
  const GC_Certified_Count = companyData?.gc_data?.reduce(
    (total, item) => item.certified + total,
    0
  );
  const LCA_Certified_Count = companyData?.lca_data?.reduce(
    (total, item) => item.Certified + total,
    0
  );
  const H1B_Certified_Count = companyData?.h1_data?.reduce(
    (total, item) => item.initial_approval + item.continuing_approval + total,
    0
  );
  const city =
    companyData?.details_data?.city_name ||
    companyData?.details_data?.city ||
    "";
  const state =
    companyData?.details_data.state_name ||
    companyData?.details_data?.state ||
    "";

  const fullAddress = [
    companyData?.details_data.address1,
    companyData?.details_data.address2,
    city,
    state,
    companyData?.details_data?.postal_code,
  ]
    .filter(Boolean)
    .join(", ");

  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    fullAddress
  )}`;

  //   const mapImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(fullAddress)}&zoom=14&size=600x300&maptype=roadmap&markers=color:red%7Clabel:A%7C${encodeURIComponent(fullAddress)}&key=${GOOGLE_MAPS_API_KEY}`;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops

  const redirectToGoogleMaps = (companyName) => {
    const baseUrl = "https://www.google.com/maps/search/";
    const query = encodeURIComponent(companyName);
    window.location.href = `${baseUrl}${query}`;
  };
  return (
    <Box>
      <Card
        sx={{
          boxShadow: "none",
          p: 1,
          borderRadius: "8px",
          height: "600px",
          overflow: "scroll",
        }}
      >
        <Card sx={{ boxShadow: "none", p: 0, textAlign: "justify" }}>
          {/* <CardHeader
                        title={
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 600,
                              color: "rgba(0,0,0,0.7)",
                            }}
                          >
                            Overview
                          </Typography>
                        }
                      /> */}
          <CardContent sx={{ pt: 0 }}>
            <Typography
              paragraph
              sx={{
                color: "black",
                fontSize: isMobile ? "14px" : "14px",
              }}
            >
              {companyData?.description !== null &&
              companyData?.description !== undefined &&
              companyData?.description
                ? !companyData?.description.includes("ahref") &&
                  !companyData?.description.includes("divclass") &&
                  !companyData?.description.includes("aclass") &&
                  !companyData?.description.includes("pclass") &&
                  !companyData?.description.includes("liclass")
                  ? parse(companyData?.description)
                  : companyData?.description.replace(/<[^>]+>/g, "")
                : "No Description "}
            </Typography>
          </CardContent>
        </Card>
        {/* <Grid container spacing={0}>
          <Grid item xs={4}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ border: "2px solid #ddd", height: 20, m: 2 }} />
              <Box sx={{}}>
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  PERM Labor Certification Approvals
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  {GC_Certified_Count}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ border: "2px solid #ddd", height: 20, m: 2 }} />
              <Box sx={{}}>
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  H1B USCIS Approvals
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  {H1B_Certified_Count}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ border: "2px solid #ddd", height: 20, m: 2 }} />
              <Box sx={{}}>
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  H1B LCA Approvals
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  {LCA_Certified_Count}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid> */}
        <Box sx={{ flexGrow: 1, border: "1px solid #ddd", m: 1, mb: 0 }} />

        <Box sx={{ display: "flex", alignItems: "center", pl: 2 }}>
          <Box sx={{ display: "flex", gap: 3 }}>
            <PlaceRounded sx={iconStyles} />
            {fullAddress ? (
              <Link href={googleMapsUrl} target="_blank" underline="hover">
                <Typography variant="body1">{fullAddress}</Typography>
              </Link>
            ) : (
              <Typography variant="body1">Address not available</Typography>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <AddressMap address={fullAddress} />
        </Box>

        <Box sx={{ flexGrow: 1, border: "1px solid #ddd", m: 1, mt: 0 }} />
        <Box sx={boxMainStyles}>
          <Box sx={boxSubStyles}>
            <LocalPhoneRounded sx={iconStyles} />
            <Typography variant="body1">
              {companyData?.details_data?.phone || "N/A"}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, border: "1px solid #ddd", m: 1, mt: 0 }} />
        <Box sx={boxMainStyles}>
          <Box
            sx={boxSubStyles}
            component={Link}
            href={companyData?.url || ""}
            target="_blank"
          >
            <PublicRounded sx={iconStyles} />
            <Typography variant="body1">{companyData?.url || "N/A"}</Typography>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, border: "1px solid #ddd", m: 1, mt: 0 }} />
        <Box sx={boxMainStyles}>
          <Box sx={boxSubStyles}>
            <MapRounded sx={iconStyles} />
            <Typography variant="body1">USA</Typography>
          </Box>
        </Box>

        {/* <Paper elevation={2} p={4}>
          <Card sx={{ boxShadow: "none", p: 0 }}>
            <CardHeader
              title={
                <Typography
                  paragraph
                  sx={{
                    margin: 0,
                    fontWeight: 600,
                    color: "rgba(0,0,0,0.7)",
                  }}
                >
                  Manage Addresses
                </Typography>
              }
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography
                paragraph
                sx={{
                  color: "black",
                  fontWeight: 600,
                  fontSize: isMobile ? "16px" : "20px", // Adjust the font size as needed
                  m: 0,
                }}
              >
                {companyData?.name}
              </Typography>
              <Typography
                paragraph
                sx={{
                  color: "black",
                  fontSize: isMobile ? "14px" : "16px",
                  m: 0,
                }}
              >
                {companyData && getComapanyLocation(companyData)}
              </Typography>
              <Typography
                paragraph
                sx={{ color: "black", fontSize: "16px", m: 0 }}
              >
                {companyData?.zipcode}
              </Typography>
            </CardContent>
          </Card>
        </Paper> */}
      </Card>
    </Box>
  );
};

export default Overview;

const boxMainStyles = { display: "flex", alignItems: "center", p: 2 };
const boxSubStyles = { display: "flex", gap: 3 };
const iconStyles = { color: "#ddd" };
const textStyles = {};
