import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Key from "../../clientVariables.json";
import {
  AssignmentTurnedInOutlined,
  AssignmentTurnedInRounded,
  BookmarkAddedOutlined,
  BookmarkAddedRounded,
  BusinessCenterOutlined,
  BusinessCenterRounded,
  BusinessRounded,
  CheckCircleOutline,
  CheckCircleRounded,
  CorporateFareRounded,
  DashboardOutlined,
  DashboardRounded,
  DeleteOutlineRounded,
  DescriptionOutlined,
  DescriptionRounded,
  HistoryRounded,
  InfoOutlined,
  Message,
  Notifications,
  PeopleOutlined,
  PeopleRounded,
  TopicOutlined,
  TopicRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Backdrop,
  Badge,
  Input,
  InputAdornment,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  Popover,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import { styled, useTheme, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import userService from "../../services/user.service";
import Jssearchblock from "./Jssearchblock";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../Actions/loader.actions";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SearchComponent from "./SearchComponent";
import {
  updateResumeFilterParams,
  updateResumePaginationParams,
  updateResumeSearchParams,
} from "../Actions/resumeSearchActions";
import { jobsBuildFacetQuery } from "../../utils/jobsQueryBuilder";
import {
  updateJobFilterParams,
  updateJobPaginationParams,
  updateJobSearchParams,
} from "../Actions/jobSearchActions";
import { openAIExtracteSearchValues } from "./jobs_compopnents/extractedSearchValues";
import lightLog from "../../Images/logo/logo-light-bg.png";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";

const drawerWidth = 240;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.9),
  // '&:hover': {
  //   backgroundColor: alpha(theme.palette.common.white, 0.6),
  // },
  color: alpha(theme.palette.common.black, 0.9),
  marginLeft: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  border: '1px solid #E0E6F7',
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchHistory = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "100%",
  left: 0,
  right: 0,
  // boxShadow: 3,
  border: "none",
  zIndex: 10,
  maxHeight: "250px",
  overflowY: "auto",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#fff",
  // '&:hover': {
  //   backgroundColor: alpha(theme.palette.common.white, 0.6),
  // },
  color: alpha(theme.palette.common.black, 0.9),
  // marginLeft: 0,
  // width: '100%',
  // display: 'flex',
  // alignItems: 'center',
  // [theme.breakpoints.up('sm')]: {
  //   marginLeft: theme.spacing(1),
  //   width: 'auto',
  // },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color:'#A0ABB8',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  // color: "inherit",
  color: "#A0ABB8",
  flexGrow: 1,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "50ch",
      // '&:focus': {
      //   width: '70ch',
      // },
    },
    "&::placeholder": {
      // color: "#888",
      color: "#A0ABB8",
      opacity: 1,
    },
  },
}));

const IconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  color: "#888",
  "&:hover": {
    color: "#406882",
    cursor: "pointer",
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // width: drawerWidth,

  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const ITEM_HEIGHT = 48;

const JSNavBar = ({ children }) => {
  const theme = useTheme();
  const searchState = useSelector((state) => state.searchResumeReducer);
  const {
    searchParams = {},
    filterParams = {},
    paginationParams = {},
    results = [],
    numFound,
    facet_counts,
    selectedResume = null,
    selectedResumeDetails = null,
    error,
  } = searchState || {};
  const primaryResumeDetails = useSelector(
    (state) => state.primaryResumeReducer.resume
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const openNotification = Boolean(anchorElNotification);
  const [formOpen, setFormOpen] = useState(false);
  const currentUser = useSelector((state) => state.UserReducer.user);
  // const [numFound, setNumFound] = useState(0);
  const [recentJobs, setRecentJobs] = useState([]);
  const dispatch = useDispatch();
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [query, setQuery] = useState("");
  const [anchorElInfo, setAnchorElInfo] = useState(null);
  const [searchHistory, setSearchHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const searchRef = useRef(null);

  const handleFocus = () => {
    setShowHistory(true);
    getSearchHistory();
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowHistory(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (query) {
      updateSearchHistory();
    }
  }, [query]);

  const computeMatchScore = (item) => {
    const keyword = item.keyword.toLowerCase();
    const lowerQuery = query.toLowerCase();

    if (keyword.includes(lowerQuery)) {
      return 1;
    }

    return 0;
  };

  const updateSearchHistory = () => {
    setSearchHistory((prevHistory) => {
      return prevHistory
        .map((item) => ({
          ...item,
          score: computeMatchScore(item),
        }))
        .sort((a, b) => b.score - a.score)
        .map(({ score, ...item }) => item);
    });
  };

  const handleInfoClick = (event) => {
    setAnchorElInfo(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorElInfo(null);
  };

  const openInfo = Boolean(anchorElInfo);
  const idInfo = true ? "info-popover" : undefined;

  const handleSearchChange = (event) => {
    const searchQuery = event.target.value;
    setQuery(searchQuery);
    // console.log('searchQuery 0099', searchQuery);
  };

  const handleOpenSearchModal = () => {
    setOpenSearchModal(true);
  };

  const handleCloseSearchModal = () => {
    setOpenSearchModal(false);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops

  const appliedRedirection = () => {
    if (dashboardDetails.appliedJobsCount > 0) {
      getDashboardDetails();
      history.push("/jobs/applied");
    } else {
      showAlert("Applied");
    }
  };

  const savedRedirection = () => {
    if (dashboardDetails.savedJobsCount > 0) {
      getDashboardDetails();
      history.push("/jobs/saved");
    } else {
      showAlert("Saved");
    }
  };

  const shortlistedRedirection = () => {
    if (dashboardDetails.shortlistedCount > 0) {
      getDashboardDetails();
      history.push("/jobs/shortlisted");
    } else {
      showAlert("Shortlisted");
    }
  };

  const showAlert = (action) => {
    setAlertMessage(`You haven't ${action.toLowerCase()} for any jobs yet.`);
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    history.push("/jobs");
  };

  const handleClose = (v) => {
    setFormOpen(v);
  };
  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null);
  };
  const accountHandler = () => {
    setAnchorElUser(null);
  };
  const logoutHandler = () => {
    setAnchorElUser(null);
    userService.logout();
  };
  const handleIconClick = () => {
    sessionStorage.removeItem("inputjobtitle");
    history.push("/Jsdashboard");
  };
  useEffect(() => {
    getDashboardDetails();
  }, []);
  const getDashboardDetails = async () => {
    try {
      const response = await axios.post(
        `${Key.domain}/dashboard/get_js_dashboard_details`,
        {
          session_token: currentUser.session_token,
          user_id: currentUser.id,
        }
      );
      if (response.status === 200) {
        //
        setDashboardDetails(response.data.data.userDetails.count[0]);
      } else {
        console.error(
          "response.status === 200 false : get_js_dashboard_details"
        );
      }
    } catch (error) {
      if (error.response && error.response.status == 400) {
        if (
          error.response.data.data === "Access denied.. Please Login" ||
          error.response.data.data === "Access denied"
        ) {
          // setAlert(true);
        }
      }

      console.error("Error fetching calculate_jsprofile_strength:", error);
    }
  };

  const insertSearchText = async (search) => {
    try {
      const response = await axios.post(
        `${Key.domain}/empdashboard/insert_emp_saved_searches`,
        {
          user_id: currentUser.id,
          session_token: currentUser.session_token,
          keyword: search || "",
          email: "",
          location: "",
          jobtype: "",
          query: "",
        }
      );
      if (response.status === 200) {
        // console.log("Search Inserted");
      }
    } catch (error) {
      throw error;
    }
  };

  const getSearchHistory = async () => {
    try {
      const response = await axios.post(
        `${Key.domain}/empdashboard/get_emp_saved_searches`,
        {
          user_id: currentUser.id,
          session_token: currentUser.session_token,
        }
      );
      if (response.status === 200) {
        // console.log("Search History", response.data.data);
        const searchHistory = response.data.data.map(
          (data) => data.search_title
        );
        // setSearchHistory([query, ...searchHistory]);
        setSearchHistory(response.data.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const deleteSearchHistory = async (data) => {
    // console.log('deleteSearchHistory', data);

    try {
      const response = await axios.post(
        `${Key.domain}/empdashboard/delete_emp_saved_searches`,
        {
          user_id: currentUser.id,
          session_token: currentUser.session_token,
          id: data.id,
        }
      );
      if (response.status === 200) {
        setSearchHistory((prevHistory) =>
          prevHistory.filter((item) => item.id !== data.id)
        );
      }
    } catch (error) {
      console.error("Error deleting search history:", error);
    }
  };

  const handleSearchSubmit = async (isNew, search_text) => {
    // console.log("handleSearchSubmit", isNew, search_text);

    setShowHistory(false);
    if (isNew === "new_text" && search_text) {
      await insertSearchText(search_text);
    }
    dispatch(showLoader());
    try {
      // Wrap the openAIExtracteSearchValues in a promise to use async/await
      const data = await new Promise((resolve, reject) => {
        openAIExtracteSearchValues(search_text, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });

      // console.log("Extracted Data:", data);
      const { jobType, state, title, visaType } = data;
      await dispatch(updateJobSearchParams(data));

      await dispatch(
        updateJobFilterParams({
          jobtype: "*:*",
          visatype: "*:*",
          state: "*:*",
          city: "*:*",
          total_experience: "*:*",
          lastmodified: null,
        })
      );
      await dispatch(
        updateJobPaginationParams({
          start: 0,
          rows: 10,
          currentPage: 1,
          init: 1,
        })
      );
      history.push("/jobs");
      dispatch(hideLoader());
    } catch (error) {
      console.error("Error:", error);
      // dispatch(hideLoader());
    }
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const highlightMatch = (text, query) => {
    const escapedQuery = query && escapeRegExp(query?.trim());

    if (
      (query && escapedQuery.toLowerCase().includes("c\\+\\+")) ||
      escapedQuery?.toLowerCase().includes("c \\+\\+")
    ) {
      return <span key={text}>{text}</span>;
    }

    const regex = new RegExp(`(${escapedQuery})`, "gi");

    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="highlighted" style={{ color: "#1E88E5" }}>
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  const viewAllRecentJobs = (index) => {
    dispatch(showLoader());
    setSelectedItem(index);
    const searchJobsUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?q=*%3A*&fq=status:(1)&rows=6&start=0&sort=created%20desc&wt=json`;
    fetch(`${Key.domain}/solr/solr_jobs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: searchJobsUrl }),
    }).then((response) => {
      response.json().then((resdata) => {
        // setNumFound(resdata.data.response.numFound);

        setRecentJobs(resdata.data.response.docs);
        dispatch(hideLoader());
      });
    });
    sessionStorage.removeItem("filtered-jobtypes");
    sessionStorage.removeItem("filtered-visatypes");
    sessionStorage.removeItem("jobMappedDates");
    localStorage.removeItem("workModeFlages");
    sessionStorage.removeItem("filtered-workmode");
    localStorage.removeItem("jobtypeFlages");
    localStorage.removeItem("visatypeFlages");
    localStorage.removeItem("traveltypeFlages");
    history.push({
      pathname: "/JsMostRecentJobsList",
      state: {
        mostRecentJobs: recentJobs,
        numFound: numFound,
      },
    });
    dispatch(hideLoader());
  };
  const filledIconStyles = {
    fontSize: "20px",
  };
  const outlinedIconStyles = {
    fontSize: "20px",
    color: "#9e9e9e",
  };

  const navItems = [
    // {title:'Home', to:'/', icon1:<HomeRounded />, icon2:<HomeOutlined />},
    {
      title: "Dashboard",
      to: "/Jsdashboard",
      onClick: handleIconClick,
      icon1: (
        <DashboardRounded
          onClick={handleIconClick}
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/Jsdashboard" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <DashboardOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Jobs",
      to: "/jobs",
      onClick: () => history.push({
        pathname: "/jobs",
        state: { title: "related" }
      }),
      icon1: (
        <BusinessCenterRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/jobs" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <BusinessCenterOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Resumes",
      to: "/my-resumes",
      icon1: (
        <DescriptionRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/my-resumes" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <DescriptionOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Companies",
      to: "/companies",
      icon1: (
        <BusinessRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/companies" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <CorporateFareRounded sx={outlinedIconStyles} />,
    },
    {
      title: "Files",
      to: "/Filemanager",
      icon1: (
        <TopicRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/Filemanager" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <TopicOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Network",
      to: "/network",
      icon1: (
        <PeopleRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/network" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <PeopleOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Applied",
      onClick: appliedRedirection,
      to: "/jobs/applied",
      icon1: (
        <AssignmentTurnedInRounded
          sx={{
            ...filledIconStyles,
            color:
              location.pathname === "/jobs/applied" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <AssignmentTurnedInOutlined sx={outlinedIconStyles} />,
    },
    {
      title: "Saved",
      to: "/jobs/saved",
      onClick: savedRedirection,
      icon1: (
        <CheckCircleRounded
          sx={{
            ...filledIconStyles,
            color: location.pathname === "/jobs/saved" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <CheckCircleOutline sx={outlinedIconStyles} />,
    },
    {
      title: "Shortlisted",
      to: "/jobs/shortlisted",
      onClick: shortlistedRedirection,
      icon1: (
        <BookmarkAddedRounded
          sx={{
            ...filledIconStyles,
            color:
              location.pathname === "/jobs/shortlisted" ? "#2196f3" : "#9e9e9e",
          }}
        />
      ),
      icon2: <BookmarkAddedOutlined sx={outlinedIconStyles} />,
    },
  ];
  const viewAllNotificationsHandler = () => {
    handleCloseNotificationMenu();
    history.push("/Notificationslist");
  };
  const updateNotificationReadStatus = (notificationid, job_id) => {
    // props.showLoader();

    axios
      .post(`${Key.domain}/Notifications/update_notification_readstatus`, {
        user_id: sessionStorage.getItem("id"),
        notificationId: notificationid,
      })
      .then((response) => {
        getNotifications();
        // history.push({
        //   pathname: "/job/view/Job_" + job_id,
        //   state:{
        //     notification:"navabar"}
        // });
      });

    setTimeout(() => {
      history.push("/job/view/Job_" + job_id);
    }, 1000);
    handleCloseNotificationMenu();
    //
  };
  const getNotifications = () => {
    axios
      .post(`${Key.domain}/Notifications/get_notifications`, {
        user_id: sessionStorage.getItem("id"),
      })
      .then((response) => {
        setNotifications(response.data.data.notifications);
        setNotificationsCount(response.data.data.notificationsCount);
        // props.hideLoader();
      });
  };
  const dashboardClicked = () => {
    sessionStorage.removeItem("jobtitle");
    sessionStorage.removeItem("cityname");
    sessionStorage.removeItem("skillNDescriptionSearch");
    sessionStorage.removeItem("filtered-jobtypes");
    sessionStorage.removeItem("filtered-visatypes");
    sessionStorage.removeItem("jobMappedDates");
    localStorage.removeItem("workModeFlages");
    sessionStorage.removeItem("filtered-workmode");
    localStorage.removeItem("jobtypeFlages");
    localStorage.removeItem("visatypeFlages");
    localStorage.removeItem("traveltypeFlages");
    // sessionStorage.removeItem("currentsearchpage")
    sessionStorage.removeItem("appliedJobsCount");
    sessionStorage.removeItem("appliedjobsPerPage");
    sessionStorage.removeItem("inputResumeTitle");
  };
  const mynetworkClicked = () => {
    sessionStorage.removeItem("myNetworkTabIndex");
  };
  // const navItemHandler = (title) => {
  //   setSelectIcon(title);
  //   if(title === "Dashboard") {
  //     dashboardClicked()
  //   }
  //   else if(title === "Network") {
  //     mynetworkClicked()
  //   }
  // }
  useEffect(() => {
    // dashboardClicked()
    // window.addEventListener("scroll", handleScroll);
    getNotifications();
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const selectedIndex = navItems.findIndex((item) => item.to === currentPath);
    setSelectedItem(selectedIndex !== -1 ? selectedIndex : null);
    if (location.pathname === "/Jsdashboard") {
      dashboardClicked();
    } else if (location.pathname === "/My_network") {
      mynetworkClicked();
    }
  }, [location.pathname, navItems]);

  const sidebarMobile = (
    <MuiDrawer
      variant={isMobile ? "temporary" : "permanent"}
      open={isMobile ? openDrawer : true}
      onClose={isMobile ? handleDrawerToggle : undefined}
      sx={{
        width: drawerWidth,
        zIndex: 1201,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
        },
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link to="/">
          {/* <img
            src="/static/media/jobsnProfiles.54d22dc53e022b59b8b7.png"
            width={160}
          /> */}
          <img src={lightLog} width={150} />
        </Link>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <Link to={item.to}>
              <ListItemButton onClick={handleDrawerToggle}>
                <ListItemIcon>{item.icon1}</ListItemIcon>
                <ListItemText
                  primary={item.title}
                  sx={{ color: "rgba(0, 0, 0, 0.7)" }}
                  onClick={() => item.to === '/jobs' ? history.push({
                    pathname: "/jobs",
                    state: { title: "related" }
                  },) : history.push(`${item.to}`)}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </MuiDrawer>
  );
  const sidebarDesktop = (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      open={isMobile ? openDrawer : true}
      onClose={isMobile ? handleDrawerToggle : undefined}
      sx={{
        // width: 200,
        // minWidth: '48px',
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          // width: 200,
          width: "calc(70px)",
        },
      }}
    >
      <List sx={{ pt: "68px" }}>
        {navItems.map((item, index) => (
          // item?.title === 'Jobs'
          //   ? <ListItem key={item} disablePadding sx={{ display: "block" }}>
          //   <Tooltip title={item?.title} placement="right">

          //       <ListItemButton
          //         // onClick={() => navItemOnClickHandler(item?.title, index)}
          //         // onClick={() => viewAllRecentJobs(index)}
          //         key={index}
          //         selected={selectedItem === index}
          //         sx={{
          //           minHeight: 48,
          //           // justifyContent: open ? 'initial' : 'center',
          //           //   px: 2.5,
          //           display: "flex",
          //           flexDirection: "column",
          //           fontSize: "10px",
          //           color: "#9e9e9e",
          //           borderLeft:
          //             selectedItem === index ? "2px solid #2196f3" : "none",
          //           marginLeft: "5px",
          //           padding: "5px",
          //         }}
          //       >
          //         <ListItemIcon
          //           sx={{
          //             minWidth: 0,
          //             // mr: open ? 3 : 'auto',
          //             justifyContent: "center",
          //           }}
          //         >
          //           {selectedItem === index ? item.icon1 : item.icon2}
          //         </ListItemIcon>
          //         {/* <ListItemText primary={text} sx={{fontSize:'10px'}}
          //       // sx={{ opacity: open ? 1 : 0 }}
          //        /> */}
          //         {item.title}
          //       </ListItemButton>

          //   </Tooltip>
          // </ListItem>
          // :
          <ListItem key={item} disablePadding sx={{ display: "block" }}>
            <Tooltip title={item?.title} placement="right">
              {item?.onClick ? (
                <ListItemButton
                  // onClick={() => navItemOnClickHandler(item?.title, index)}
                  // onClick={() => setSelectedItem(index)}
                  onClick={() => {
                    setSelectedItem(index);
                    item.onClick();
                  }}
                  key={index}
                  selected={selectedItem === index}
                  sx={{
                    minHeight: 48,
                    // justifyContent: open ? 'initial' : 'center',
                    //   px: 2.5,
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "10px",
                    color: "#9e9e9e",
                    borderLeft:
                      selectedItem === index ? "2px solid #2196f3" : "none",
                    marginLeft: "5px",
                    padding: "5px",
                    fontWeight: 600
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      // mr: open ? 3 : 'auto',
                      justifyContent: "center",
                    }}
                  >
                    {selectedItem === index ? item.icon1 : item.icon2}
                  </ListItemIcon>
                  {/* <ListItemText primary={text} sx={{fontSize:'10px'}}
        // sx={{ opacity: open ? 1 : 0 }}
         /> */}
                  {item.title}
                </ListItemButton>
              ) : (
                <Link to={item?.to}>
                  <ListItemButton
                    // onClick={() => navItemOnClickHandler(item?.title, index)}
                    onClick={() => setSelectedItem(index)}
                    key={index}
                    selected={selectedItem === index}
                    sx={{
                      minHeight: 48,
                      // justifyContent: open ? 'initial' : 'center',
                      //   px: 2.5,
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "10px",
                      color: "#9e9e9e",
                      borderLeft:
                        selectedItem === index ? "2px solid #2196f3" : "none",
                      marginLeft: "5px",
                      padding: "5px",
                      fontWeight: 600,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        // mr: open ? 3 : 'auto',
                        justifyContent: "center",
                      }}
                    >
                      {selectedItem === index ? item.icon1 : item.icon2}
                    </ListItemIcon>
                    {/* <ListItemText primary={text} sx={{fontSize:'10px'}}
          // sx={{ opacity: open ? 1 : 0 }}
           /> */}
                    {item.title}
                  </ListItemButton>
                </Link>
              )}
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  // const Search = styled("div")(({ theme }) => ({
  //   position: "relative",
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.9),
  //   "&:hover": {
  //     backgroundColor: alpha(theme.palette.common.white, 0.6),
  //   },
  //   color: alpha(theme.palette.common.black, 0.9),
  //   marginRight: theme.spacing(2),
  //   marginLeft: 0,
  //   width: "100%",
  //   [theme.breakpoints.up("sm")]: {
  //     marginLeft: theme.spacing(3),
  //     width: "auto",
  //   },
  // }));

  // const SearchIconWrapper = styled("div")(({ theme }) => ({
  //   padding: isMobile ? theme.spacing(0, 0.5) : theme.spacing(0, 2),
  //   height: "100%",
  //   position: "absolute",
  //   pointerEvents: "none",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // }));

  // const StyledInputBase = styled(InputBase)(({ theme }) => ({
  //   color: "inherit",
  //   "& .MuiInputBase-input": {
  //     padding: theme.spacing(1, 1, 1, 0),
  //     // vertical padding + font size from searchIcon
  //     paddingLeft: isMobile
  //       ? `calc(1em + ${theme.spacing(1)})`
  //       : `calc(1em + ${theme.spacing(4)})`,
  //     transition: theme.transitions.create("width"),
  //     width: "100%",
  //     fontSize: isMobile ? "13px" : null,
  //     [theme.breakpoints.up("md")]: {
  //       width: "40ch",
  //     },
  //   },
  //   input: {
  //     "&::placeholder": {
  //       textOverflow: "ellipsis !important",
  //       color: "rgba(0,0,0,1)",
  //     },
  //   },
  // }));

  return (
    <div sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          bgcolor: "#c6c6c6",
        }}
      >
        {/* #53778e */}
        <Toolbar sx={{ minHeight: "62px !important", pl: isMobile ? 0 : null }}>
          {/* Your header content */}

          {isMobile && (
            <Toolbar sx={{ minHeight: "40px important", pl: 0 }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{ ml: "auto", display: { sm: "block", md: "none" } }}
              >
                <MenuRoundedIcon />
              </IconButton>
            </Toolbar>
          )}
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {/* <img
              src="/static/media/jobsnProfiles.54d22dc53e022b59b8b7.png"
              alt="logo"
              style={{ cursor: "pointer", width: "100px" }}
            /> */}
            <img src={lightLog} width={180} />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }} />
          {/* <Search
            // onClick={() => setFormOpen(true)}
            onClick={handleOpenSearchModal}
            sx={{ flexGrow: 1 }}
          >
            <SearchIconWrapper>
              <SearchIcon
                sx={{
                  fontSize: isMobile ? "13px" : null,
                }}
              />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search for job title, skills, location and more"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
          <Search
            ref={searchRef}
            sx={{
              borderBottomRightRadius: showHistory && 0,
              borderBottomLeftRadius: showHistory && 0,
              backgroundColor: showHistory && "#fff",
            }}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={query}
              onChange={(e) => handleSearchChange(e)}
              onFocus={handleFocus}
              onKeyDown={(e) =>
                e.key === "Enter" && handleSearchSubmit("new_text", query)
              }
            />
            {showHistory && (
              <SearchHistory
                component={List}
                sx={{
                  borderTopRightRadius: showHistory ? 0 : "",
                  borderTopLeftRadius: showHistory ? 0 : "",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
                // sx={{
                //   position: 'absolute',
                //   top: '100%',
                //   left: 0,
                //   right: 0,
                //   bgcolor: 'background.paper',
                //   boxShadow: 3,
                //   zIndex: 10,
                //   maxHeight: '200px',
                //   overflowY: 'auto',
                //   borderRadius: '3px',
                // }}
              >
                <Box sx={{ pl: 2, pr: 2 }}>
                  <Divider />
                </Box>
                {searchHistory.length > 0 &&
                  searchHistory.map((data, index) => (
                    <ListItem
                      key={data.id}
                      sx={{
                        // border: "1px solid rgba(229, 234, 242, 0.5)",
                        // mb: "6px",
                        backgroundColor: "#f3f6f966",
                        // borderRadius: "10px",
                        cursor: "pointer",
                        pt: "3px",
                        pb: "3px",
                        ".keyboardIcon": {
                          display: "none",
                        },
                        "&:hover": {
                          color: "#1E88E5",
                          backgroundColor: "#E3F2FD",
                          // border: "1px solid #64b5f6",
                          ".keyboardIcon": {
                            display: "block",
                          },
                          "& .highlighted": {
                            textDecoration: "underline",
                          },
                          "& .hoverColor": {
                            color: "#1E88E5",
                          },
                        },
                      }}
                    >
                      <HistoryRounded
                        className="hoverColor"
                        sx={{ fontSize: 18, mr: 2, color: "#6f7f95" }}
                      />
                      <ListItemText
                        primary={highlightMatch(data.keyword)}
                        primaryTypographyProps={{ fontSize: "13px" }}
                        onClick={() => {
                          setQuery(data.keyword);
                          handleSearchSubmit("old_text", data.keyword);
                        }}
                      />
                      <Box sx={{ flexGrow: 1 }} />
                      {/* <KeyboardReturnRounded
                      sx={{ color: "#1E88E5" }}
                      className="keyboardIcon"
                    /> */}
                      <DeleteOutlineRounded
                        className="keyboardIcon"
                        sx={{
                          color: "#1E88E5",
                          fontSize: 18,
                          "&:hover": { color: "#d32f2f" },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteSearchHistory(data);
                        }}
                      />
                      {/* <Button variant="text" color="primary" size="small" 
                      className="keyboardIcon"
                    sx={{
                      textTransform: 'capitalize',
                      padding: 0,
                      // paddingInline: '7px',
                      }}>
                      Delete
                    </Button> */}
                    </ListItem>
                  ))}
                {/* {searchHistory.map((item, index) => (
                    <Typography
                      key={index}
                      sx={{
                        padding: theme => theme.spacing(1),
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: alpha('#406882', 0.1),
                        },
                      }}
                      onClick={() => {
                        setQuery(item);
                        handleSearchSubmit();
                      }}
                    >
                      {item}
                    </Typography>
                  ))} */}
              </SearchHistory>
            )}
            <IconWrapper onClick={handleInfoClick}>
              <InfoOutlined />
            </IconWrapper>
            <IconWrapper>
              <Button
                variant="contained"
                disableElevation
                size="small"
                color="primary"
                onMouseDown={() => handleSearchSubmit("new_text", query)}
                sx={{ paddingInline: "7px", fontSize: "12px" }}
              >
                Search
              </Button>
            </IconWrapper>
            <Popover
              id={idInfo}
              open={openInfo}
              anchorEl={anchorElInfo}
              onClose={handleInfoClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography sx={{ p: "6px" }}>Eg:</Typography>
                <Typography sx={{ p: "2px" }}>
                  1. Find Software Engineer jobs in California at Google
                </Typography>
                <Typography sx={{ p: "2px" }}>
                  2. Looking for 5 years experienced Python jobs at Microsoft in
                  New York
                </Typography>
                <Typography sx={{ p: "2px" }}>
                  3. H1B visa Full-Time Java developer jobs at Amazon in Texas
                </Typography>
                <Typography sx={{ p: "2px" }}>
                  4. Show me all Contract positions at Facebook
                </Typography>
                <Typography sx={{ p: "2px" }}>
                  5. I want Salesforce jobs in New York at IBM
                </Typography>
                <Typography sx={{ p: "2px" }}>
                  6. Python jobs at Google
                </Typography>
                <Typography sx={{ p: "2px" }}>7. Jobs in New York</Typography>
                <Typography sx={{ p: "2px" }}>
                  8. Salesforce jobs with 3 years experience
                </Typography>
                <Typography sx={{ p: "2px" }}>9. OPT jobs</Typography>
                <Typography sx={{ p: "2px" }}>
                  10. Search with ID Need to add # before ID {`(#309132)`}
                </Typography>
              </Box>
            </Popover>
          </Search>
          <Box sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }} />
          <Modal open={formOpen} onClose={() => setFormOpen(false)}>
            <Jssearchblock formOpen={formOpen} handleClose={handleClose} />
          </Modal>
          <Box sx={{ display: { xs: "flex", md: "flex" }, alignItems: 'center', gap: 2 }}>
            {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <Mail />
              </Badge>
            </IconButton> */}
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleOpenNotificationMenu}
            > */}
              <Badge badgeContent={notificationsCount} color="error">
                <IoNotificationsOutline style={{ color: "#585858", height:'26px', width:'26px', cursor: 'pointer' }}  onClick={handleOpenNotificationMenu} />
              </Badge>
            {/* </IconButton> */}
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorElNotification}
              open={openNotification}
              onClose={handleCloseNotificationMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 5.5,
                  width: "40ch",
                  padding: "4px",
                },
              }}
              // title="Notifications"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>Notifications</p>
                <p
                  onClick={viewAllNotificationsHandler}
                  style={{ cursor: "pointer", color: "#406882" }}
                >
                  View All
                </p>
              </div>
              <Divider sx={{ margin: 0 }} />
              {notifications.map((notify) => (
                <MenuItem
                  key={notify.id}
                  // selected={option === 'Pyxis'}
                  onClick={() =>
                    notify.notification_subject !==
                    "Your recruiter deleted a Job"
                      ? updateNotificationReadStatus(notify.id, notify.job_id)
                      : ""
                  }
                >
                  <Stack direction="row" spacing={1}>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={notify.notification_read.includes(
                        sessionStorage.getItem("id")
                      )}
                    >
                      <Message sx={{ color: "rgba(0,0,0,0.4)" }} />
                    </Badge>
                    <Stack direction="column" spacing={0}>
                      <p
                        style={{
                          overflowWrap: "break-word",
                          whiteSpace: "normal",
                          margin: 0,
                          fontSize: "14px",
                        }}
                      >
                        {notify.notification_subject}
                      </p>
                      <small>{moment(notify.created).fromNow()}</small>
                    </Stack>
                  </Stack>
                </MenuItem>
              ))}
            </Menu>

            {/* <Box sx={{ flexGrow: 0 }}> */}
            {/* <Tooltip title="Open settings"> */}
            {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml:1 }}> */}
            {/* <Avatar
                  alt={currentUser?.full_name}
                  // src={`${Key.files_domain}/dashboard/profile_image/${currentUser.id}/${currentUser.image}`}
                  src={`${Key.s3_files_url}/user_${sessionStorage.getItem(
                    "id"
                  )}/profileimages/${currentUser.image}`}
                  // src={
                  //   sessionStorage.getItem("profileimg") &&
                  //   sessionStorage.getItem("profileimg") !== "null" &&
                  //   `${Key.files_domain}/dashboard/profile_image/` +
                  //     sessionStorage.getItem("id") +
                  //     "/" +
                  //     sessionStorage.getItem("profileimg")
                  // }
                  sx={{ height: "34px", width: "34px" }}
                /> */}
            {/* </IconButton> */}
            {/* </Tooltip> */}
            <Box sx={{ display: "flex", gap: "4px", cursor: 'pointer' }} onClick={handleOpenUserMenu}>
              <Avatar
                alt={currentUser?.full_name}
                // src={`${Key.files_domain}/dashboard/profile_image/${currentUser.id}/${currentUser.image}`}
                src={currentUser?.image ? `${Key.s3_files_url}/user_${sessionStorage.getItem(
                  "id"
                )}/profileimages/${currentUser.image}` 
                : ""
                // "https://img.freepik.com/premium-photo/sleek-icon-portraying-chinese-male-with-testimonial-theme_1283595-14525.jpg?w=740"
              }
                // src={
                //   sessionStorage.getItem("profileimg") &&
                //   sessionStorage.getItem("profileimg") !== "null" &&
                //   `${Key.files_domain}/dashboard/profile_image/` +
                //     sessionStorage.getItem("id") +
                //     "/" +
                //     sessionStorage.getItem("profileimg")
                // }
                sx={{ border:'2px solid white' }}
              />
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Tooltip title={currentUser?.full_name}>
                <Typography variant="body1"
                  sx={{
                    // color: "#05264C",
                    color: "#000",
                    m: 0,
                    fontSize: "14px",
                    fontWeight: 600,
                    maxWidth: 150,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: 'capitalize'
                  }}
                >
                  {currentUser?.full_name}
                </Typography>
                </Tooltip>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography variant="body1"
                  sx={{ 
                    color:  "#000",//"#66789C", 
                    m: 0, 
                    fontSize: "12px", 
                    maxWidth: 150,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: 'capitalize'
                   }}
                >
                  {currentUser?.usertype == "5" ? 
                  primaryResumeDetails?.application_title !== undefined &&
                  primaryResumeDetails?.application_title !== null &&
                  primaryResumeDetails?.application_title !== ""
                    ? primaryResumeDetails.application_title
                    : currentUser?.js_user_role !== undefined &&
                      currentUser?.js_user_role !== null &&
                      currentUser?.js_user_role !== ""
                    ? currentUser.js_user_role
                    : ""
                   : ""}
                  
                </Typography>
                <IoMdArrowDropdown
                    style={{ color: "#05264C", fontSize: "14px" }}
                  />
                </Box>
              </Box>
            </Box>
            <Menu
              sx={{ mt: "32px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))} */}
              <MenuItem key="account" onClick={accountHandler}>
                <Typography textAlign="center">
                  <Link to="/accountSettings">Account</Link>
                </Typography>
              </MenuItem>
              <MenuItem key="logout" onClick={logoutHandler}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
            {/* </Box> */}
          </Box>
        </Toolbar>
      </AppBar>
      {!isMobile && sidebarDesktop}
      {/* <div sx={{ flexGrow: 1, padding: (theme) => theme.spacing(3) }}>
        {!isMobile && <Toolbar />} 
        {children}
      </div> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // padding: (theme) => theme.spacing(3),
          marginLeft: isMobile ? 0 : openDrawer ? drawerWidth : 0,
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar sx={{ display: { md: "none" } }} />
        <Dialog open={alertOpen} onClose={handleAlertClose} fullWidth>
          <DialogTitle>
            <AlertTitle>Info</AlertTitle>
          </DialogTitle>
          <DialogContent>
            <Alert severity="info">{alertMessage}</Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAlertClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            ml: isMobile ? 0 : "70px",
            mt: isTablet ? 0 : isMobile ? 0 : "62px",
            bgcolor: "#f0f6fb",
            p: 1
          }}
        >
          {children}
        </Box>
      </Box>
      {isMobile && sidebarMobile}
      <BlurredModal open={openSearchModal} onClose={handleCloseSearchModal}>
        <Box sx={styleModal}>
          <SearchComponent closeSearch={setOpenSearchModal} />
        </Box>
      </BlurredModal>
    </div>
  );
};

export default JSNavBar;

const BlurredBackdrop = styled(Backdrop)(({ theme }) => ({
  backdropFilter: "blur(8px)",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
}));

function BlurredModal({ open, onClose, children }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropComponent={BlurredBackdrop}
      BackdropProps={{
        sx: { backdropFilter: "blur(2px)" },
      }}
    >
      {children}
    </Modal>
  );
}

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth <= 768 ? "96%" : "70%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0,
  borderRadius: "6px",
  maxHeight: "70vh",
  overflow: "scroll",
};

// import React from 'react';
// import {
//   AppBar,
//   Toolbar,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton,
// } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';

// const JSNavBar = () => {
//   const [mobileOpen, setMobileOpen] = React.useState(false);
//   const [sidebarOpen, setSidebarOpen] = React.useState(true);

//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };

//   return (
//     <div style={{ display: 'flex' }}>
//       <AppBar position="fixed">
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             edge="start"
//             onClick={handleDrawerToggle}
//             sx={{ mr: 2, display: { md: 'none' } }}
//           >
//             <MenuIcon />
//           </IconButton>
//           {/* Add logo or title */}
//           {/* <Typography variant="h6" noWrap>
//             Your Logo/Title
//           </Typography> */}
//         </Toolbar>
//       </AppBar>
//       <Drawer variant="temporary" open={mobileOpen} onClose={handleDrawerToggle}>
//         {/* ... Mobile drawer content */}
//         {/* Include navigation links */}
//       </Drawer>
//       <Drawer variant="permanent" open={sidebarOpen}>
//         <List>
//           {/* ... Sidebar content */}
//           {/* Include navigation links */}
//         </List>
//       </Drawer>
//            <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           padding: (theme) => theme.spacing(3),
//           marginLeft: isMobile ? 0 : (openDrawer ? drawerWidth : 0),
//           transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//           }),
//         }}
//       >
//         <Toolbar />
//         {children}
//       </Box>
//       {/* Add your main content here */}
//       {/* <main>
//         Your main content goes here
//       </main> */}
//     </div>
//   );
// };

// export default JSNavBar;
