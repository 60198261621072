import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import axios from "axios";


const GC_Data = ({ companyData }) => {
  const history = useHistory();
  const [rows, setRows] = useState([]);


  useEffect(() => {
    if (companyData?.id) {
      getGcData(); 
    }
  }, [companyData]);


  const handleClick = (redirect) => {
    history.push(`/companies/${redirect}`);
  };


  const getGcData = async () => {
    try {
      const response = await axios.post(
        `https://apps.jobsnprofiles.com/company/immigration_data_gc`,
        {
          company_id: companyData?.id,
        }
      );

      if (response.status === 200) {
        const data = response.data.data.data.gc_immigration_data;
console.log(response.data.data);

        const extractedData = data.map((item, index) => ({
          id: item.id || index,
          lcaCaseNumber: item.CASE_NUMBER,
          h1bJobTitle: item.JOB_TITLE,
          cityState: `${item.WORKSITE_CITY}, ${item.WORKSITE_STATE}`,
          decisionDate: item.DECISION_DATE,
          // processingTime: item.PROCESSING_TIME || "20 months, 25 days",
          prevailingWageLevel: item.PW_SKILL_LEVEL,
          status: item.CASE_STATUS,
        }));

        setRows(extractedData);
        console.log(extractedData);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      field: "lcaCaseNumber",
      headerName: "LCA Case Number",
      width: 180,
      renderCell: (params) => (
        <span
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleClick(params.value)}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "h1bJobTitle",
      headerName: "H1B Job Title",
      width: 160,
      renderCell: (params) => (
        <span
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleClick(params.value)}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "cityState",
      headerName: "Location (City, State)",
      width: 200,
      renderCell: (params) => (
        <span
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleClick(params.value)}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "prevailingWageLevel",
      headerName: "Prevailing Wage Level",
      width: 180,
    },
    {
      field: "decisionDate",
      headerName: "Decision Date",
      width: 150,
    },
    // {
    //   field: "processingTime",
    //   headerName: "Processing Time",
    //   width: 150,
    // },
    {
      field: "status",
      headerName: "Status",
      width: 120,
    },
  ];
  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
        List of PERM Labor Certifications (LCs) for {companyData?.name} - 2023 & 2024
      </Typography>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
        />
      </Box>
      <Box sx={{ mt: 2 }}>
      <Typography variant="body2" sx={{ mt: 2, mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year extends from October 1 until September 30.
        </Typography>
        {/* <Typography variant="body2" sx={{ mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year spans from October 1st to September 30th.
        </Typography> */}
      </Box>
    </Box>
  );
};

export default GC_Data;
