import React from "react";
import { useSelector } from "react-redux";
import { useResponsiveStyles } from "../../../../../Styles/responsiveStyles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

function ResumeSkills() {
  const styles = useResponsiveStyles();
  const searchState = useSelector((state) => state.searchResumeReducer);
  const {
    searchParams = {},
    filterParams = {},
    paginationParams = {},
    results = [],
    numFound,
    selectedResume = null,
    error,
  } = searchState || {};

  const skillsToHighlight = searchParams?.skills || [];

  const skillsInResume = selectedResume?.ai_skills
    ?.split(",")
    .map((skill) => skill.trim());
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(64, 104, 130, 0.9)",
      //  theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: styles.fontSize14,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: styles.fontSize14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: 400, maxWidth: "400px" }}
        >
          <Table aria-label="customized table" stickyHeader size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={2} sx={{ textAlign: "center" }}>
                  Resume Skills
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {skillsInResume?.length > 0 ? (
                skillsInResume.map((row, index) => {
                  const isHighlighted = skillsToHighlight.includes(row);
                  if (index % 2 === 0) {
                    return (
                      <StyledTableRow key={index}>
                        <TableCell 
                          sx={{
                            fontSize: styles.fontSize14,
                            backgroundColor: isHighlighted ? "yellow" : "inherit", 
                            fontWeight: isHighlighted ? "bold" : "normal",
                          }}>
                          {row}
                        </TableCell>
                        {skillsInResume[index + 1] && (
                          <TableCell sx={{
                            fontSize: styles.fontSize14,
                            backgroundColor: skillsToHighlight.includes(skillsInResume[index + 1]) ? "yellow" : "inherit", 
                            fontWeight: skillsToHighlight.includes(skillsInResume[index + 1]) ? "bold" : "normal",
                          }}>
                            {skillsInResume[index + 1]}
                          </TableCell>
                        )}
                      </StyledTableRow>
                    );
                  }
                  return null;
                })
              ) : (
                <Typography
                  paragraph
                  sx={{
                    margin: 0,
                    paddingTop: 2,
                    textAlign: "center",
                  }}
                >
                  Skills Not found
                </Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default ResumeSkills;
