import { Box, Card, Typography } from "@mui/material";
import React from "react";
import H1B_Approvals_Denails from "./H1B_Approvals_Denails";
import LCA_Approvals_Denails from "./LCA";
import palette from "../../../theme/palatte";

const H1B_Approvals = ({ companyData }) => {
  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        H1B LCA Approvals for {companyData?.name}
      </Typography>
      {/* <Typography
        variant="body2"
        sx={{ fontSize: "12px",
          //  color: "#d32f2f", 
           mb: 2, mt: 1, textAlign: 'justify' }}
      >
        The below data & graphs are based on USCIS & US Department of Labor
        public disclosure files for the H1B program. We do our best to load data
        as they release it, check our data refresh status for more. For the most
        accurate & recent data, you must check the USCIS & US Dept of Labor
        websites. We are not liable for any data inaccuracies presented below,
        use it at your discretion. By using our website you are agreeing to our
        <Box
          component="span"
          sx={{
            textDecoration: "none",
            color: "primary.main",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {" "}
          terms and conditions.
        </Box>
      </Typography> */}
      <Typography variant="body1" sx={{ fontSize: "14px", textAlign: 'justify' }}>
      The data below is based on public disclosures from U.S. Citizenship and Immigration Services (USCIS) and the U.S. Department of Labor (DOL) regarding the H1B program. We update this information as new data becomes available, but for the latest details, please check the official USCIS and DOL websites. Labor Condition Applications (LCAs), required for H1B hiring, are filed with the Department of Labor and can cover multiple positions. After LCA approval, USCIS reviews petitions for foreign workers already in the U.S., while visa approvals for those outside the U.S. are handled through consular processing.
        
        {/* <Box
          component="span"
          sx={{
            textDecoration: "none",
            color: "primary.main",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() =>
            window.open(
              "https://visagrader.com/visa-approvals-and-refusals/H1B",
              "_blank"
            )
          }
        >
          website.
        </Box> */}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Typography
          variant="body2"
          sx={{
            color: "#6a9ed8", // "#0A9094",
            fontWeight: 500,
            textAlign: "center",
            mb: 1,
          }}
        >
          {/* For fiscal year (FY) 2024, the LCA data is only available till Q3.
          USCIS data is released only once per year. */}
          Annual USCIS Data Report for {companyData?.name}
        </Typography>
        <LCA_Approvals_Denails companyData={companyData} />

        <Typography variant="body2" sx={{ mt: 2, mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year extends from October 1 until September 30.
        </Typography>
        {/* <Typography variant="body2" sx={{ mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year spans from October 1st to September 30th.
        </Typography> */}
        {/* <Typography variant="body2" sx={{ mb: 0.5 }}>
          To know last refresh date for website data,
          <Box
            component="span"
            sx={{
              textDecoration: "none",
              color: "primary.main",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => window.open("#", "_blank")}
          >
            {" "}
            Check Data Refresh Status
          </Box>
        </Typography> */}
      </Box>
      {/*------------ H1B USCIS Approvals for {companyData?.name}------------- */}
      <Typography variant="h5" sx={{ fontWeight: 600, mt: 4, mb: 2 }}>
        H1B USCIS Approvals for {companyData?.name}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <H1B_Approvals_Denails companyData={companyData} />

        <Typography variant="body2" sx={{ mt: 2, mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year extends from October 1 until September 30.
        </Typography>
        {/* <Typography variant="body2" sx={{ mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year spans from October 1st to September 30th.
        </Typography> */}
        
      </Box>
      {/* <Box sx={{ mt: 1 }}>
        <Card
          sx={{
            p: 3,
            borderLeft: `5px solid ${palette.primary.light}`, //#699293
            borderRight: "1px solid #ddd",
            borderTop: "1px solid #ddd",
            borderBottom: "1px solid #ddd",
            mb: 3,
            mt: 3,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            What is the H1B Approval Rate for {companyData?.name} ?
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {companyData?.name} filed 14 LCAs in fiscal year 2024, of which{" "}
            <span
              style={{
                backgroundColor: "#4caf50",
                color: "#fff",
                fontWeight: 600,
                paddingInline: "7px",
                borderRadius: "4px",
              }}
            >
              13
            </span>{" "}
            were approved and{" "}
            <span
              style={{
                backgroundColor: "#ff9800",
                color: "#fff",
                fontWeight: 600,
                paddingInline: "7px",
                borderRadius: "4px",
              }}
            >
              0
            </span>{" "}
            were denied. Also, Nitya Software Solutions INC filed 0 with USCIS
            and{" "}
            <span
              style={{
                backgroundColor: "#4caf50",
                color: "#fff",
                fontWeight: 600,
                paddingInline: "7px",
                borderRadius: "4px",
              }}
            >
              0
            </span>{" "}
            were approved and{" "}
            <span
              style={{
                backgroundColor: "#ff9800",
                color: "#fff",
                fontWeight: 600,
                paddingInline: "7px",
                borderRadius: "4px",
              }}
            >
              0
            </span>{" "}
            were denied. The H1B LCA Approval rate ≈
            <span
              style={{
                backgroundColor: "#42a5f5",
                color: "#fff",
                fontWeight: 600,
                paddingInline: "7px",
                borderRadius: "4px",
              }}
            >
              {" "}
              92%
            </span>
            . The USCIS H1B petitions approval rate ≈
            <span
              style={{
                backgroundColor: "#42a5f5",
                color: "#fff",
                fontWeight: 600,
                paddingInline: "7px",
                borderRadius: "4px",
              }}
            >
              {" "}
              N/A%
            </span>
            .
          </Typography>
        </Card>
        <Card
          sx={{
            p: 3,
            borderLeft: `5px solid ${palette.primary.light}`,
            borderRight: "1px solid #ddd",
            borderTop: "1px solid #ddd",
            borderBottom: "1px solid #ddd",
            mb: 3,
            mt: 3,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Does {companyData?.name} Sponsor H1B Visa?
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Yes, {companyData?.name} sponsors H1B Visa. The above sections on
            this page show the complete H1B Sponsorship data for
            {companyData?.name}. They filed a total of 14 LCAs in fiscal year
            2024 with US Department of Labor. For additional H1B sponsorship
            data for {companyData?.name} on the job titles, approvals, and
            salaries they sponsored, check the relevant tabs on this page.
          </Typography>
        </Card>
      </Box> */}
    </Box>
  );
};

export default H1B_Approvals;
