import {
    OPEN_EDIT_BOX,
    CLOSE_EDIT_BOX,
    SET_CURRENT_SECTION,
    SET_ACTION_TYPE,
    JS_FULL_RESUME_DETAILS_SUCCESS
  } from './actions';
  
  const initialState = {
    isEditBoxOpen: false,
    currentTemplateId: null,
    currentSection: null, 
    editContent: null,
    templates: {}, 
    selectedResumeDetails: null,
    resumeActionType: null,

  };
  
  const resumeBuilderReducer = (state = initialState, action) => {
    switch (action.type) {
      case OPEN_EDIT_BOX:
        return {
          ...state,
          isEditBoxOpen: true,
          currentTemplateId: action.payload.templateId,
          currentSection: action.payload.section,
        };
  
      case CLOSE_EDIT_BOX:
        return {
          ...state,
          isEditBoxOpen: false,
          currentTemplateId: null,
          currentSection: null,
          editContent: null,
        };
  
        case SET_CURRENT_SECTION: 
      return {
        ...state,
        currentSection: action.payload,
      };
      case SET_ACTION_TYPE: 
      return {
        ...state,
        resumeActionType: action.payload,
      };

      case JS_FULL_RESUME_DETAILS_SUCCESS:
        return {
          ...state,
          selectedResumeDetails: action.payload,
        };


      default:
        return state;
    }
  };
  
  export default resumeBuilderReducer;
  