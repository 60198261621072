import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
import {
  Avatar,
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  Radio,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Modal,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  tableCellClasses,
} from "@mui/material";
import { Button, Grid, Box } from "@mui/material";

import {
  ArrowBackIosRounded,
  ArrowRightOutlined,
  AssignmentLateRounded,
  BusinessCenterOutlined,
  BusinessCenterRounded,
  BusinessRounded,
  CloseRounded,
  EmailRounded,
  FavoriteBorderOutlined,
  FlightOutlined,
  MoreVertRounded,
  PeopleOutlined,
  PhoneRounded,
  RoomOutlined,
  SchoolOutlined,
  WorkHistoryOutlined,
  WorkspacePremiumRounded,
} from "@mui/icons-material";
import { FaUserGraduate } from "react-icons/fa6";
import { MdOutlineWorkHistory } from "react-icons/md";
import { useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { linearProgressClasses } from "@mui/material";
import { MdFlight } from "react-icons/md";
import { FaListCheck } from "react-icons/fa6";
import { styled, alpha } from "@mui/material/styles";

import PropTypes from "prop-types";
import parse from "html-react-parser";
import { useResponsiveStyles } from "../../Styles/responsiveStyles";
import LCA_Approvals_Denails from "./imigration/LCA";
import GC_Approvals_Denails from "./imigration/GC_Approvals_Denails";
import H1B_Approvals_Denails from "./imigration/H1B_Approvals_Denails";
import LOGOIMG from "../../Images/logo/jnp_log.png";
import JobList from "./jobs/JobList";
import JobDetail from "./jobs/JobDetail";
import { getComapanyLocation, getJobLocation } from "../../utils/getLocation";
import Employees from "./employees/Employees";
import Main from "./imigration/Main";
import { getContrastingTextColor } from "./imigration/contrastColorGenerator";
import { useLocation } from "react-router-dom";
import Overview from "./overview/Overview";

function CustomTabPanelMain(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanelMain.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yPropsMain(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#53778e",
    //  theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const Bullet = styled("span")({
  display: "inline-block",
  width: "3px",
  height: "3px",
  borderRadius: "50%",
  backgroundColor: "currentColor",
});

const Details = ({ companyData }) => {
  const styles = useResponsiveStyles();
  const location = useLocation();
  const [companyTabValue, setCompanyTabValue] = useState(0);
  const [mainTabValue, setMainTabValue] = useState(0);
  const comapanyDetails = companyData || null;
  const H1BData = companyData?.h1_data || null;
  const LCAData = companyData?.lca_data || null;
  const GCData = companyData?.gc_data || null;
  const [selectedJob, setSelectedJob] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops

  const handleChange = (event, newValue) => {
    setCompanyTabValue(newValue);
  };
  const handleChangeMain = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const redirectToGoogleMaps = (companyName) => {
    const baseUrl = 'https://www.google.com/maps/search/';
    const query = encodeURIComponent(companyName);
    window.location.href = `${baseUrl}${query}`;
  };

  return (
    <Grid
      container
      spacing={0.5}
      // sx={{bgcolor:'#EEF1F3', }}
    >
      {
        location.pathname.includes("comp_") &&
      
      <Grid item xs={4}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper
              sx={{
                height: window.innerHeight - 50,
                overflow: "scroll",
              }}
            >
              {/* <Toolbar
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    minHeight: "48px !important",
                  }}
                >
                  <Box
                    sx={{
                      flex: "1 1 100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: 500, margin: 0 }}
                      // variant="h6"
                      paragraph
                      id="tableTitle"
                      component="div"
                    >
                      Company Detail
                    </Typography>
                  </Box>
                </Toolbar>
                <Divider sx={{ margin: 0 }} /> */}
              <Grid item xs={12}>
                {true ? (
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "8px",
                      p: 1,
                      pb: 0,
                    }}
                  >
                    <Stack direction="row" gap={2}>
                      <Avatar
                        // src="https://img.freepik.com/premium-photo/free-vector-colorful-bird-illustration-gradient-bird-colorful-logo-gradient-vector_971166-58958.jpg?w=740"
                        src={
                          comapanyDetails?.logo_url
                            ? comapanyDetails?.logo_url
                            : ""
                          // "https://img.freepik.com/premium-photo/free-vector-colorful-bird-illustration-gradient-bird-colorful-logo-gradient-vector_971166-59182.jpg?w=740"
                        }
                        sx={{
                          height: isMobile ? "70px" : "100px",
                          width: isMobile ? "70px" : "100px",
                        }}
                      />
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 600, textTransform: "capitalize" }}
                        >
                          {comapanyDetails?.name?.toLowerCase()}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "2px",
                            alignItems: "end",
                            mb: "4px",
                          }}
                        >
                          <RoomOutlined
                            sx={{ color: "#E0E6F7", fontSize: "18px" }}
                          />
                          <Typography variant="body2" sx={{}}>
                            {getComapanyLocation(companyData)}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            variant="body2"
                            sx={{
                              bgcolor: "#FFECB3",
                              color: getContrastingTextColor("#FFECB3"),
                              paddingInline: "15px",
                              borderRadius: "8px",
                              fontSize: "14px",
                            }}
                          >
                            Cumputer/IT
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            mt: 1,
                            gap: 2,
                          }}
                        >
                          <Tooltip title="Coming Soon..." followCursor>
                          <Button
                            variant="outlined"
                            startIcon={<FavoriteBorderOutlined />}
                            color="primary"
                            sx={{ textTransform: "capitalize" }}
                          >
                            Favorite
                          </Button>
                          </Tooltip>
                          <Button
                            variant="contained"
                            startIcon={<PeopleOutlined />}
                            color="primary"
                            disableElevation
                            sx={{ textTransform: "capitalize" }}
                            onClick={() => setMainTabValue(1)}
                          >
                            View Contacts
                          </Button>
                        </Box>
                      </Box>
                    </Stack>

                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          borderTop: 1,
                          borderTopColor: "divider",
                          mt: 2,
                        }}
                      >
                        <Tabs
                          value={companyTabValue}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                        >
                          <Tab
                            label="Overview"
                            {...a11yProps(0)}
                            sx={{ textTransform: "capitalize" }}
                          />
                          <Tab
                            label="About"
                            {...a11yProps(1)}
                            sx={{ textTransform: "capitalize" }}
                          />
                          {(isMobile || isTablet) && (
                            <Tab
                              label="Jobs"
                              {...a11yProps(2)}
                              sx={{ textTransform: "capitalize" }}
                            />
                          )}

                          <Tab
                            label="Addresses"
                            {...a11yProps(3)}
                            sx={{ textTransform: "capitalize" }}
                          />
                        </Tabs>
                      </Box>
                    </Box>
                  </Card>
                ) : (
                  <Card> Data Not Found...</Card>
                )}
              </Grid>

              <Grid item xs={12}>
                <Card
                  sx={{
                    boxShadow: "none",
                    p: 1,
                    borderRadius: "8px",
                    height: "600px",
                    overflow: "scroll",
                  }}
                >
                  <CustomTabPanel value={companyTabValue} index={0} sx={{}}>
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Company Name
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                            onClick={() => redirectToGoogleMaps(comapanyDetails?.name)}
                          >
                            {comapanyDetails?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Contact Name
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            {comapanyDetails?.visa_contacts_data?.[0]?.contact_name||comapanyDetails?.contactname || "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Email
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            {comapanyDetails?.visa_contacts_data?.[0]?.contact_email||comapanyDetails?.contactemail || "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Phone
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            {comapanyDetails?.visa_contacts_data?.[0]?.contact_phone||comapanyDetails?.contactphone || "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Country
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            USA
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            State
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            {comapanyDetails?.state_name || "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            City
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            {comapanyDetails?.city_name || "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Zipcode
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            {comapanyDetails?.zipcode || "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Address
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            {comapanyDetails?.address1 ||
                            comapanyDetails?.address2
                              ? `${comapanyDetails?.address1 || ""} ${
                                  comapanyDetails?.address2 || ""
                                }`.trim()
                              : "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Since
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            {comapanyDetails?.since || "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Industry
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            IT
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Overall Rating
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            {" "}
                            {comapanyDetails?.overall_rating || "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Stock Value
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.9)",
                            }}
                          >
                            {comapanyDetails?.stock_value || "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Divider />
                  </CustomTabPanel>
                  <CustomTabPanel value={companyTabValue} index={1} sx={{}}>
                    <Card
                      sx={{ boxShadow: "none", p: 0, textAlign: "justify" }}
                    >
                      <CardHeader
                        title={
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 600,
                              color: "rgba(0,0,0,0.7)",
                            }}
                          >
                            Overview
                          </Typography>
                        }
                      />
                      <CardContent sx={{ pt: 0 }}>
                        <Typography
                          paragraph
                          sx={{
                            color: "black",
                            fontSize: isMobile ? "14px" : "14px",
                          }}
                        >
                          {comapanyDetails?.description !== null &&
                          comapanyDetails?.description !== undefined &&
                          comapanyDetails?.description
                            ? !comapanyDetails?.description.includes("ahref") &&
                              !comapanyDetails?.description.includes(
                                "divclass"
                              ) &&
                              !comapanyDetails?.description.includes(
                                "aclass"
                              ) &&
                              !comapanyDetails?.description.includes(
                                "pclass"
                              ) &&
                              !comapanyDetails?.description.includes("liclass")
                              ? parse(comapanyDetails?.description)
                              : comapanyDetails?.description.replace(
                                  /<[^>]+>/g,
                                  ""
                                )
                            : "No Description "}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CustomTabPanel>

                  <CustomTabPanel value={companyTabValue} index={2}>
                    <Paper elevation={2} p={4}>
                      <Card sx={{ boxShadow: "none", p: 0 }}>
                        <CardHeader
                          title={
                            <Typography
                              paragraph
                              sx={{
                                margin: 0,
                                fontWeight: 600,
                                color: "rgba(0,0,0,0.7)",
                              }}
                            >
                              Manage Addresses
                            </Typography>
                          }
                        />
                        <CardContent sx={{ pt: 0 }}>
                          <Typography
                            paragraph
                            sx={{
                              color: "black",
                              fontWeight: 600,
                              fontSize: isMobile ? "16px" : "20px", // Adjust the font size as needed
                              m: 0,
                            }}
                          >
                            {comapanyDetails?.name}
                          </Typography>
                          <Typography
                            paragraph
                            sx={{
                              color: "black",
                              fontSize: isMobile ? "14px" : "16px",
                              m: 0,
                            }}
                          >
                            {comapanyDetails && getComapanyLocation(comapanyDetails)}
                          </Typography>
                          <Typography
                            paragraph
                            sx={{ color: "black", fontSize: "16px", m: 0 }}
                          >
                            {comapanyDetails?.zipcode}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Paper>
                  </CustomTabPanel>
                </Card>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
}
      <Grid item xs={location.pathname.includes("comp_") ? 8 : 12}>
        <Paper elevation={1}>
          <Tabs
            value={mainTabValue}
            onChange={handleChangeMain}
            aria-label="basic tabs example"
            sx={{
              minHeight: "auto",
              "& .MuiTab-root": {
                fontSize: styles.fontSize13,
                minHeight: "auto",
                padding: "6px 12px",
                minWidth: "60px",
                textTransform: "capitalize",
                fontWeight: 500,
                // letterSpacing: 0.8
              },
              "& .Mui-selected": {
                color: "#1976d2 !important",
                // backgroundColor: '#e3f2fd',
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#1976d2",
              },
              "& .css-heg063-MuiTabs-flexContainer": {
                //   paddingTop: "10px",
              },
            }}
          >
           { !location.pathname.includes("comp_") &&
            <Tab
              disableRipple
              disableFocusRipple
              disableTouchRipple
              label="Overview"
              {...a11yProps(0)}
            />}
            {companyData?.visa_contacts_data.length > 0 &&
              <Tab
              disableRipple
              disableFocusRipple
              disableTouchRipple
              label="Imigration"
              {...a11yProps(location.pathname.includes("comp_") ? 0 : 1)}
            />}
            <Tab
              disableRipple
              disableFocusRipple
              disableTouchRipple
              label="Jobs"
              {...a11yProps(companyData?.visa_contacts_data.length > 0 ? (location.pathname.includes("comp_") ? 3 : 2) : (location.pathname.includes("comp_") ? 2 : 1))}
            />
            <Tab
              disableRipple
              disableFocusRipple
              disableTouchRipple
              label="Employees"
              {...a11yProps(companyData?.visa_contacts_data.length > 0 ? (location.pathname.includes("comp_") ? 4 : 3) : (location.pathname.includes("comp_") ? 3 : 2))}
            />
            {/* <Tab
              disableRipple
              disableFocusRipple
              disableTouchRipple
              label="Atorney"
              {...a11yProps(2)}
            /> */}
          </Tabs>
          <CustomTabPanel value={mainTabValue} index={!location.pathname.includes("comp_") ? 0 : 100}>
            <Overview companyData={companyData} />
          </CustomTabPanel>
          <CustomTabPanel value={mainTabValue} index={companyData?.visa_contacts_data.length > 0 ? (location.pathname.includes("comp_") ? 0 : 1) : 90}>
            <Main companyData={companyData} />
          </CustomTabPanel>
          <CustomTabPanel value={mainTabValue} index={companyData?.visa_contacts_data.length > 0 ? (location.pathname.includes("comp_") ? 1 : 2) : (location.pathname.includes("comp_") ? 0 : 1)}>
            <Grid container spacing={1} sx={{ p: 1 }}>
              <Grid item xs={4}>
                <JobList setSelectedJob={setSelectedJob} companyData={companyData} />
              </Grid>
              <Grid item xs={8}>
                <JobDetail selectedJob={selectedJob} />
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={mainTabValue} index={companyData?.visa_contacts_data.length > 0 ? (location.pathname.includes("comp_") ? 2 : 3) : (location.pathname.includes("comp_") ? 1 : 2)}>
            <Paper elevation={0} sx={{p:1, bgcolor: '#f8faff'}}>
            <Employees companyData={companyData} />
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel value={mainTabValue} index={5}>
            Atorney Data
          </CustomTabPanel>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Details;
