export const getResumeLocation = (data) => {
  const { res_cityName, res_stateName, res_shortRegion } = data;
  let location = "";

  if (res_cityName) {
    location += res_cityName;
  }

  if (res_shortRegion) {
    location += location ? `, ${res_shortRegion}` : res_shortRegion;
  } else if (res_stateName) {
    location += location ? `, ${res_stateName}` : res_stateName;
  }

  return location;
};

export const getJobLocation = (data = {}) => {
  const { city_name, state_name, short_region, workmode, map_enable } = data;
  // console.log("getJobLocation", data);

  let location = "";

  if (city_name) {
    location += city_name;
  }

  if (short_region) {
    location += location ? `, ${short_region}` : short_region;
  } else if (state_name) {
    location += location ? `, ${state_name}` : state_name;
  }

  return map_enable ? location || "Remote" : "N/A";
};

export const getJobDBLocation = (data = {}) => {
  const { cityname, statename, shortRegion, is_remote, map_enable } = data;

  let location = "";

  if (cityname) {
    location += cityname;
  }

  if (shortRegion) {
    location += location ? `, ${shortRegion}` : shortRegion;
  } 
  else if (statename) {
    location += location ? `, ${statename}` : statename;
  }

  // if (map_enable) {
    return location || (is_remote ? "Remote" : "N/A");
  // } 

  // return "N/A";
};


export const getComapanyLocation = (data = {}) => {
  const { city_name, state_name, short_region} = data;
  // console.log("getJobLocation", data);

  let location = "";

  if (city_name) {
    location += city_name;
  }

  if (short_region) {
    location += location ? `, ${short_region}` : short_region;
  } else if (state_name) {
    location += location ? `, ${state_name}` : state_name;
  }

  return location || "N/A";
};
