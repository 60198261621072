import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

const GC_JobTiltes = ({companyData}) => {
  const history = useHistory();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (companyData?.id) getLcaData();
  }, [companyData]);

  const handleJobClick = (jobTitle) => {
    history.push(`/jobs/${jobTitle}`);
  };

  function findMaxMinAvg(arr) {
    // Convert strings to numbers by removing '$', ',', and trimming spaces
    const filteredArr = arr
      .map((num) => parseFloat(num.replace(/[$,]/g, "").trim())) // Clean and convert to numbers
      .filter((num) => !isNaN(num)); // Keep only valid numbers
  
    if (filteredArr.length === 0) return { max: null, min: null, avg: null };
  
    const max = Math.max(...filteredArr);
    const min = Math.min(...filteredArr);
    const avg = filteredArr.reduce((sum, num) => sum + num, 0) / filteredArr.length;
  
    console.log(min, max, avg);
  
    return { max, min, avg };
  }
  
  const getLcaData = async () => {
    try {
      const response = await axios.post(
        `https://apps.jobsnprofiles.com/company/immigration_data_gc`,
        {
          company_id: companyData?.id,
        }
      );

      if (response.status === 200) {
        const data = response.data.data.data.gc_immigration_data;
        console.log(response.data.data);

        const salariesByJobTitles = data?.reduce((acc, cur) => {
          const jobTitle = cur.JOB_TITLE;
          const salary = cur.PW_WAGE || 0;

          console.log(salary);
          

          if (!acc[jobTitle]) {
            acc[jobTitle] = { salaries: [], count: 0 };
          }

          acc[jobTitle].salaries.push(salary);
          acc[jobTitle].count += 1;

          return acc;
        }, {});

        const extractedData = Object.keys(salariesByJobTitles).map(
          (jobTitle, index) => {
            const { max, min, avg } = findMaxMinAvg(
              salariesByJobTitles[jobTitle].salaries
            );

            return {
              id: index,
              jobTitle,
              lcaCount: salariesByJobTitles[jobTitle].count,
              maxSalary: `$${max?.toFixed(0)}` || "-",
              avgSalary: `$${avg?.toFixed(0)}` || "-",
              minSalary: `$${min?.toFixed(0)}` || "-",
            };
          }
        );

        setRows(extractedData);
        console.log(extractedData);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 250,
      // renderCell: (params) => (
      //   <span
      //     style={{
      //       color: "blue",
      //       textDecoration: "underline",
      //       cursor: "pointer",
      //     }}
      //     onClick={() => handleJobClick(params.value)}
      //   >
      //     {params.value}
      //   </span>
      // ),
    },
    {
      field: "lcaCount",
      headerName: "LCA Count",
      width: 150,
    },
    {
      field: "maxSalary",
      headerName: "Maximum Salary",
      width: 120,
    },
    {
      field: "avgSalary",
      headerName: "Average Salary",
      width: 150,
    },
    {
      field: "minSalary",
      headerName: "Minimum Salary",
      width: 120,
    },
  ];

  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
        Latest Green Card Job Titles sponsored by {companyData?.name} in 2023 & 2024
      </Typography>
      
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
        />
      </Box>
      <Box sx={{ mt: 2 }}>
      <Typography variant="body2" sx={{ mt: 2, mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year extends from October 1 until September 30.
        </Typography>
        {/* <Typography variant="body2" sx={{ mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year spans from October 1st to September 30th.
        </Typography> */}
      </Box>
    </Box>
  );
};

export default GC_JobTiltes;
