import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "@mui/material";
import { useResponsiveStyles } from "../../../../../Styles/responsiveStyles";
import { useDispatch, useSelector } from "react-redux";
import { updateResumePaginationParams } from "../../../../Actions/resumeSearchActions";

const PaginationComponent = () => {
  const styles = useResponsiveStyles();
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.searchResumeReducer);
  const {
    searchParams = {},
    filterParams = {},
    paginationParams = {},
    results = [],
    numFound,
    selectedResume = null,
    error,
  } = searchState || {};

  const isMatchLGL = useMediaQuery("(min-width:1025px)");
  const handlePageChange = (event, newPage) => {
    console.log("newPage", newPage);
    const startNum = newPage === 1 ? 0 : (newPage - 1) * paginationParams.rows;

    dispatch(
      updateResumePaginationParams({
        start: startNum,
        currentPage: newPage,
      })
    );
  };

  const totalPages = Math.ceil(numFound / paginationParams.rows);

  return (
    <Stack direction="row" justifyContent="center">
      <Pagination
        count={totalPages}
        page={paginationParams.currentPage}
        onChange={handlePageChange}
        variant="text"
        shape="circular"
        size="small"
        boundaryCount={isMatchLGL ? 2 : 1}
        siblingCount={0}
        sx={{
          "& .MuiPaginationItem-page": {
            fontSize: styles.fontSize9,
          },
          "& .MuiPaginationItem-page.Mui-selected": {
            bgcolor: "#406882",
            color: "#fff",
            fontSize:
              window.innerWidth <= 768 ? styles.fontSize18 : styles.fontSize11,
          },
        }}
      />
    </Stack>
  );
};

export default PaginationComponent;
