import React, { useState, useEffect, useMemo } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { themePlugin } from "@react-pdf-viewer/theme";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import axios from "axios";
import Key from "../../clientVariables.json";
import { useSelector } from "react-redux";

const PdfViewer = (props) => {
  const searchState = useSelector(
    (state) => state.searchResumeReducer?.searchParams
  );
  const [defaultPdfFile, setDefaultFile] = useState("");
  const [isFileFound, setFileFound] = useState(true);
  const [loading, setLoading] = useState(false);

  const themePluginInstance = themePlugin();

  useEffect(() => {
    if (props?.resumeDetails) {
      let fileUrl;
      // if (parseInt(props.resumeDetails?.resume_id ? props?.resumeDetails?.resume_id : props?.resumeDetails?.id) < 594500) {
      //     fileUrl = `${Key.s3_files_url}/resume_${props?.resumeDetails?.resume_id
      //         ? props?.resumeDetails?.resume_id
      //         : props?.resumeDetails?.id
      //     }/${props?.resumeDetails?.filename
      //         ? props?.resumeDetails?.filename?.split('.').slice(0, -1).join('.') + ".pdf"
      //         : props?.resumeDetails?.res_fileName?.split('.').slice(0, -1).join('.') + ".pdf"
      //     }`;
      // } else {
      fileUrl = `${Key.s3_files_url}/user_${
        props?.resumeDetails?.js_id
          ? props?.resumeDetails?.js_id
          : props?.resumeDetails?.uid
      }/resume_${
        props?.resumeDetails?.resume_id
          ? props?.resumeDetails?.resume_id
          : props?.resumeDetails?.id
      }/${
        props?.resumeDetails?.filename
          ? props?.resumeDetails?.filename?.split(".").slice(0, -1).join(".") +
            ".pdf"
          : props?.resumeDetails?.res_fileName
              ?.split(".")
              .slice(0, -1)
              .join(".") + ".pdf"
      }`;
      // }

      axios
        .get(fileUrl)
        .then((response) => {
          setDefaultFile(fileUrl);
          setFileFound(true);
        })
        .catch((error) => {
          console.error("Error fetching the PDF file:", error);
          setFileFound(false);
        });
    }
  }, [props?.resumeDetails]);

  const skillsAI =
    sessionStorage.getItem("OpenAIskillsAI")?.split("OR").join(",") || "";

  const inputString = searchState?.hl_words ? searchState?.hl_words.join(",") 
    : (searchState?.resumeTitle &&
    Object.keys(searchState.resumeTitle).length !== 0
      ? searchState.resumeTitle
      : skillsAI);

  const results = useMemo(() => {
    if (inputString && inputString.trim()) {
      const parts = decodeURIComponent(inputString)
        .split(",")
        .map((part) => part.replace(/['"]+/g, "").trim().toLowerCase());
      return parts.filter((part) => part !== "");
    }
    return [];
  }, [inputString]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [inputString]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    toolbarPlugin: {
      searchPlugin: {
        keyword: results,
        highlightKeyword: true,
        onHighlightKeyword: (props) => {
          props.highlightEle.style.backgroundColor = "rgb(255 66 0)";
        },
      },
    },
  });

  return isFileFound ? (
    <div className="pdf-container">
      {defaultPdfFile && !loading ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
          <Viewer
            fileUrl={defaultPdfFile}
            plugins={[defaultLayoutPluginInstance, themePluginInstance]}
            theme="white"
          />
        </Worker>
      ) : (
        ""
      )}
    </div>
  ) : (
    <div>Resume Not Available</div>
  );
};

export default PdfViewer;
