import React, { useEffect, useState } from "react";
import { CloseRounded } from "@mui/icons-material";
import {
  Select,
  MenuItem,
  InputAdornment,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  Typography,
  TextField,
  InputBase,
  Popover,
  Chip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useResponsiveStyles } from "../../../../Styles/responsiveStyles";
import {
  updateResumeFilterParams,
  updateResumePaginationParams,
  updateResumeSearchParams,
} from "../../../Actions/resumeSearchActions";
import { useDispatch } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.2),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.1),
  },
  color: alpha(theme.palette.common.black, 0.9),
  marginLeft: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  flexGrow: 1,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "50ch",
      "&:focus": {
        width: "50ch",
      },
    },
    "&::placeholder": {
      color: "#888",
      opacity: 1,
    },
  },
}));

const IconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  color: "#888",
  "&:hover": {
    color: "#406882",
    cursor: "pointer",
  },
}));

const AdvancedSearch = ({
  isOpen,
  aiData,
  setAdvancedSearchOpen,
  setOpenAiForm1Modal,
  setProgress,
}) => {
  const styles = useResponsiveStyles();
  const dispatch = useDispatch();
  const [anchorElInfo, setAnchorElInfo] = useState(null);
  const [searchCategory, setSearchCategory] = useState("skill");
  const [searchText, setSearchText] = useState("");
  const [chipsData, setChipsData] = useState({
    skills: [],
    titles: [],
    relatedTitles: [],
  });

  useEffect(() => {
    if (aiData) {
      //   console.log("aiData", aiData);
      setChipsData((prev) => ({
        skills: [],
        relatedTitles: [],
        titles: [aiData.job_title],
      }));
    }
  }, [aiData]);

  const handleInfoClick = (event) => {
    setAnchorElInfo(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorElInfo(null);
  };

  const handleAddChip = () => {
    // console.log(`Searching for ${searchCategory}: ${searchText}`);
    if (searchText?.trim() !== "") {
      if (searchCategory === "skill") {
        setChipsData((prev) => ({
          ...prev,
          skills: [...prev.skills, searchText],
        }));
      } else if (searchCategory === "title") {
        setChipsData((prev) => ({
          ...prev,
          titles: [...prev.titles, searchText],
        }));
      } else if (searchCategory === "related_title") {
        setChipsData((prev) => ({
          ...prev,
          relatedTitles: [...prev.relatedTitles, searchText],
        }));
      }
    }
    setSearchText("");
  };
  const handleDeleteChip = (chipToDelete) => () => {
    setChipsData((prev) => ({
      relatedTitles: prev.relatedTitles.filter((chip) => chip !== chipToDelete),
      titles: prev.titles.filter((chip) => chip !== chipToDelete),
      skills: prev.skills.filter((chip) => chip !== chipToDelete),
    }));
  };
  const handleCheckboxChange = (event, category) => {
    // console.log("cheked data", event.target.value, category);

    const { checked, value } = event.target;
    if (checked) {
      setChipsData((prev) => ({
        ...prev,
        [category]: [...prev[category], value],
      }));
    } else {
      setChipsData((prev) => ({
        ...prev,
        [category]: prev[category].filter((chip) => chip !== value),
      }));
    }
  };

  const findResumesHandler = async () => {
    await dispatch(
      updateResumeSearchParams({
        jobType: aiData.jobtype,
        state: aiData.state,
        title: chipsData.titles.join(" OR "),
        visaType: aiData.visatype,
        skills: chipsData.skills,
        relatedTitles: chipsData.relatedTitles,
        experience: aiData.experience,
        hl_words: [
          ...chipsData.titles,
          ...chipsData.skills,
          ...chipsData.relatedTitles,
        ],
      })
    );
    await dispatch(
      updateResumeFilterParams({
        city: "*:*",
        jobtype: "*:*",
        lastmodified: null,
        state: "*:*",
        total_experience: "*:*",
        visatype: "*:*",
      })
    );
    await dispatch(
      updateResumePaginationParams({
        start: 0,
        rows: 10,
        currentPage: 1,
        // , init:1
      })
    );
    setAdvancedSearchOpen(false);
  };

  const cancelHandler = () => {
    setAdvancedSearchOpen(false);
    setProgress(0);
    setOpenAiForm1Modal(true);
  };

  const idInfo = anchorElInfo ? "info-popover" : undefined;
  const openInfo = Boolean(anchorElInfo);

  return (
    <Box>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component={Card} sx={AiForm1ModalStyles}>
          {/* <CardHeader
            title={
              <h2
                className=""
                style={{
                  fontSize: false ? "17px" : "19px",
                  color: "#406882",
                  fontWeight: 600,
                }}
              >
                Advanced Search
              </h2>
            }
            action={
              <IconButton
              // onClick={handleCloseSearchHandler}
              >
                <CloseRounded />
              </IconButton>
            }
          /> */}
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Search>
                  <Select
                    size="small"
                    value={searchCategory}
                    onChange={(e) => setSearchCategory(e.target.value)}
                    variant="outlined"
                    sx={{
                      height: "30px",
                      marginRight: "4px",
                      marginLeft: "8px",
                      //   backgroundColor: 'primary',
                      border: "1px solid #1976D2",
                    }}
                  >
                    <MenuItem value="skill">Skill</MenuItem>
                    <MenuItem value="title">Title</MenuItem>
                    <MenuItem value="related_title">Related Title</MenuItem>
                  </Select>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder={`Enter ${searchCategory} to Add...`}
                    inputProps={{ "aria-label": "search" }}
                    value={searchText}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Capitalize the first letter and keep the rest as is
                      const capitalizedValue =
                        value.charAt(0).toUpperCase() + value.slice(1);
                      setSearchText(capitalizedValue);
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter" &&
                      searchText?.trim() !== "" &&
                      handleAddChip()
                    }
                  />
                  {/* <IconWrapper onClick={handleInfoClick}>
        <InfoOutlined />
      </IconWrapper> */}
                  <IconWrapper>
                    <Button
                      variant="contained"
                      disableElevation
                      size="small"
                      color="primary"
                      onMouseDown={handleAddChip}
                      sx={{ paddingInline: "7px", fontSize: "12px" }}
                    >
                      Add
                    </Button>
                  </IconWrapper>
                  <Popover
                    id={idInfo}
                    open={openInfo}
                    anchorEl={anchorElInfo}
                    onClose={handleInfoClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <Typography sx={{ p: "6px" }}>Eg:</Typography>
                      <Typography sx={{ p: "2px" }}>
                        1. Looking for a Java developer in California with
                        fulltime and H1B visa
                      </Typography>
                      {/* Add more example search queries */}
                    </Box>
                  </Popover>
                </Search>
              </Grid>
              <Grid
                item
                container
                spacing={0}
                xs={12}
                sx={{ height: 500, overflow: "scroll" }}
              >
                <Grid item xs={12}>
                  {chipsData.skills.length > 0 && (
                    <Box>
                      <Typography
                        paragraph
                        sx={{
                          fontSize: styles.fontSize12,
                          letterSpacing: "0.1rem",
                          color: "#576375",
                          fontWeight: 600,
                          m: 0,
                        }}
                      >
                        Skills:
                      </Typography>
                      {chipsData.skills.map((chip, index) => (
                        <Chip
                          size="small"
                          key={index}
                          label={chip}
                          onDelete={handleDeleteChip(chip)}
                          style={{
                            marginRight: "5px",
                            marginBottom: "5px",
                            fontSize: "9px",
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  {chipsData.titles.length > 0 && (
                    <Box>
                      <Typography
                        paragraph
                        sx={{
                          fontSize: "12px",
                          letterSpacing: "0.1rem",
                          color: "#576375",
                          fontWeight: 600,
                          m: 0,
                        }}
                      >
                        Primary Titles:
                      </Typography>
                      {chipsData.titles.map((chip, index) => (
                        <Chip
                          size="small"
                          key={index}
                          label={chip}
                          onDelete={handleDeleteChip(chip, "titles")}
                          style={{
                            marginRight: "5px",
                            marginBottom: "5px",
                            fontSize: "9px",
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  {chipsData.relatedTitles.length > 0 && (
                    <Box>
                      <Typography
                        paragraph
                        sx={{
                          fontSize: "12px",
                          letterSpacing: "0.1rem",
                          color: "#576375",
                          fontWeight: 600,
                          m: 0,
                        }}
                      >
                        Related Titles:
                      </Typography>
                      {chipsData.relatedTitles.map((chip, index) => (
                        <Chip
                          size="small"
                          key={index}
                          label={chip}
                          onDelete={handleDeleteChip(chip, "relatedTitles")}
                          style={{
                            marginRight: "5px",
                            marginBottom: "5px",
                            fontSize: "9px",
                          }}
                        />
                      ))}
                    </Box>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography component="body" sx={subTitleStyles}>
                    Skills :
                  </Typography>
                  <Box sx={{ height: "300px", overflow: "scroll" }}>
                    <FormGroup>
                      {aiData &&
                        aiData.skills &&
                        aiData.skills.map((skill) => (
                          <FormControlLabel
                            key={skill}
                            sx={checkBoxLabelStyles}
                            control={
                              <Checkbox
                                sx={checkBoxStyles}
                                value={skill}
                                checked={chipsData.skills.includes(skill)}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "skills")
                                }
                              />
                            }
                            label={skill}
                          />
                        ))}
                    </FormGroup>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography component="body" sx={subTitleStyles}>
                    Related Titles :
                  </Typography>
                  {/* <FormGroup>
                  {aiData &&
                    aiData?.job_roles &&
                    aiData.job_roles.map((title) => (
                      <FormControlLabel
                        sx={checkBoxLabelStyles}
                        control={<Checkbox sx={checkBoxStyles}
                        onChange={(e) => handleCheckboxChange(e, "related_titles")} />}
                        label={title}
                      />
                    ))}
                </FormGroup> */}
                  <Box sx={{ height: "300px", overflow: "scroll" }}>
                    <FormGroup>
                      {aiData &&
                        aiData.job_roles &&
                        aiData.job_roles.map((title) => (
                          <FormControlLabel
                            key={title}
                            sx={checkBoxLabelStyles}
                            control={
                              <Checkbox
                                sx={checkBoxStyles}
                                value={title}
                                checked={chipsData.relatedTitles.includes(
                                  title
                                )}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "relatedTitles")
                                }
                              />
                            }
                            label={title}
                          />
                        ))}
                    </FormGroup>
                  </Box>
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
                
              </Grid> */}
            </Grid>
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "6px",
                  position: "fixed",
                  bottom: 15,
                  right: 15,
                  bgcolor: "#fff",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{ textTransform: "capitalize" }}
                  onClick={cancelHandler}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={findResumesHandler}
                  sx={{ textTransform: "capitalize" }}
                >
                  Find Resumes
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Modal>
    </Box>
  );
};

export default AdvancedSearch;

const AiForm1ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: "75%",
  overFlow: "scroll",
  bgcolor: "background.paper",
  border: "1px solid #ddd",
  boxShadow: 24,
  // p: 4,
  borderRadius: "8px",
};

const subTitleStyles = {
  fontWeight: 600,
  color: "#406882",
  fontSize: "14px",
};

const checkBoxStyles = {
  "& .MuiSvgIcon-root": {
    fontSize: 16,
  },
};

const checkBoxLabelStyles = {
  "& .MuiFormControlLabel-label": {
    fontSize: "12px",
  },
  margin: 0,
};
