import React, { Component } from "react";
import Swal from "sweetalert2";
import UserActions from '../controllers/user.controller'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class SessionAlert extends Component {

  constructor(props) {
    super(props);
    this.HandleClick = this.HandleClick.bind(this);
    this.Handlelogout = this.Handlelogout.bind(this);

  }

  componentDidMount() {
    this.HandleClick1()
  }

  HandleClick() {
    Swal.fire({
      title: 'Success',
      type: 'success',
      text: 'Your work has been saved.',
    });
  }
  Handlelogout =()=> {
    this.props.logout()
  }
  HandleClick1 =() =>{
    Swal.fire({
      title: 'Session Was Expired ',
      text: 'Please Login Again',
      icon: 'warning',
      //  showCancelButton: true,
      confirmButtonColor: '#406882',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Please Login!'
    }).then((res)=> {
      if (res.isConfirmed) {
        console.log(this)
        sessionStorage.clear()
        localStorage.clear()
        this.props.history.push({
          pathname: '/Login',
        })
        window.location.reload()
        //this.Handlelogout()
      }

    }
    )
  }

  HandleClicktop() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 1500
    });
  }
  HandleClickAutoclose() {
    let timerInterval
    Swal.fire({
      title: 'Auto close alert!',
      html: 'I will close in <b></b> milliseconds.',
      timer: 1000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })
  }


  render() {
    return (
      <div>

      </div>
    );
  }

}


const mapDispatchToProps = function (dispatch) {
  return {
    logout: () => {
      UserActions.logout()(dispatch);
    }
  }
};
export default withRouter(connect(null, mapDispatchToProps)(SessionAlert));
