import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchResumeResults,
  fetchResumeResultsV1,
  jsResumeDetails,
  setSelectedResume,
  updateResumeFilterParams,
  updateResumePaginationParams,
  updateResumeSearchParams,
} from "../../../Actions/resumeSearchActions";
import {
  buildFacetQuery,
  resumeQueryBuilder,
} from "../../../../utils/resumeQueryBuilder";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Stack,
  Card,
  useMediaQuery,
  useTheme,
  Badge,
  Modal,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import {
  Avatar,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Tooltip,
  MuiTooltip,
  Toolbar,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import {
  AddRounded,
  CloseRounded,
  EditRounded,
  FilterListRounded,
} from "@mui/icons-material";
import { useResponsiveStyles } from "../../../../Styles/responsiveStyles";
import palette from "../../../../theme/palatte";
import DetailsView from "./DetailsView";
import Key from "../../../../clientVariables.json";
import { getResumeLocation } from "../../../../utils/getLocation";
import PaginationComponent from "./components/PaginationComponent";
import RowSelect from "./components/RowSelect";
import { getSolrFilterCountsByURL } from "../../../../utils/facetQueryBuilder";
import SessionAlert from "../../../SessionAlert";
import Highlighter from "react-highlight-words";
import {
  job_types_data,
  us_states_data,
  visa_types_data,
} from "../../../../utils/commonData";
import { hideLoader, showLoader } from "../../../Actions/loader.actions";
import { useLocation } from "react-router-dom";
import { openAIExtracteAISearchValues } from "../ai/extractSearchValuesAI";
import AdvancedSearch from "../ai/AdvancedSearch";
import SearchIcon from "@mui/icons-material/Search";
import ModifySearch from "../ai/ModifySearch";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const job_types = [
  {
    id: 8,
    title: "Need H1B",
  },
  {
    id: 9,
    title: "Hourly Employee",
  },
  {
    id: 15,
    title: "Contract to Hire - W2",
  },
  {
    id: 14,
    title: "Contract to Hire - Corp-to-Corp",
  },
  {
    id: 13,
    title: "Contract - Independent",
  },
  {
    id: 11,
    title: "Contract - Corp-to-Corp",
  },
  {
    id: 1,
    title: "Full-Time",
  },
  {
    id: 16,
    title: "Contract to Hire - Independent",
  },
  {
    id: 2,
    title: "Part-Time",
  },
  {
    id: 3,
    title: "Internship",
  },
  {
    id: 4,
    title: "W2 - Permanent",
  },
  {
    id: 5,
    title: "W2 - Contract",
  },
  {
    id: 6,
    title: "1099-Contract",
  },
  {
    id: 7,
    title: "Contract to Hire",
  },
  {
    id: 10,
    title: "Contract Based",
  },
];

const ResumeMain = () => {
  const styles = useResponsiveStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const currentUser = useSelector((state) => state.UserReducer.user);
  const searchState = useSelector((state) => state.searchResumeReducer);
  const {
    searchParams = {},
    filterParams = {},
    paginationParams = {},
    results = [],
    numFound,
    facet_counts,
    selectedResume = null,
    selectedResumeDetails = null,
    error,
  } = searchState || {};
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState(0);
  const [activeButton, setActiveButton] = useState("All");
  const [selectedJobTypeButtons, setSelectedJobTypeButtons] = useState([]);
  const [jobTypesData, setJobTypesData] = useState(job_types);
  const [visaTypesData, setVisaTypesData] = useState([]);
  const [hover, setHover] = useState(false);
  const [resumepackageExpired, setResumePackageExpired] = useState(false);
  const [resumeViewsLeft, setResumeViewsLeft] = useState(0);
  const [allResumeViews, setAllResumeViews] = useState([]);
  const [packageExpiredOpen, setPackageExpiredOpen] = useState(false);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState({
    jobType: [],
    visaType: [],
    exp: [],
    date: "",
    state: [],
    city: [],
  });
  const [totalCounts, setTotalCounts] = useState({
    jobTypeCount: [],
    visatypeCount: [],
    totalExpCount: null,
    stateCount: [],
    cityCount: [],
  });
  const [alert, setAlert] = useState(false);
  const [filtersCount, setFiltersCount] = useState({
    jobTypeCount: 0,
    visatypeCount: 0,
    totalExpCount: 0,
    postedDate: 0,
    total: 0,
  });
  const [cityList, setCityList] = useState([]);
  const [initialFacetCounts, setInitialFacetCounts] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const location = useLocation();
  const [openAiForm1Modal, setOpenAiForm1Modal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [openAImessage, setOpenAImessage] = useState("");
  const [aiData, setAIData] = useState(null);
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [modifySearch, setModifySearch] = useState(false);
  const progressRef = useRef(() => {});

  const previousSearchParams = useRef(searchParams);
  const previousFilterParams = useRef(filterParams);
  const previousPaginationParams = useRef(paginationParams);

  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(0);
  // const [rowsPerPage, setRowPerPage] = useState(10);
  // const [pageStartNum, setPageStartNum] = useState(0);

  const handleCloseAlert = () => {
    setPackageExpiredOpen(false);
  };

  const handleOpenAiForm1Modal = () => {
    setOpenAiForm1Modal(true);
  };

  useEffect(() => {
    // location.pathname
    if (location.pathname === "/employer/ai-search") {
      // handleOpenAiForm1Modal();
    }
  }, []);

  useEffect(() => {
    progressRef.current = () => {
      if (progress === 0) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  }, [progress]);

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  useEffect(() => {
    getAllJobTypes();
    getAllVisaTypes();
    checkEmployerLimit();
    getAllResumeViews();

    const facetEx = async (query) => {
      const solrBaseUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=*:*&rows=10&start=0&fq=search_flag:(1)&wt=json`;
      // const solrBaseUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=${query}&rows=10&start=0&fq=search_flag:(1)&wt=json`;
      // const query = '&facet=true&facet.pivot=location_state,jobtype';
      try {
        const response = await axios.post(
          `${Key.domain}/solr/solr_resumes`,
          {
            url: `${solrBaseUrl}${query}`,
            // url: `${solrBaseUrl}`,
          }
          //   {
          //     url: "https://solr.jobsnprofiles.com/solr/jnp_resumes_v2/select?defType=edismax&q=\"frontend developer\"&qf=application_title^5.0 summary^2.0&fq=location_state:1452&fq=jobtype:1&facet=true&facet.field=location_state&facet.field=jobtype&facet.field=visatype&sort=created desc&hl=true&hl.fl=application_title&hl.simple.pre=<em>&hl.simple.post=</em>&rows=10&start=0&fq=search_flag:(1)&wt=json"
          // }
          // {
          //   "url": "https://solr.jobsnprofiles.com/solr/jnp_resumes_v2/select?q=*:*&rows=10&start=0&fq=search_flag:(1)&wt=json&fq=application_title:(python OR aws)&fq=location_state:(1407 OR 1452 OR 1503)&fq=jobtype:(1 OR 2 OR 3)&facet=true&facet.pivot=location_state,jobtype,visatype&facet.sort=count"
          // }
        );
        // console.log("facetEx", response);
      } catch (error) {
        console.error(error);
      }
    };

    // http://localhost:8983/solr/your_core/select?q=frontend+developer&fq=location_state:1452&fq=jobtype:1&facet=true&facet.field=location_state&facet.field=jobtype&facet.field=visatype&sort=created desc&hl=true&hl.fl=summary

    //    const startDate = moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    // const endDate = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    const startDate =
      moment
        .utc()
        .subtract(30, "days")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss") + "Z";
    const endDate =
      moment.utc().endOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z";
    // console.log("facetEx date", startDate, endDate);

    const experienceStart = 2;
    const experienceEnd = 5;
    //  facetEx('&fq=application_title:(python OR aws)&fq=location_state:(1407 1452)&fq=jobtype:(1 3)&facet=true&facet.pivot=location_state,jobtype,visatype&facet.pivot=location_state,jobtype,total_experience&facet.field=jobtype&facet.field=visatype&facet.field=total_experience&facet.range=total_experience&facet.range.start=0&facet.range.end=100&facet.range.gap=2&facet.mincount=1&facet.sort=count');

    // facetEx(`&fq=application_title:(python OR aws)&fq=location_state:(1407 1452)&fq=jobtype:(1 3)&facet=true&facet.pivot=location_state,jobtype,visatype&facet.pivot=location_state,jobtype,total_experience&facet.field=jobtype&facet.field=visatype&facet.field=total_experience&facet.range=lastmodified&facet.range.start=${startDate}&facet.range.end=${endDate}&facet.range.gap=%2B1DAY&facet.mincount=1&facet.sort=count`);

    // facetEx(`&fq=application_title:(python OR aws)&fq=location_state:(1407 1452)&fq=jobtype:(1 3)&facet=true&facet.field=jobtype&facet.field=visatype&facet.field=total_experience&facet.range=lastmodified&facet.range.start=${startDate}&facet.range.end=${endDate}&facet.range.gap=%2B1DAY&facet.range=total_experience&facet.range.start=0.0&facet.range.end=75.0&facet.range.gap=5.0&facet.mincount=1&facet.sort=count`);

    //  facetEx('&fq=application_title:(python OR aws)&fq=location_state:(1407 1452)&facet=true&facet.pivot=location_state,jobtype&facet.pivot=location_state,visatype&facet.pivot=location_state,total_experience&facet.sort=count&fq=jobtype:(1 3)&fq=total_experience:(5 6 7)');
    //  facetEx('&facet=true&facet.field=jobtype&facet.sort=index');
    //  facetEx('&facet=true&facet.query=total_experience:[5 TO *]');
    //  facetEx('&facet=true&facet.date=created&facet.date.start=2020-01-01T00:00:00Z&facet.date.end=2024-12-31T23:59:59Z&facet.date.gap=%2B1YEAR');

    //  facetEx('&fq=application_title:(python OR aws)&fq=location_state:(1407 OR 1452)&fq=jobtype:(1 OR 2 OR 3)&facet=true&facet.field=location_state&facet.field=jobtype&facet.field=visatype&facet.sort=count&sort=created desc&hl=true&hl.fl=application_title');
    //  facetEx("defType=edismax&q='frontend developer'&qf=application_title^5.0 summary^2.0&fq=location_state:1452&fq=jobtype:1&facet=true&facet.field=location_state&facet.field=jobtype&facet.field=visatype&sort=created desc&hl=true&hl.fl=application_title&hl.simple.pre=<em>&hl.simple.post=</em>")
  }, []);

  useEffect(() => {
    const fetchJobTypeCounts = async () => {
      try {
        const response = facet_counts.facet_fields.jobtype;
        const singleIdCounts = [];
        for (let i = 0; i < response.length; i += 2) {
          const jobTypeId = response[i];
          const count = response[i + 1];
          if (!jobTypeId.includes(",")) {
            singleIdCounts.push(jobTypeId, count);
          }
        }

        const counts = await mapDataToTitles(singleIdCounts, job_types_data);
        // console.log("singleIdCounts", singleIdCounts, counts);
        setTotalCounts((prev) => ({
          ...prev,
          jobTypeCount: counts,
        }));
      } catch (error) {
        console.error("Error fetching job type data:", error);
      }
    };

    const fetchVisaTypeCounts = async () => {
      try {
        const response = facet_counts.facet_fields.visatype;
        const singleIdCounts = [];
        for (let i = 0; i < response.length; i += 2) {
          const visaTypeId = response[i];
          const count = response[i + 1];
          if (!visaTypeId.includes(",")) {
            singleIdCounts.push(visaTypeId, count);
          }
        }
        const counts = await mapDataToTitles(singleIdCounts, visa_types_data);

        setTotalCounts((prev) => ({
          ...prev,
          visatypeCount: counts,
        }));
      } catch (error) {
        console.error("Error fetching visa type data:", error);
      }
    };

    const fetchStateCounts = async () => {
      try {
        const response = await facet_counts.facet_fields.location_state;
        const counts = await mapDataToStates(response, us_states_data);

        setTotalCounts((prev) => ({
          ...prev,
          stateCount: counts,
        }));
      } catch (error) {
        console.error("Error fetching visa type data:", error);
      }
    };
    const fetchCityCounts = async () => {
      try {
        const response = await facet_counts.facet_pivot[
          "location_state,address_city"
        ][0].pivot;
        const counts = await mapDataToCities(response);
        // console.log(
        //   "facet_counts.facet_pivot['location_state,address_city'][0].pivot",
        //   counts,
        //   response
        // );
        // console.log("city counts", counts);

        setTotalCounts((prev) => ({
          ...prev,
          cityCount: counts,
        }));
      } catch (error) {
        console.error("Error fetching visa type data:", error);
      }
    };

    if (value === 0 && jobTypesData) {
      fetchJobTypeCounts();
    } else if (value === 1 && visaTypesData) {
      fetchVisaTypeCounts();
    } else if (
      value === 2
      // && filterParams.total_experience === "*:*"
    ) {
      // fetchExperienceCounts();
      if (facet_counts.facet_fields.total_experience) {
        const result = groupExperienceCounts(
          facet_counts.facet_fields.total_experience
        );
        setTotalCounts((prev) => ({
          ...prev,
          totalExpCount: result,
        }));
      }
    } else if (value === 5) {
      fetchStateCounts();
    } else if (value === 6) {
      fetchCityCounts();
    }
  }, [value, facet_counts, dispatch]);

  useEffect(() => {
    let jobTypesFilters = 0;
    let visaTypesFilters = 0;
    let experienceFilters = 0;
    let postedDateFilters = 0;

    if (filterParams) {
      jobTypesFilters =
        filterParams?.jobtype && filterParams?.jobtype !== "*:*"
          ? filterParams.jobtype?.split(" OR ").length
          : 0;
      visaTypesFilters =
        filterParams?.visatype && filterParams?.visatype !== "*:*"
          ? filterParams.visatype?.split(" OR ").length
          : 0;
      // experienceFilters =
      //   filterParams?.total_experience &&
      //   filterParams?.total_experience !== "*:*"
      //     ? filterParams.total_experience?.split(" OR ").length
      //     : 0;
      postedDateFilters = filterParams?.lastmodified ? 1 : 0;
    }

    const total =
      jobTypesFilters +
      visaTypesFilters +
      // experienceFilters +
      postedDateFilters;

    // setFiltersCount((prev) => ({
    //   ...prev,
    //   total,
    //   visatypeCount: visaTypesFilters,
    //   jobTypeCount: jobTypesFilters,
    //   totalExpCount: experienceFilters,
    //   postedDate: postedDateFilters,
    // }));

    dispatch(
      updateResumePaginationParams({
        start: 0,
        currentPage: 1,
      })
    );


  }, [filterParams]);

  useEffect(() => {
    // const solrBaseUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=(*:*)`;
    //   let query = "";
    //   const jobTypeID = [];
    //   const visaTypeID = [];
    //   const stateID = [];
    //   job_types_data.forEach((data) => {
    //     if (data.title.includes(searchParams.jobType)) {
    //       jobTypeID.push(data.id);
    //     }
    //   });
    //   visa_types_data.forEach((data) => {
    //     if (data.type_code.includes(searchParams.visaType)) {
    //       visaTypeID.push(data.id);
    //     }
    //   });
    //   us_states_data.forEach((data) => {
    //     if (data.name.includes(searchParams.state)) {
    //       stateID.push(data.id);
    //     }
    //   });
    //   // console.log("jobTypeID", jobTypeID, visaTypeID);
    //   if (searchParams.title) {
    //     query += `&fq=application_title:("${searchParams.title}")`;
    //     // console.log(query);
    //   }
    //   if (stateID.length > 0) {
    //     query += `&fq=location_state:(${stateID.join(" OR ")})`;
    //     // console.log(query);
    //   }
    //   // if (jobTypeID.length > 0) {
    //   //   query += `&fq=jobtype:(${jobTypeID.join(" OR ")})`;
    //   //   // console.log(query);
    //   // }
    //   // if (visaTypeID.length > 0) {
    //   //   query += `&fq=visatype:(${visaTypeID.join(" OR ")})`;
    //   //   // console.log(query);
    //   // }
    //   query += `&facet=true&facet.pivot=location_state,address_city&facet.field=location_state&facet.field=jobtype&facet.field=visatype&facet.field=total_experience&facet.mincount=1&facet.sort=count`;
    //   dispatch(fetchResumeResultsV1(`${solrBaseUrl}${query}&rows=10&start=0&fq=search_flag:(1)&sort=lastmodified desc&wt=json`))
    //   .then(() => {
    //     console.log("Data fetched successfully");
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //   });
    // fetchResumes(query)
  }, [searchParams]);

  useEffect(() => {
    previousSearchParams.current = searchParams;
    previousFilterParams.current = filterParams;
    previousPaginationParams.current = paginationParams;
  }, [searchParams, filterParams, paginationParams]);

  useEffect(() => {
    // const fetchData = async () => {
    //   const query =await buildFacetQuery(
    //     filterParams,
    //     searchParams,
    //     paginationParams,
    //     facet_counts
    //   );

    //   try {
    //     await dispatch(fetchResumeResultsV1(query));
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };
    const fetchData = () => {
      const query = buildFacetQuery(
        filterParams,
        searchParams,
        paginationParams,
        facet_counts
      );
      // console.log(
      //   "Check99",
      //   filterParams,
      //   searchParams,
      //   paginationParams,
      //   facet_counts,
      //   query
      // );

      dispatch(fetchResumeResultsV1(query))
        .then(() => {
          console.log("Data fetched successfully");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    // fetchData();
    // if (initialLoad 
    //   // && location.pathname !== "/employer/ai-search"
    // ) {
    //   // fetchData();
    //   // setInitialLoad(false);
    //   return;
    // }

    if (
      searchParams.title !== previousSearchParams.title ||
      searchParams.state !== previousSearchParams.state ||
      searchParams.jobType !== previousSearchParams.jobType ||
      searchParams.visaType !== previousSearchParams.visaType ||
      searchParams.email !== previousSearchParams.email ||
      searchParams.id !== previousSearchParams.id ||
      searchParams.skills !== previousSearchParams.skills ||
      searchParams.relatedTitles !== previousSearchParams.relatedTitles ||
      searchParams.experience !== previousSearchParams.experience ||
      filterParams.jobtype !== previousFilterParams.jobtype ||
      filterParams.visatype !== previousFilterParams.visatype ||
      filterParams.state !== previousFilterParams.state ||
      filterParams.city !== previousFilterParams.city ||
      filterParams.total_experience !== previousFilterParams.total_experience ||
      filterParams.lastmodified !== previousFilterParams.lastmodified ||
      paginationParams.start !== previousPaginationParams.start ||
      paginationParams.rows !== previousPaginationParams.rows
    ) {
      fetchData();
    }
  }, [
    searchParams.title,
    searchParams.state,
    searchParams.jobType,
    searchParams.visaType,
    searchParams.email,
    searchParams.id,
    searchParams.skills,
    searchParams.relatedTitles,
    searchParams.experience,
    filterParams.jobtype,
    filterParams.visatype,
    filterParams.state,
    filterParams.city,
    filterParams.total_experience,
    filterParams.lastmodified,
    paginationParams.start,
    paginationParams.rows,
    paginationParams.currentPage,
  ]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     console.log(filterParams, searchParams, paginationParams);
  //     const query = buildFacetQuery(
  //       filterParams,
  //       searchParams,
  //       paginationParams,
  //       facet_counts
  //     );
  //     console.log(query);

  //     try {
  //       dispatch(fetchResumeResultsV1(query));
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   if (initialLoad) {
  //     fetchData();
  //     setInitialLoad(false);
  //   }
  //   // fetchData();
  // }, [searchParams]);
  // useEffect(() => {
  //   // if (!initialLoad) {
  //     const fetchData = async () => {
  //       const query = buildFacetQuery(
  //         filterParams,
  //         searchParams,
  //         paginationParams,
  //         facet_counts
  //       );
  //       try {
  //         dispatch(fetchResumeResultsV1(query));
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     };

  //   if (!initialLoad) {
  //     fetchData();
  //   }
  //   // }
  // }, [
  //   searchParams,
  //   filterParams.jobtype,
  //   filterParams.visatype,
  //   filterParams.state,
  //   filterParams.jobtype,
  //   filterParams.city,
  //   filterParams.total_experience,
  //   filterParams.lastmodified,
  //   paginationParams.start,
  //   paginationParams.rows,
  // ]);

  useEffect(() => {
    const fetchCities = async () => {
      // setTotalCounts((prev) => ({
      //   ...prev,
      //   cityCount: [],
      // }));
      try {
        if (searchParams?.state) {
          // const matchingState = us_states_data.find((data) =>
          //   data.name.includes(searchParams.state) || data.shortRegion.includes(searchParams.state)
          // );
          const matchingState = us_states_data.find(
            (data) =>
              data.name
                .toLowerCase()
                .includes(searchParams.state.toLowerCase()) ||
              data.shortRegion
                .toLowerCase()
                .includes(searchParams.state.toLowerCase())
          );
          // console.log('matchingState', matchingState);

          if (matchingState) {
            const cities = await getCitiesByStateId(matchingState.id);
            setCityList(cities);
          }
        } else if (filterParams?.state && filterParams?.state !== "*:*") {
          let citiesByMultiStates = [];

          for (const state of filterParams.state.split(" OR ")) {
            const matchingState = us_states_data.find(
              (data) => data.id === parseInt(state)
            );
            if (matchingState) {
              // console.log(matchingState);

              const cities = await getCitiesByStateId(matchingState.id);
              citiesByMultiStates = [...citiesByMultiStates, ...cities];
            }
          }
          setCityList(citiesByMultiStates);
          // console.log("citiesByMultiStates", citiesByMultiStates);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, [searchParams.state, filterParams.state]);

  useEffect(() => {
    const { start, rows, init, currentPage } = paginationParams;

    // console.log("Pagination params:", { start, rows, init, currentPage });

    if (
      start !== 0 ||
      init === 0 ||
      (start === 0 && rows > 0 && currentPage >= 1)
    ) {
      // console.log("Fetching resume results...");
      // dispatch(fetchResumeResults());
    }
  }, [
    paginationParams.start,
    paginationParams.rows,
    paginationParams.init,
    paginationParams.currentPage,
    dispatch,
  ]);
  useEffect(() => {
    const jobTypesParams =
      selectedFilterValues.jobType.length > 0
        ? jobTypesData
            .filter((data) => selectedFilterValues.jobType.includes(data.title))
            .map((data) => data.id)
            .join(" OR ")
        : "*:*";

    dispatch(updateResumeFilterParams({ jobtype: jobTypesParams }));
  }, [selectedFilterValues.jobType, jobTypesData, dispatch]);

  useEffect(() => {
    const visaTypeParams =
      selectedFilterValues.visaType.length > 0
        ? visaTypesData
            .filter((data) =>
              selectedFilterValues.visaType.includes(data.type_code)
            )
            .map((data) => data.id)
            .join(" OR ")
        : "*:*";

    dispatch(updateResumeFilterParams({ visatype: visaTypeParams }));
  }, [selectedFilterValues.visaType, visaTypesData, dispatch]);

  useEffect(() => {
    const stateParams =
      selectedFilterValues.state.length > 0
        ? us_states_data
            .filter((data) => selectedFilterValues.state.includes(data.name))
            .map((data) => data.id)
            .join(" OR ")
        : "*:*";

    dispatch(updateResumeFilterParams({ state: stateParams }));
  }, [selectedFilterValues.state, dispatch]);

  useEffect(() => {
    const cityParams =
      selectedFilterValues.city.length > 0
        ? cityList
            .filter((data) => selectedFilterValues.city.includes(data.cityName))
            .map((data) => data.id)
            .join(" OR ")
        : "*:*";

    dispatch(updateResumeFilterParams({ city: cityParams }));
  }, [selectedFilterValues.city, dispatch]);

  useEffect(() => {
    // const expParams = selectedFilterValues.exp.flatMap((data) => {
    //   if (data === "0 - 3") return ["0", "1", "2", "3"];
    //   if (data === "4 - 6") return ["4", "5", "6"];
    //   if (data === "7 - 10") return ["7", "8", "9", "10"];
    //   if (data === "above 10")
    //     return Array.from({ length: 31 - 10 }, (_, i) => (i + 11).toString());
    //   return [];
    // });
    // const expParams = selectedFilterValues.exp.flatMap((data) => {
    //   switch (data) {
    //     case "0-3":
    //       return ["0", "1", "2", "3"];
    //     case "3-6":
    //       return ["4", "5", "6"];
    //     case "6-9":
    //       return ["6", "7", "8", "9"];
    //     case "9-12":
    //       return ["9", "10", "11", "12"];
    //     case "12-15":
    //       return ["12", "13", "14", "15"];
    //     case "15-18":
    //       return ["15", "16", "17", "18"];
    //     case "18-21":
    //       return ["18", "19", "20", "21"];
    //     case "21-24":
    //       return ["21", "22", "23", "24"];
    //     case "24-27":
    //       return ["24", "25", "26", "27"];
    //     case "27+":
    //       return Array.from({ length: 81 - 26 }, (_, i) => (i + 26).toString());
    //     default:
    //       return [];
    //   }
    // });
    const expParams = selectedFilterValues.exp.flatMap((data) => {
      switch (data) {
        case "0-3":
          return "0 TO 3.9";
        case "4-6":
          return "4 TO 6.9";
        case "7-9":
          return "7 TO 9.9";
        case "10-12":
          return "10 TO 12.9";
        case "13-15":
          return "13 TO 15.9";
        case "16-18":
          return "16 TO 18.9";
        case "19-21":
          return "19 TO 21.9";
        case "22-24":
          return "22 TO 24.9";
        case "25-27":
          return "25 TO 27.9";
        case "27+":
          return "28 TO 81";
        default:
          return [];
      }
    });

    const expParamsString = expParams.length > 0 ? expParams : "*:*";
    dispatch(updateResumeFilterParams({ total_experience: expParamsString }));
  }, [selectedFilterValues.exp, dispatch]);

  useEffect(() => {
    const dateMap = {
      All: null,
      Day: 1,
      Week: 2,
      Month: 3,
    };

    dispatch(
      updateResumeFilterParams({
        lastmodified: dateMap[selectedFilterValues.date],
      })
    );
  }, [selectedFilterValues.date, dispatch]);

  // const fetchResumes = async (query) => {
  //   const { start, rows, init, currentPage } = paginationParams;

  //   const solrBaseUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=*:*&rows=${rows}&start=${start}&fq=search_flag:(1)&sort=lastmodified desc&wt=json`;
  //   // const solrBaseUrl = `${Key.Solr_url}/${Key.solrResumes_collection}/select?q=${query}&rows=10&start=0&fq=search_flag:(1)&wt=json`;
  //   // const query = '&facet=true&facet.pivot=location_state,jobtype';
  //   try {
  //     dispatch(fetchResumeResultsV1(`${solrBaseUrl}${query}`));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleButtonClick = (buttonName, filterName) => {
    setActiveButton(buttonName);
    // dispatch(
    //   updateResumePaginationParams({
    //     start: 0,
    //     currentPage: 1,
    //   })
    // );
    if (filterName === "jobtype") {
      setSelectedFilterValues((prev) => {
        const newJobType = prev.jobType.includes(buttonName)
          ? prev.jobType.filter((name) => name !== buttonName)
          : [...prev.jobType, buttonName];
        return { ...prev, jobType: newJobType };
      });
    } else if (filterName === "visaType") {
      setSelectedFilterValues((prev) => {
        const newVisaType = prev.visaType.includes(buttonName)
          ? prev.visaType.filter((name) => name !== buttonName)
          : [...prev.visaType, buttonName];
        return { ...prev, visaType: newVisaType };
      });
    } else if (filterName === "exp") {
      setSelectedFilterValues((prev) => {
        const newExp = prev.exp.includes(buttonName)
          ? prev.exp.filter((name) => name !== buttonName)
          : [...prev.exp, buttonName];
        return { ...prev, exp: newExp };
      });
      // setSelectedFilterValues((prev) => ({ ...prev, exp: buttonName }));
    } else if (filterName === "state") {
      setSelectedFilterValues((prev) => {
        const newState = prev.state.includes(buttonName)
          ? prev.state.filter((name) => name !== buttonName)
          : [...prev.state, buttonName];
        return { ...prev, state: newState };
      });
    } else if (filterName === "city") {
      setSelectedFilterValues((prev) => {
        const newCity = prev.city.includes(buttonName)
          ? prev.city.filter((name) => name !== buttonName)
          : [...prev.city, buttonName];
        return { ...prev, city: newCity };
      });
    } else if (filterName === "date") {
      setSelectedFilterValues((prev) => ({ ...prev, date: buttonName }));
    }
  };

  const mapDataToTitles = (data, titles) => {
    const counts = {};

    // for (let i = 0; i < data.length; i += 2) {
    //   const ids = data[i].toString().split(",");
    //   const count = data[i + 1];

    //   ids.forEach((id) => {
    //     counts[id] = (counts[id] || 0) + count;
    //   });
    // }
    for (let i = 0; i < data.length; i += 2) {
      const id = data[i];
      const count = data[i + 1];
      counts[id] = count;
    }
    return titles
      .map((titleObj) => ({
        title: titleObj.title || titleObj.type_code,
        count: counts[titleObj.id] || 0,
      }))
      .sort((a, b) => b.count - a.count);
  };
  const mapDataToStates = (data, titles) => {
    const counts = {};

    for (let i = 0; i < data.length; i += 2) {
      const ids = data[i].toString().split(",");
      const count = data[i + 1];

      ids.forEach((id) => {
        counts[id] = (counts[id] || 0) + count;
      });
    }
    return titles
      .map((titleObj) => ({
        title: titleObj.name,
        count: counts[titleObj.id] || 0,
      }))
      .sort((a, b) => b.count - a.count);
  };
  const mapDataToCities = (countData) => {
    const counts = [];
    // console.log(cityList, countData);

    countData.forEach((data) => {
      const city = cityList?.find((city) => city.id === parseInt(data.value));
      if (city) {
        counts.push({ title: city.cityName, count: data.count });
      }
    });
    // console.log(counts);
    return counts.sort((a, b) => b.count - a.count);
  };

  const getCount = (countOfItem, obj) => {
    // console.log('getCount', countOfItem, obj);
    let count = 0;
    if (obj && countOfItem && countOfItem !== undefined && obj !== undefined) {
      const key = countOfItem.toString();
      if (obj.hasOwnProperty(key)) {
        count = obj[key];
      }
    }

    return count;
  };

  const groupExperienceCounts = (data) => {
    const ranges = {
      "0-3": 0,
      "4-6": 0,
      "7-9": 0,
      "10-12": 0,
      "13-15": 0,
      "16-18": 0,
      "19-21": 0,
      "22-24": 0,
      "25-27": 0,
      "27+": 0,
    };

    for (let i = 0; i < data.length; i += 2) {
      const year = parseFloat(data[i]);
      const count = data[i + 1];

      if (year >= 0 && year < 4) {
        ranges["0-3"] += count;
      } else if (year > 3 && year < 7) {
        ranges["4-6"] += count;
      } else if (year > 6 && year < 10) {
        ranges["7-9"] += count;
      } else if (year > 9 && year < 13) {
        ranges["10-12"] += count;
      } else if (year > 12 && year < 16) {
        ranges["13-15"] += count;
      } else if (year > 15 && year < 19) {
        ranges["16-18"] += count;
      } else if (year > 18 && year < 22) {
        ranges["19-21"] += count;
      } else if (year > 21 && year < 25) {
        ranges["22-24"] += count;
      } else if (year > 24 && year < 28) {
        ranges["25-27"] += count;
      } else if (year > 27) {
        ranges["27+"] += count;
      }
    }

    return ranges;
  };

  const getExpCount = (countOfItems, obj) => {
    let count = 0;
    // if (obj && countOfItems && Array.isArray(countOfItems) && countOfItems.length > 0) {
    //     countOfItems.forEach((item) => {

    //     });
    // }
    if (obj && countOfItems) {
      let lowerBound, upperBound;

      if (countOfItems.includes("above")) {
        lowerBound = parseInt(countOfItems.split(" ")[1], 10);
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            const keyNum = parseFloat(key);
            if (keyNum > lowerBound) {
              count += obj[key];
            }
          }
        }
      } else if (countOfItems.includes("<")) {
        upperBound = parseInt(countOfItems.split(" ")[1], 10);
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            const keyNum = parseFloat(key);
            if (keyNum < upperBound) {
              count += obj[key];
            }
          }
        }
      } else {
        const splitItem = countOfItems.split("-").map(Number);
        lowerBound = splitItem[0];
        upperBound = splitItem[1];

        for (let i = lowerBound; i <= upperBound; i++) {
          const key = i.toString() + ".0";
          if (obj.hasOwnProperty(key)) {
            count += obj[key];
          }
        }
      }
    }
    return count;
  };

  const getCitiesByStateId = async (id) => {
    try {
      const response = await axios.post(
        `${Key.domain}/commonAPIs/get_cities_by_stateid`,
        {
          state_id: id,
        }
      );
      // console.log("state_id_results", response);
      if (response.status === 200) {
        return response.data.data;
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const detailsHandler = (data, index) => {
    dispatch(setSelectedResume(data));
    dispatch(jsResumeDetails(data?.id));
    // checkEmployerLimit()
    // getAllResumeViews();
  };

  const getAllJobTypes = async () => {
    try {
      const response = await axios.get(
        `${Key.domain}/commonAPIs/get_all_jobtypes`
      );
      if (response.status === 200) {
        // console.log("check facet jobtype 1", response.data.data);
        setJobTypesData(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAllVisaTypes = async () => {
    try {
      const response = await axios.get(
        `${Key.domain}/commonAPIs/get_all_visatypes`
      );
      if (response.status) {
        // console.log(response.data);
        setVisaTypesData(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAllResumeViews = () => {
    // showLoader();

    if (currentUser !== null) {
      try {
        const response = axios.post(
          `${Key.domain}/resume/get_all_resume_views`,
          {
            user_id: sessionStorage.getItem("id"),
            session_token: currentUser.session_token,
          }
        );
        setAllResumeViews(response?.data?.map((data) => data?.resume_id));
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (
            error.response.data.data === "Access denied.. Please Login" ||
            error.response.data.data === "Access denied"
          ) {
            //setAlert(true);
          }
        }
        console.error("Error fetching getEmpDashboardDetails:", error);
      }
    }
    //props.hideLoader();
  };
  // const updateViewStatusMultiple = (ids) => {
  //   if (ids && ids !== undefined && ids.length > 0) {
  //     ids.map((id) => updateViewStatus(id));
  //   }
  // };
  // const updateViewStatus = (rid) => {
  //   checkEmployerLimit(rid);
  //   getAllResumeViews();
  // };
  useEffect(() => {
    checkEmployerLimit();
    getAllResumeViews();
  }, [selectedResume]);
  const checkEmployerLimit = async () => {
    await axios
      .post(`${Key.domain}/users/check_employer_package_limit`, {
        user_id: currentUser.id,
        session_token: currentUser.session_token,
        action_to_check: "resume_views",
        subscriber_name: currentUser.full_name,
        subscriber_email: currentUser.username,
        //package_id: currentUser.package_id,
      })
      .then((resdata) => {
        if (
          resdata.status === 200 &&
          resdata.data.success === 1 &&
          resdata.data.data !== undefined &&
          resdata.data.data !== null &&
          resdata.data.data !== undefined &&
          resdata.data.data !== "" &&
          resdata.data.data[0] !== null &&
          resdata.data.data[0] !== undefined &&
          resdata.data.data[0] !== "" &&
          resdata.data.data[0].resumes_viewed !== null &&
          resdata.data.data[0].resumes_viewed !== undefined &&
          resdata.data.data[0].resumes_viewed !== "" &&
          resdata.data.data[0].resumeviews_allowed !== null &&
          resdata.data.data[0].resumeviews_allowed !== undefined &&
          resdata.data.data[0].resumeviews_allowed !== ""
        ) {
          if (resdata.data.data[0]?.package_expired === 1) {
            setResumePackageExpired(true);
            setPackageExpiredOpen(true);
          } else if (resdata.data.limitExceeded === true) {
            setLimitExceeded(true);
            setPackageExpiredOpen(true);
          } else {
            const leftResumeViews =
              parseInt(resdata.data.data[0].resumeviews_allowed) -
              parseInt(resdata.data.data[0].resumes_viewed);
            setResumeViewsLeft(leftResumeViews);

            let admin_id = sessionStorage.getItem("admin_id");
            let ca_id =
              admin_id !== "" &&
              admin_id !== 0 &&
              admin_id !== null &&
              admin_id !== undefined &&
              admin_id !== "0"
                ? admin_id
                : sessionStorage.getItem("id");
            if (
              leftResumeViews > 0 &&
              !selectedResume?.viewed_ids?.includes(currentUser?.id.toString())
            ) {
              // window.alert("called")

              if (!Array.isArray(selectedResume?.viewed_ids)) {
                selectedResume.viewed_ids = [];
              }
              if (
                !selectedResume?.viewed_ids?.includes(
                  currentUser?.id.toString()
                )
              ) {
                selectedResume?.viewed_ids.push(currentUser?.id.toString());
              }
              if (selectedResume?.id !== undefined) {
                //window.alert("selectedResume")
                axios
                  .post(`${Key.domain}/resume/update_resume_viewedStatus`, {
                    user_id: sessionStorage.getItem("id"),
                    resumeid: selectedResume?.id,
                    ca_id: ca_id,
                    resume_type: "regular",
                    views: 1,
                    session_token: currentUser.session_token,
                    viewed_uids: selectedResume?.viewed_ids,
                  })
                  .then((response) => {
                    var resdata = response.data.data;
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
          }
        } else if (resdata.status === 400) {
        } else {
          console.error("check_employer_package_limit fails", resdata.data);
          throw new Error("No views yet");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          if (
            error.response.data.data === "Access denied.. Please Login" ||
            error.response.data.data === "Access denied"
          ) {
            setAlert(true);
          }
        }
      });
  };

  const getTimeAgo = (timestamp) => {
    const lastModifiedMoment = moment(timestamp);
    const now = moment();
    const diffInSeconds = now.diff(lastModifiedMoment, "seconds");

    if (diffInSeconds < 60) {
      return "a few seconds ago";
    } else {
      return lastModifiedMoment.fromNow();
    }
  };

  const submitAIHandler = async () => {
    // console.log("AIMessage", openAImessage);
    setProgress(2);
    const data = await new Promise((resolve, reject) => {
      openAIExtracteAISearchValues(openAImessage, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
    setAIData(data);
    setOpenAiForm1Modal(false);
    setAdvancedSearchOpen(true);
    // await dispatch(updateResumeSearchParams({
    //   jobType: data.jobtype,
    //   state: data.state,
    //   title: data.job_title,
    //   visaType: data.visatype,
    //   skills: data.skills,
    //   relatedTitles: data.job_roles,
    //   experience: data.experience
    // }));
    // console.log("AIMessage AI Result", data);
  };

  const buttonStyles = (buttonName) => ({
    borderRadius: "0px",
    p: "4px 15px",
    color:
      activeButton === buttonName ? palette.primary.main : palette.grey[600],
    backgroundColor:
      activeButton === buttonName ? palette.primary.lighter : "transparent",
    borderColor: palette.grey[400],
    "&:hover": {
      backgroundColor:
        activeButton === buttonName
          ? palette.primary.light
          : palette.primary.lighter,
      //   borderColor: activeButton === buttonName ? 'primary.dark' : 'primary.main',
    },
    fontSize: styles.fontSize12,
    textTransform: "capitalize",
  });

  const buttonStylesRound = (buttonName) => ({
    borderRadius: "20px",
    p: "4px 15px",
    mr: "5px",
    mb: "4px",
    color:
      Object.keys(filterParams).length !== 0 &&
      selectedFilterValues.jobType.includes(buttonName)
        ? palette.primary.main
        : palette.grey[600],
    backgroundColor:
      Object.keys(filterParams).length !== 0 &&
      selectedFilterValues.jobType.includes(buttonName)
        ? palette.primary.lighter
        : "transparent",
    borderColor: palette.grey[400],
    "&:hover": {
      backgroundColor: selectedFilterValues.jobType.includes(buttonName)
        ? palette.primary.light
        : palette.primary.lighter,
    },
    fontSize: styles.fontSize12,
    textTransform: "capitalize",
  });

  const buttonVisaTypeStylesRound = (buttonName) => ({
    borderRadius: "20px",
    p: "4px 15px",
    mr: "5px",
    mb: "4px",
    color: selectedFilterValues.visaType.includes(buttonName)
      ? palette.primary.main
      : palette.grey[600],
    backgroundColor: selectedFilterValues.visaType.includes(buttonName)
      ? palette.primary.lighter
      : "transparent",
    borderColor: palette.grey[400],
    "&:hover": {
      backgroundColor: selectedFilterValues.visaType.includes(buttonName)
        ? palette.primary.light
        : palette.primary.lighter,
    },
    fontSize: styles.fontSize12,
    textTransform: "capitalize",
  });

  const buttonExpStylesRound = (buttonName) => ({
    borderRadius: "20px",
    p: "4px 15px",
    mr: "5px",
    mb: "4px",
    color: selectedFilterValues.exp.includes(buttonName)
      ? palette.primary.main
      : palette.grey[600],
    backgroundColor: selectedFilterValues.exp.includes(buttonName)
      ? palette.primary.lighter
      : "transparent",
    borderColor: palette.grey[400],
    "&:hover": {
      backgroundColor: selectedFilterValues.exp.includes(buttonName)
        ? palette.primary.light
        : palette.primary.lighter,
    },
    fontSize: styles.fontSize12,
    textTransform: "capitalize",
  });
  const buttonStateStylesRound = (buttonName) => ({
    borderRadius: "20px",
    p: "4px 15px",
    mr: "5px",
    mb: "4px",
    color: selectedFilterValues.state.includes(buttonName)
      ? palette.primary.main
      : palette.grey[600],
    backgroundColor: selectedFilterValues.state.includes(buttonName)
      ? palette.primary.lighter
      : "transparent",
    borderColor: palette.grey[400],
    "&:hover": {
      backgroundColor: selectedFilterValues.state.includes(buttonName)
        ? palette.primary.light
        : palette.primary.lighter,
    },
    fontSize: styles.fontSize12,
    textTransform: "capitalize",
  });
  const buttonCityStylesRound = (buttonName) => ({
    borderRadius: "20px",
    p: "4px 15px",
    mr: "5px",
    mb: "4px",
    color: selectedFilterValues.city.includes(buttonName)
      ? palette.primary.main
      : palette.grey[600],
    backgroundColor: selectedFilterValues.city.includes(buttonName)
      ? palette.primary.lighter
      : "transparent",
    borderColor: palette.grey[400],
    "&:hover": {
      backgroundColor: selectedFilterValues.city.includes(buttonName)
        ? palette.primary.light
        : palette.primary.lighter,
    },
    fontSize: styles.fontSize12,
    textTransform: "capitalize",
  });

  const text1Styles = {
    m: 0,
    fontSize: styles.fontSize12,
    fontWeight: 500,
    color: palette.grey[600],
  };
  return alert ? (
    <SessionAlert />
  ) : (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 0,
          borderColor: "divider",
          position: "fixed",
          width: "100%",
          backgroundColor: "white",
          zIndex: 1100,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", pl: 2, justifyContent: 'space-between', width: '100%' }}>
          {/* <FilterListRounded sx={{
          color:palette.grey[600],
          // '&:hover .expandOverItems':{
          //     // height:'60px'
          //     overflow:'visible'
          // }
        }} /> */}
        
          <Box
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{ display: "flex", alignItems: "center" }}
          >
             {/* {
              location.pathname === "/employer/ai-search" &&
              <Button
              type="button"
              variant="contained" disableElevation
              onClick={() => {setOpenAiForm1Modal(true); setProgress(0); setBuffer(10);}}
              size="small"
              sx={{
                bgcolor: 'rgba(64, 104, 130, 0.9)',
                '&:hover': { bgcolor: '#406882' },
                textTransform: 'capitalize',
              }}
            >
              Find Resumes
            </Button>
             } */}
            <IconButton sx={{ pt: "10px" }}>
              <Badge
                color="primary"
                badgeContent={filtersCount.total ? filtersCount.total : 0}
                // variant="dot"
                // invisible={filtersCount === 0}
              >
                <FilterListRounded
                  sx={{
                    color: palette.grey[600],
                  }}
                />
              </Badge>
            </IconButton>
            <Box
              className="expandOverItems"
              sx={{
                height: hover ? "auto" : "auto",
                overflow: hover ? "visible" : "hidden",
                transition: "overflow 0.3s",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  minHeight: "auto",
                  "& .MuiTab-root": {
                    fontSize: styles.fontSize13,
                    minHeight: "auto",
                    padding: "6px 12px",
                    minWidth: "60px",
                    textTransform: "capitalize",
                    fontWeight: 500,
                    // letterSpacing: 0.8
                  },
                  "& .Mui-selected": {
                    color: "#1976d2 !important",
                    // backgroundColor: '#e3f2fd',
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#1976d2",
                  },
                  "& .css-heg063-MuiTabs-flexContainer": {
                    paddingTop: "10px",
                  },
                }}
              >
                <TabWithBadge
                  label="Job Type"
                  badgeContent={filtersCount.jobTypeCount}
                  {...a11yProps(0)}
                />
                <TabWithBadge
                  label="Visa Type"
                  badgeContent={filtersCount.visatypeCount}
                  {...a11yProps(1)}
                />
                <TabWithBadge
                  label="Experience"
                  badgeContent={filtersCount.totalExpCount}
                  {...a11yProps(2)}
                />
                <TabWithBadge
                  label="Posted Date"
                  badgeContent={filtersCount.postedDate}
                  {...a11yProps(3)}
                />
                {/* <Tab
            disableRipple
            disableFocusRipple
            disableTouchRipple
            label="Visa Type"
            {...a11yProps(1)}
          /> */}
                {/* <Tab
            disableRipple
            disableFocusRipple
            disableTouchRipple
            label="Location"
            {...a11yProps(2)}
          /> */}
                {/* <Tab
            disableRipple
            disableFocusRipple
            disableTouchRipple
            label="Experience"
            {...a11yProps(2)}
          /> */}
                {/* <Tab disableRipple disableFocusRipple disableTouchRipple label="Posted Date" {...a11yProps(3)} /> */}
                <Tab
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  label="Sort By"
                  {...a11yProps(4)}
                />
                <TabWithBadge
                  label="States"
                  badgeContent={0}
                  {...a11yProps(5)}
                />
                {(searchParams.state !== null ||
                  filterParams.state !== "*:*") && (
                  <TabWithBadge
                    label="Cities"
                    badgeContent={0}
                    {...a11yProps(6)}
                  />
                )}
              </Tabs>
            </Box>
          </Box>
          <Box sx={{mr:10}}>
          {
              location.pathname === "/employer/ai-search" &&
              <Button
              type="button"
              variant="contained" disableElevation
              // onClick={() => {setOpenAiForm1Modal(true); setProgress(0); setBuffer(10);}}
              onClick={() => {setModifySearch(true)}}
              size="small"
              sx={{
                // bgcolor: 'rgba(64, 104, 130, 0.9)',
                '&:hover': { bgcolor: '#406882' },
                bgcolor: '#E97D44',
                textTransform: 'capitalize',
              }}
              startIcon={<SearchIcon  />}
            >
              Modify Description
            </Button>
             }
          </Box>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box
            className="expandOverItems"
            sx={{
              p: "4px",
              height: hover ? "auto" : "36px",
              width: "95%",
              overflow: hover ? "visible" : "hidden",
              backgroundColor: "#fff",
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {/* <Button sx={buttonStylesRound('All')}  variant="outlined" size='small' onClick={() => handleButtonClick('All', 'jobtype')}>All</Button> */}
            {totalCounts?.jobTypeCount.length > 0 &&
              totalCounts?.jobTypeCount?.map((data) => (
                <Button
                  sx={buttonStylesRound(data.title)}
                  disabled={data.count === 0}
                  variant="outlined"
                  size="small"
                  onClick={() => handleButtonClick(data.title, "jobtype")}
                >{`${data.title} (${data.count})`}</Button>
              ))}
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box
            className="expandOverItems"
            sx={{
              p: "4px",
              height: hover ? "auto" : "36px",
              width: "95%",
              overflow: hover ? "visible" : "hidden",
              backgroundColor: "#fff",
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {/* <Button sx={buttonStylesRound('All')}  variant="outlined" size='small' onClick={() => handleButtonClick('All', 'visaType')}>All</Button> */}
            {totalCounts?.visatypeCount.length > 0 &&
              totalCounts?.visatypeCount?.map((data) => (
                <Button
                  sx={buttonVisaTypeStylesRound(data.title)}
                  disabled={data.count === 0}
                  variant="outlined"
                  size="small"
                  onClick={() => handleButtonClick(data.title, "visaType")}
                >{`${data.title}  (${data.count})`}</Button>
              ))}
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {/* <Button sx={buttonExpStylesRound('All')}  variant="outlined" size='small' onClick={() => handleButtonClick('All')}>All</Button> */}
          {/* {
            
            totalCounts?.totalExpCount.length > 0 &&
              totalCounts?.totalExpCount?.map((data, index) => (
                <Button
                  sx={buttonVisaTypeStylesRound(data[index])}
                  disabled={data.count === 0}
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    handleButtonClick(data.title, "visaType")
                  }
                >{`${data.title}  (${data.count})`}</Button>
              ))
          } */}
          {totalCounts?.totalExpCount &&
            Object.entries(totalCounts?.totalExpCount).map(([range, count]) => (
              <Button
                key={range}
                sx={buttonExpStylesRound(range)}
                disabled={count === 0}
                variant="outlined"
                size="small"
                onClick={() => handleButtonClick(range, "exp")}
              >{`${range} (${count})`}</Button>
            ))}
          {/* <Button
            sx={buttonExpStylesRound("0 - 3")}
            variant="outlined"
            size="small"
            disabled={!getExpCount("0 - 3", facet_counts.facet_fields.total_experience)}
            onClick={() => handleButtonClick("0 - 3", "exp")}
          >{`0 - 3 (${getExpCount(
            "0 - 3",
            facet_counts.facet_fields.total_experience
          )})`}</Button>
          <Button
            sx={buttonExpStylesRound("4 - 6")}
            variant="outlined"
            size="small"
            disabled={!getExpCount("4 - 6", facet_counts.facet_fields.total_experience)}
            onClick={() => handleButtonClick("4 - 6", "exp")}
          >{`4 - 6 (${getExpCount(
            "4 - 6",
            facet_counts.facet_fields.total_experience
          )})`}</Button>
          <Button
            sx={buttonExpStylesRound("7 - 10")}
            variant="outlined"
            size="small"
            disabled={!getExpCount("7 - 10", facet_counts.facet_fields.total_experience)}
            onClick={() => handleButtonClick("7 - 10", "exp")}
          >{`7 - 10 (${getExpCount(
            "7 - 10",
            facet_counts.facet_fields.total_experience
          )})`}</Button>
          <Button
            sx={buttonExpStylesRound("above 10")}
            variant="outlined"
            size="small"
            disabled={!getExpCount("above 10", facet_counts.facet_fields.total_experience)}
            onClick={() => handleButtonClick("above 10", "exp")}
          >{`above 10 (${getExpCount(
            "above 10",
            facet_counts.facet_fields.total_experience
          )})`}</Button> */}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <Box
            className="expandOverItems"
            sx={{
              p: "4px",
              height: hover ? "auto" : "36px",
              width: "95%",
              overflow: hover ? "visible" : "hidden",
              backgroundColor: "#fff",
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {totalCounts?.stateCount.length > 0 &&
              totalCounts?.stateCount?.map((data) => (
                <Button
                  sx={buttonStateStylesRound(data.title)}
                  disabled={data.count === 0}
                  variant="outlined"
                  size="small"
                  onClick={() => handleButtonClick(data.title, "state")}
                >{`${data.title}  (${data.count})`}</Button>
              ))}
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <Box
            className="expandOverItems"
            sx={{
              p: "4px",
              height: hover ? "auto" : "36px",
              width: "95%",
              overflow: hover ? "visible" : "hidden",
              backgroundColor: "#fff",
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {totalCounts?.cityCount.length > 0 &&
              totalCounts?.cityCount?.map((data) => (
                <Button
                  sx={buttonCityStylesRound(data.title)}
                  disabled={data.count === 0}
                  variant="outlined"
                  size="small"
                  onClick={() => handleButtonClick(data.title, "city")}
                >{`${data.title}  (${data.count})`}</Button>
              ))}
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            size="small"
          >
            <Button
              sx={buttonStyles("All")}
              onClick={() => handleButtonClick("All", "date")}
            >
              All
            </Button>
            <Button
              sx={buttonStyles("Day")}
              onClick={() => handleButtonClick("Day", "date")}
            >
              Day
            </Button>
            <Button
              sx={buttonStyles("Week")}
              onClick={() => handleButtonClick("Week", "date")}
            >
              Week
            </Button>
            <Button
              sx={buttonStyles("Month")}
              onClick={() => handleButtonClick("Month", "date")}
            >
              Month
            </Button>
          </ButtonGroup>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            size="small"
          >
            <Button
              sx={buttonStyles("Recent")}
              onClick={() => handleButtonClick("Recent")}
            >
              Recent
            </Button>
            <Button
              sx={buttonStyles("Relavance")}
              onClick={() => handleButtonClick("Relavance")}
            >
              Relavance
            </Button>
          </ButtonGroup>
        </CustomTabPanel>
      </Box>
      <Grid container spacing={0} sx={{ pt: "80px" }}>
        <Grid
          item
          xs={3.5}
          component={Paper}
          elevation={1}
          sx={{ height: "100vh", overflow: "scroll" }}
        >
          {/* <Divider /> */}
          <Box>
            <Toolbar
              sx={{
                padding: styles.listToolbarPadding,
                minHeight: styles.minHeightListToolbar,
                position: "sticky",
                top: 0,
                zIndex: 99,
                bgcolor: "white",
                boxShadow: "0 0 2px black",
                borderTop: "1px solid #ddd",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  margin: 0,
                  flexGrow: 1,
                  fontSize: styles.fontSize16,
                }}
                // variant="h6"
                paragraph
                id="tableTitle"
                component="div"
              >
                Resumes{" "}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  margin: 0,
                  fontSize: styles.fontSize9,
                  paddingInline: "7px",
                  border: "1px solid #406882",
                  borderRadius: "8px",
                  bgcolor: "rgba(25, 118, 210, 0.08)",
                  color: "#406882",
                }}
                // variant="h6"
                paragraph
                id="tableTitle"
                component="div"
              >
                {currentUser !== null &&
                parseInt(currentUser.package_id) === 1 &&
                parseInt(currentUser.usertype) === 2
                  ? `Views Left ${resumeViewsLeft}`
                  : `Views Left ${resumeViewsLeft}`}
                {/* {
                            `Views Left ${resumeViewsLeft}`
                          } */}
              </Typography>
            </Toolbar>
            <List sx={{ pr: "4px", zIndex: 0 }}>
              {results &&
                results.map((data, index) => (
                  <ListItemButton
                    selected={selectedResume?.id === data.id}
                    onClick={() => detailsHandler(data, index)}
                    sx={{
                      borderBottom: "1px solid #ddd",
                      borderLeft: !data?.viewed_ids?.includes(
                        currentUser?.id.toString()
                      )
                        ? "4px solid #2065d1"
                        : "",
                      ".editButton": {
                        display: "none",
                      },
                      "&:hover": {
                        ".editButton": {
                          display: "block",
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "6px" }}>
                        <Avatar />
                        <Box sx={{ width: "100", flexGrow: 1 }}>
                          <Box sx={{ display: "flex", width: "100%" }}>
                            <Typography
                              paragraph
                              sx={{
                                flexGrow: 1,
                                m: 0,
                                fontWeight: 800,
                                fontSize: styles.fontSize16,
                                color: palette.grey[700],
                                textTransform: "capitalize",
                              }}
                            >
                              {data?.full_name && data?.full_name !== undefined
                                ? data?.full_name.toLowerCase()
                                : ""}
                            </Typography>
                            {/* <Tooltip title="Edit Customer" followCursor>
                            <EditRounded
                              className="editButton"
                              // onClick={() => setEditCustomerData(data)}
                              sx={{
                                height: styles.icon20,
                                width: styles.icon20,
                                color: palette.grey[600],
                                bgcolor: "#fff",
                              }}
                            />
                          </Tooltip> */}
                          </Box>
                          {/* <Typography paragraph sx={{...text1Styles}}>
                        California City, California
                    </Typography> */}
                          {/* <Typography paragraph sx={{ ...text1Styles, fontWeight:600, color:'#406882' }}>
                          {data.application_title}
                        </Typography> */}
                          <Highlighter
                            highlightStyle={{
                              backgroundColor: "#F9FC3F",
                            }}
                            searchWords={
                              searchParams?.hl_words || searchParams?.title
                                ?.replace(/"/g, "")
                                .split(",") || []
                              // sessionStorage.getItem(
                              //   "skillstoHighlight"
                              // ) !== undefined &&
                              // sessionStorage.getItem(
                              //   "skillstoHighlight"
                              // ) !== null &&
                              // sessionStorage.getItem(
                              //   "skillstoHighlight"
                              // ) !== ""
                              //   ? sessionStorage
                              //       .getItem("skillstoHighlight")
                              //       .replace(/"/g, "")
                              //       .split(",")
                              //   : []
                            }
                            autoEscape={true}
                            style={{ fontSize: "13px" }}
                            textToHighlight={data.application_title}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography
                            paragraph
                            sx={{ m: 0, fontSize: styles.fontSize9 }}
                          >
                            Location
                          </Typography>
                          <Typography
                            paragraph
                            sx={{ m: 0, fontSize: styles.fontSize13 }}
                          >
                            {getResumeLocation(data)}
                          </Typography>
                        </Box>
                        <Box sx={{ border: "1px solid #ddd" }} />
                        <Box>
                          <Typography
                            paragraph
                            sx={{ m: 0, fontSize: styles.fontSize9 }}
                          >
                            Experience
                          </Typography>
                          <Typography
                            paragraph
                            sx={{ m: 0, fontSize: styles.fontSize13 }}
                          >
                            {`${data.total_experience} Years`}
                          </Typography>
                        </Box>
                        <Box sx={{ border: "1px solid #ddd" }} />
                        <Box>
                          <Typography
                            paragraph
                            sx={{ m: 0, fontSize: styles.fontSize9 }}
                          >
                            Last Modified
                          </Typography>
                          <Typography
                            paragraph
                            sx={{ m: 0, fontSize: styles.fontSize13 }}
                          >
                            {/* {moment(data.lastmodified).startOf('hour').fromNow()} */}
                            {getTimeAgo(data.lastmodified)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </ListItemButton>
                ))}
            </List>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Stack
                  direction="column"
                  component={Card}
                  sx={{ p: 1 }}
                  gap={2}
                >
                  <PaginationComponent />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      paragraph
                      sx={{
                        margin: 0,
                        fontSize: styles.fontSize13,
                      }}
                    >
                      Page <strong>{paginationParams.currentPage}</strong> of{" "}
                      <strong>{numFound}</strong> Resumes
                    </Typography>
                    <RowSelect
                    // rowsPerPage={paginationParams.rows}
                    // onRowsChange={handleRowsChange}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={8.5} sx={{ height: "100vh", overflow: "scroll" }}>
          <Box
            sx={
              limitExceeded || resumepackageExpired
                ? hideContentStyles.blurredContent
                : hideContentStyles.unblurredContent
            }
          >
            {results.length > 0 ? (
              <DetailsView
                limitExceeded={limitExceeded}
                resumepackageExpired={resumepackageExpired}
              />
            ) : (
              <Box
                sx={{
                  height: 400,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                We are fetching the profiles Please give me some more time...
              </Box>
            )}
          </Box>
        </Grid>
        <Dialog
          fullScreen={fullScreen}
          open={packageExpiredOpen}
          fullWidth
          onClose={handleCloseAlert}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {resumepackageExpired
              ? "Package Expired!"
              : "Resume View Limit exceeded!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {resumepackageExpired || limitExceeded ? (
                <span>
                  Your package is expired.Please{" "}
                  <a
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                    // onClick={() => setPackagesShow(true)}
                  >
                    ContactSales
                  </a>{" "}
                  to upgrade your package!`
                </span>
              ) : (
                <span>
                  You have exceeded your free limit to view, download or share
                  resumes.Please{" "}
                  <a
                    // onClick={() => setPackagesShow(true)}
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                  >
                    ContactSales
                  </a>{" "}
                  to upgrade your package!
                </span>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </Grid>
      <Modal
        open={openAiForm1Modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component={Card} sx={AiForm1ModalStyles}>
          <CardHeader
            title={
              <h2
                className=""
                style={{
                  fontSize: false ? "17px" : "19px",
                  color: "#406882",
                  fontWeight: 600,
                }}
              >
                {" "}
                Find A PERFECT RESUME WITH{" "}
                <span
                  style={{
                    backgroundColor: "#e97d44",
                    padding: "4px",
                    borderRadius: "6px",
                    color: "#fff",
                  }}
                >
                  AI
                </span>
                &nbsp;
                <sub style={{ fontWeight: "100", fontSize: "12px" }}>
                  ( Beta Version )
                </sub>
              </h2>
            }
            action={
              <IconButton
                // onClick={handleCloseSearchHandler}
                onClick={() => setOpenAiForm1Modal(false)}
              >
                <CloseRounded />
              </IconButton>
            }
          />
          <CardContent>
            <form autocomplete="on">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <textarea
                    type="text"
                    style={{
                      userSelect: "none",
                      borderRadius: "6px",
                      padding: "10px 0px 0px 10px",
                    }}
                    rows={6}
                    class="form-control no-drag"
                    value={
                      openAImessage !== ""
                        ? openAImessage.replace(/"/g, "")
                        : ""
                    }
                    placeholder="Paste Job Description here..."
                    onChange={(e) => {
                      setOpenAImessage(e.target.value);
                    }}
                  ></textarea>
                </Grid>
                <Grid item xs={12} textAlign="end">
                  <Button
                    type="button"
                    variant="contained"
                    disabled={progress > 0}
                    // onClick={() => { submit(1); setProgress(2); }}
                    onClick={submitAIHandler}
                    sx={{
                      bgcolor: "rgba(64, 104, 130, 0.9)",
                      "&:hover": { bgcolor: "#406882" },
                    }}
                  >
                    AI Search
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: progress > 0 ? "block" : "none" }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress
                      variant="buffer"
                      value={progress}
                      valueBuffer={buffer}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Box>
      </Modal>
      {advancedSearchOpen && <AdvancedSearch
        isOpen={advancedSearchOpen}
        setAdvancedSearchOpen={setAdvancedSearchOpen}
        setOpenAiForm1Modal={setOpenAiForm1Modal}
        setProgress={setProgress}
        aiData={aiData}
      />}
      {modifySearch && <ModifySearch 
        isOpen={modifySearch}
        setAdvancedSearchOpen={setModifySearch}
        setOpenAiForm1Modal={setOpenAiForm1Modal}
        setProgress={setProgress}
        aiData={aiData} />}
    </Box>
  );
};

export default ResumeMain;

const TabWithBadge = ({ label, badgeContent, ...props }) => (
  <Badge
    color="primary"
    badgeContent={badgeContent}
    variant="dot"
    invisible={badgeContent === 0}
  >
    <Tab
      disableRipple
      disableFocusRipple
      disableTouchRipple
      label={label}
      {...props}
    />
  </Badge>
);

const hideContentStyles = {
  blurredContent: {
    filter: "blur(4px)",
    pointerEvents: "none",
    userSelect: "none",
  },
  unblurredContent: {
    filter: "none",
    pointerEvents: "auto",
    userSelect: "auto",
  },
};

const AiForm1ModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "1px solid #ddd",
  boxShadow: 24,
  // p: 4,
  borderRadius: "8px",
};
