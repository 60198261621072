/* eslint-disable */

import Key from "../clientVariables.json";
import moment from "moment";
import { job_types_data, us_states_data, visa_types_data } from "./commonData";
import { toast } from "react-toastify";

function containsSpecialCharacter(str) {
  const specialCharRegex = /[^a-zA-Z0-9\s]/;
  return specialCharRegex.test(str);
}

function splitBySpecialCharacters(input) {
  const specialCharRegex = /[^a-zA-Z0-9\s]+/;
  const parts = input.split(specialCharRegex);
  const cleanedParts = parts
    .map((part) => part.trim())
    .filter((part) => part !== "");
  return cleanedParts;
}

function getMappedDates(dateOption) {
  const today = new Date();
  let startdate;

  if (dateOption == "1") {
    startdate = moment(today)
      .subtract(1, "d")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else if (dateOption == "2") {
    startdate = moment(today)
      .subtract(7, "d")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else if (dateOption == "3") {
    startdate = moment(today)
      .subtract(30, "d")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else {
    startdate = null;
  }

  return startdate ? `${startdate} TO NOW` : null;
}

const removeItems = [
  "skill",
  "technology",
  "developer",
  "programmer",
  "architect",
  "designer",
  "consultant",
  "administrator",
  "coach",
  "lead",
  "manager",
  "master",
  "specalist",
  "modeler",
  "director",
  "hacker",
  "engineer",
  "analyst",
  "dba",
  "tester",
  "senior",
  "senior.",
  "sr",
  "junior",
  "junior.",
  "jr",
  "sr.",
  "jr.",
  "knowledge",
  "or",
  "with",
  "to",
  "and",
  "front",
  "end",
  "back",
  "full",
  "stack",
  "full stack",
];

function processSearchTitle(title) {
  const wordRegex = /[^\W\d_]+/g;
  const words = title.match(wordRegex);
  // console.log('processSearchTitle words', words);

  if (words) {
    const filteredWords = words.filter(
      (word) => isNaN(word) && !removeItems.includes(word.toLowerCase())
    );
    const splittingSpecialCharacter = splitBySpecialCharacters(title);
    // console.log('processSearchTitle splittingSpecialCharacter', splittingSpecialCharacter);

    const finalTitleWords = filteredWords.concat(splittingSpecialCharacter);
    //  .map(word => `"${word}"`)
    //  .join(" OR ");
    const finalFilter = finalTitleWords
      .filter((word) => !removeItems.includes(word.toLowerCase()))
      .map((word) => `"${word}"`)
      .join(" OR ");
    // console.log('processSearchTitle finalTitleWords', finalTitleWords, finalFilter);

    return finalFilter;
  } else {
    return title;
  }
}

function processSearchTitleV1(title) {
  const wordRegex = /[^\W\d_]+/g;
  const words = title.match(wordRegex);
  // console.log('processSearchTitle words', words);

  if (words) {
    // const filteredWords = words.filter(
    //   (word) => isNaN(word) && !removeItems.includes(word.toLowerCase())
    // );
    const splittingSpecialCharacter = splitBySpecialCharacters(title);
    // console.log('processSearchTitle splittingSpecialCharacter', splittingSpecialCharacter);

    // const finalTitleWords = filteredWords.concat(splittingSpecialCharacter)
    //  .map(word => `"${word}"`)
    //  .join(" OR ");
    const finalFilter = splittingSpecialCharacter
      .map((word) => `"${word}"`)
      .join(" OR ");
    // console.log('processSearchTitle finalTitleWords', finalTitleWords, finalFilter);

    return finalFilter;
  } else {
    return title;
  }
}
export const jobsBuildFacetQuery = (
  filterParams = {},
  searchParams = {},
  paginationParams = {},
  facet_counts
) => {
  const { start, rows } = paginationParams || {};
  // const solrBaseUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?defType=edismaxq=`;
  const solrBaseUrl = `${Key.Solr_url}/${Key.solrJobs_collection}/select?defType=edismax`;
  // console.log("searchParams11", searchParams);

  let query = "";
  const jobTypeID = [];
  const visaTypeID = [];
  const stateID = [];
  const cityID = [];

  const addIDToArray = (param, data, idArray, field, paramName) => {
    if (paramName === "state") {
      if (param === "*:*") {
        let loc = searchParams?.state?.split(/[,]+/);
        const singleLocation = loc && loc[0].trim();
        // console.log('singleLocation',singleLocation);

        if (singleLocation?.length === 2) {
          data.forEach((item) => {
            if (
              item.shortRegion.toLowerCase() === singleLocation.toLowerCase()
            ) {
              idArray.push(item.id);
            }
          });
        } else {
          const searchValue = searchParams[paramName]?.toLowerCase();
          data.forEach((item) => {
            if (item[field].toLowerCase().includes(searchValue)) {
              idArray.push(item.id);
            }
          });
        }
        // const searchValue = searchParams[paramName]?.toLowerCase();
        // data.forEach((item) => {
        //   if (item["shortRegion"]?.toLowerCase() === searchValue) {
        //     idArray.push(item.id);
        //   }else if (item[field].toLowerCase().includes(searchValue)) {
        //     idArray.push(item.id);
        //   }
        // });
      } else {
        if (param?.includes(" OR ")) {
          idArray.push(...param.split(" OR "));
        } else if (param) {
          idArray.push(param);
        }
      }
    } else if (param === "*:*") {
      // Ensure searchParams[paramName] exists before proceeding
      if (searchParams[paramName]) {
        const searchValue = searchParams[paramName].toLowerCase();
        // console.log("check 01", searchValue);

        data.forEach((item) => {
          if (item[field].toLowerCase().includes(searchValue)) {
            idArray.push(item.id);
          }
        });
      }
    } else {
      if (param?.includes(" OR ")) {
        idArray.push(...param.split(" OR "));
      } else if (param) {
        idArray.push(param);
      }
    }

    // console.log("check1 11", param, data, idArray, field, paramName);
    // console.log("check1 22", jobTypeID, visaTypeID, stateID);
  };

  addIDToArray(
    filterParams?.jobtype,
    job_types_data,
    jobTypeID,
    "title",
    "jobType"
  );
  addIDToArray(
    filterParams?.visatype,
    visa_types_data,
    visaTypeID,
    "type_code",
    "visaType"
  );
  addIDToArray(filterParams?.state, us_states_data, stateID, "name", "state");

  if (
    filterParams?.city &&
    filterParams.city !== "*:*" &&
    filterParams.city !== ""
  ) {
    cityID.push(...filterParams.city?.split(" OR "));
  }

  if (searchParams?.title) {
    const searchText = processSearchTitleV1(searchParams.title); // title:(Full Stack Python Developer)&defType=edismax&qf=title^10 description^2 skills^5
    const searchText2 = processSearchTitle(searchParams.title);
    
    if (searchParams.title.toLowerCase().includes("c++")) {
      query += `&q=title:("${searchParams?.title}" OR "c++")&qf=title^10&q.op=OR`;
    } 
    else if(searchParams?.title.toLowerCase().includes("j2ee")) {
      query += `&q=title:("${searchParams?.title}" OR j2ee)&qf=title^10&q.op=OR`;
    } 
    // else if(searchParams?.title.toLowerCase().includes("c#")) {
    //   query += `&q=title:("${searchParams?.title}" OR "c#")&qf=title^10&q.op=OR`;
    // }  
    else {
      query += `&q=title:(${searchText2})&qf=title^10&q.op=OR`;
    }
  }else{
    query +=`&q=*:*`;
  }

  if (stateID?.length > 0) {
    query += `&fq=state_id:(${stateID.join(" OR ")})`;
  }

  if (cityID?.length > 0) {
    query += `&fq=city_id:(${cityID.join(" OR ")})`;
  }

  if (jobTypeID?.length > 0) {
    query += `&fq=jobtype_id:(${jobTypeID.join(" OR ")})`;
  }
  if (visaTypeID.length > 0) {
    query += `&fq=workpermit:(${visaTypeID.join(" OR ")})`;
  }
  if (searchParams?.id) {
    const cleanedId = searchParams.id.replace("#", "");
    query += `&fq=id:(${cleanedId})`;
  }
  if (searchParams?.email) {
    const escapedEmail = searchParams.email.replace(
      /([+\-&|!(){}\[\]^"~*?:\\/])/g,
      "\\$1"
    );
    query += `&fq=contactemail:("${escapedEmail}")`;
  }

  if (searchParams?.skills && searchParams.skills?.length > 0) {
    query += `&fq=ai_skills:(${searchParams.skills.join(" OR ")})`;
  }

  if (searchParams?.relatedTitles && searchParams.relatedTitles?.length > 0) {
    query += `&fq=related_titles:(${searchParams.relatedTitles.join(" OR ")})`;
  }
  if (searchParams?.companyName) {
    query += `&fq=company_name:(${searchParams.companyName})`;
  }
  
  if (filterParams?.lastmodified) {
    const dateRange = getMappedDates(filterParams.lastmodified);
    query += `&fq=modified:[${dateRange}]`;
  }

  if (filterParams?.workmode && filterParams?.workmode !== null) {
    query += `&fq=workmode:${filterParams.workmode}`;
  }

  // if(filterParams.total_experience && filterParams.total_experience !== "*:*"){
  //   query += `&fq=total_experience:[${filterParams.total_experience}]`;
  // }
  // if (
  //   filterParams.total_experience &&
  //   filterParams.total_experience !== "*:*"
  // ) {
  //   const totalExperience = String(filterParams.total_experience);
  //   if (totalExperience.includes(",")) {
  //     const ranges = totalExperience.split(",");
  //     const rangeQueries = ranges.map(
  //       (range) => `total_experience:[${range.trim()}]`
  //     );
  //     const combinedQuery = rangeQueries.join(" OR ");
  //     query += `&fq=(${combinedQuery})`;
  //   } else {
  //     query += `&fq=total_experience:[${totalExperience.trim()}]`;
  //   }
  // } else if (searchParams?.experience) {
  //   const experienceValue = Number(searchParams.experience);
  //   query += `&fq=total_experience:[${experienceValue} TO *]`;
  // }

  query += `&facet=true&facet.pivot=state_id,city_id&facet.field=state_id&facet.field=jobtype_id&facet.field=workpermit&facet.field=qualifications&facet.field=workmode&facet.field=map_enable&facet.mincount=1&facet.sort=count`;

  return `${solrBaseUrl}${query}&rows=${rows || 10}&start=${start || 0}&fq=status:(1)&fq=industry_type:("IT")&sort=modified desc&wt=json`;
};
