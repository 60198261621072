import React, { useState, useEffect, useRef } from "react";
import {
  LinearProgress,
  linearProgressClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Avatar,
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  Radio,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Modal,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  tableCellClasses,
} from "@mui/material";
import { Button, Grid, Box } from "@mui/material";
import {
  ArrowRightOutlined,
  AssignmentLateRounded,
  BusinessCenterRounded,
  CloseRounded,
  WorkspacePremiumRounded,
} from "@mui/icons-material";
import { FaUserGraduate } from "react-icons/fa6";
import { MdFlight, MdOutlineWorkHistory } from "react-icons/md";
import { FaListCheck } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { styled, alpha } from "@mui/material/styles";
import { useResponsiveStyles } from "../../../Styles/responsiveStyles";
import moment from "moment";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
import { getJobLocation } from "../../../utils/getLocation";
import { toast } from "react-toastify";
import axios from "axios";
import Key from "../../../clientVariables.json";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Messagepopup from "../../searchResumes/Messagepopup";
import Sendcandidatetomail from "../../searchResumes/sendcandidatetomail";
import SessionAlert from "../../SessionAlert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#53778e",
    //  theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const JobDetail = ({ selectedJob }) => {
  const styles = useResponsiveStyles();
  const currentUser = useSelector((state) => state.UserReducer.user);
  const primaryResumeDetails = useSelector(
    (state) => state.primaryResumeReducer.resume
  );
  const [alert, setAlert] = useState(false);
  const skillMatchRef = useRef(null);
  const gridRef = useRef(null);
  const [showToolbar, setShowToolbar] = useState(false);
  const [matchedSkills, setMatchedSkills] = useState([]);
  const [matchResumeSkills, setMatchResumeSkills] = useState([]);
  const [matchJobSkills, setMatchJobSkills] = useState([]);
  const [apply, setapplied] = useState(false);
  const [save, setsaved] = useState(false);
  const history = useHistory();
  const [openJobApplyProcess, setOpenJobApplyProcess] = useState(false);
  const [allResumes, setAllResumes] = useState([]);
  const [applyProgress, setApplyProgress] = useState(0);
  const [screenersData, setScreenersData] = useState([]);
  const [mailshow, setmailShow] = useState(false);
  const [tomail, settomail] = useState("");
  const [messageshow, setmessageShow] = useState(false);
  const [candfullname, setcandfullname] = useState("");
  const [selectedScreenersQuestions, setSelectedScreenersQuestions] = useState(
    []
  );
  const [resumeFileName, setResumeFileName] = useState(primaryResumeDetails);

  const [selectedValueApplyJob, setSelectedValueApplyJob] = useState(
    primaryResumeDetails?.id
  );
  const [answersToScreeners, setAnswersToScreeners] = useState([]);
  const [applyLoading, setApplyLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Mobile devices
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Tablets
  const isDesktopSmall = useMediaQuery(theme.breakpoints.between("md", "lg")); // Small desktops
  const isDesktopLarge = useMediaQuery(theme.breakpoints.up("lg")); // Large desktops

  const isMatchLGL = useMediaQuery("(min-width:1025px)");

  const handleToolbarScroll = () => {
    const yOffset = gridRef.current.scrollTop;
    const triggerScroll = 200; // Adjust as needed

    if (yOffset > triggerScroll) {
      setShowToolbar(true);
    } else {
      setShowToolbar(false);
    }
  };
  const scrollToSkillMatch = () => {
    if (skillMatchRef.current) {
      gridRef.current.scrollTo({
        top: skillMatchRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("fetch data", selectedJob?.ai_skills);
        // if (
        //   primaryResumeDetails?.ai_skills &&
        //   selectedJob?.ai_skills &&
        //   typeof primaryResumeDetails.ai_skills === "string" &&
        //   typeof selectedJob.ai_skills === "string"
        // ) {
        //debugger
        const resumeSkills =
          ((await typeof primaryResumeDetails.ai_skills) === "string" &&
            primaryResumeDetails?.ai_skills.split(",")) ||
          "";
        // console.log("matchedSkillsr", resumeSkills);
        const jobSkills =
          (await selectedJob) && typeof selectedJob.ai_skills === "string"
            ? selectedJob?.ai_skills?.split(",")
            : selectedJob?.ai_skills;
        // console.log("matchedSkillsj", jobSkills);
        if (Array.isArray(resumeSkills)) {
          setMatchResumeSkills(removeDuplicates(resumeSkills));
          // console.log("matchedSkillsj", matchResumeSkills);
        }
        if (Array.isArray(jobSkills)) {
          setMatchJobSkills(removeDuplicates(jobSkills));
        }

        const matchedSkills = await getMatchedSkills(resumeSkills, jobSkills);
        // console.log("matchedSkillsi", matchedSkills);

        setMatchedSkills(matchedSkills);
        // console.log("matched skills", matchJobSkills);
        // }else{
        //   console.log("failed match skills")
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [primaryResumeDetails, selectedJob]);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.addEventListener("scroll", handleToolbarScroll);
    }

    return () => {
      if (gridRef.current) {
        gridRef.current.removeEventListener("scroll", handleToolbarScroll);
      }
    };
  }, []);

  useEffect(() => {
    getAllResumes();
    console.log("selectedJob", selectedJob);
  }, [selectedJob]);

  const removeDuplicates = (arr) => {
    const lowerCaseSet = new Set();
    const result = [];

    arr.forEach((item) => {
      const lowerCaseItem = item.trim().toLowerCase();
      // console.log("lowerCaseItem", lowerCaseItem);
      if (!lowerCaseSet.has(lowerCaseItem)) {
        lowerCaseSet.add(lowerCaseItem);
        result.push(item);
      }
    });

    return result;
  };

  const getMatchedSkills = (resumeSkills, jobSkills) => {
    // console.log("resumeSkills", resumeSkills);
    // console.log("jobSkills", jobSkills);
    let finalResumeSkills;
    let finalJobSkills;

    if (Array.isArray(resumeSkills)) {
      finalResumeSkills = removeDuplicates(resumeSkills);
      // console.log("resumeSkills", resumeSkills);
    } else {
      finalResumeSkills = resumeSkills;
      // console.log("resumeSkills", finalResumeSkills);
    }
    if (Array.isArray(jobSkills)) {
      finalJobSkills = removeDuplicates(jobSkills);
      // console.log("jobSkills", jobSkills);
    } else {
      finalJobSkills = jobSkills;
    }

    const matches = [];
    if (!finalResumeSkills || !finalJobSkills) {
      // console.log("finalResumeSkills", finalResumeSkills);
      return matches;
      // setMatchedSkills([]);
    }
    // setMatchedSkills([]);
    // console.log("finalResumeSkills", finalResumeSkills);
    // console.log("finalJobSkills", finalJobSkills);
    // console.log("finalResumeSkills", finalResumeSkills);
    finalResumeSkills.forEach((skill1) => {
      finalJobSkills.forEach((skill2) => {
        const normalSkill1 = skill1.toLowerCase();
        const normalSkill2 = skill2.toLowerCase();

        if (
          normalSkill1 === normalSkill2 ||
          normalSkill2.includes(normalSkill1) ||
          normalSkill1.includes(normalSkill2)
        ) {
          matches.push(skill1);
          // console.log("skill", skill1);
          // matches.push(skill2);
          // setMatchedSkills([...matchedSkills, skill2]);
        }
      });
    });
    return removeDuplicates(matches);
  };

  const getScreenersBasedOnCompanySelected = async (company_id) => {
    // console.log("currentUser Details : ", currentUser);
    axios
      .post(`${Key.domain}/screeners/get_screeners_list_based_on_company_id`, {
        user_id: currentUser?.id,
        company_id: company_id,
        job_id: selectedJob.id,
        session_token: currentUser?.session_token,
      })
      .then((response) => {
        // console.log(
        //   "screeners response based on company ID : ",
        //   response,
        //   response?.data?.data
        // );
        if (response?.data?.data.length == 0) {
          setApplyProgress(50);
        }
        setScreenersData(response?.data?.data);
      })
      .catch((err) => {
        if (err) {
          console.error(err);
        }
      });
  };

  const getAllResumes = async () => {
    try {
      const response = await axios.post(
        `${Key.domain}/resume/get_js_resumes_list`,
        {
          session_token: currentUser?.session_token,
          infinite_scroll_index: 1,
          user_id: currentUser?.id,
          sortby: 0,
        }
      );
      if (response.status === 200) {
        setAllResumes(response.data.data);
      } else {
        console.error("response.status === 200 false : get_js_resumes_list");
      }
    } catch (error) {
      if (error.response && error.response.status == 400) {
        if (
          error.response.data.data === "Access denied.. Please Login" ||
          error.response.data.data === "Access denied"
        ) {
          setAlert(true);
        }
      }

      console.error("Error fetching calculate_jsprofile_strength:", error);
    }
  };

  const getAllAtachments = async () => {
    let attachments = [];
    try {
      const response = await axios.post(
        `${Key.domain}/MyFiles/get_all_Jsattachments`,
        {
          user_id: currentUser?.id,
          session_token: currentUser?.session_token,
        }
      );
      if (response.status === 200) {
        attachments.push(response.data.data);
      } else {
        console.error("response.status === 200 false : get_all_Jsattachments");
      }
    } catch (error) {
      if (error.response && error.response.status == 400) {
        if (
          error.response.data.data === "Access denied.. Please Login" ||
          error.response.data.data === "Access denied"
        ) {
          setAlert(true);
        }
      }

      console.error("Error fetching calculate_jsprofile_strength:", error);
    }
    return attachments;
  };

  const checkApplySave = async (jobId) => {
    try {
      const response = await axios.post(
        `${Key.domain}/Jobs/check_apply_save_job`,
        {
          user_id: currentUser?.id,
          job_id: jobId,
          session_token: currentUser?.session_token,
        }
      );
      if (response.status === 200) {
        setsaved(response.data.data.savedetails.length > 0 ? true : false);
        setapplied(response.data.data.applydetails.length > 0 ? true : false);
      } else {
        console.error("response.status === 200 false : check_apply_save_job");
      }
    } catch (error) {
      if (error.response && error.response.status == 400) {
        if (
          error.response.data.data === "Access denied.. Please Login" ||
          error.response.data.data === "Access denied"
        ) {
          setAlert(true);
        }
      }

      console.error("Error fetching calculate_jsprofile_strength:", error);
    }
  };
  const createNotification = (recruiter_id, jobtitle, jobid) => {
    axios
      .post(`${Key.domain}/Notifications/create_notification`, {
        user_id: sessionStorage.getItem("id"),
        notifiedto: recruiter_id,
        notificationDescription:
          "Follow-up on your job application should begin with a confirmation of your interest in the position",
        notificationSubject:
          currentUser?.full_name + " has applied for " + jobtitle + " position",
        job_id: jobid,
      })
      .then((response) => {
        //
      });
  };
  const applyJob = async (job) => {
    if (currentUser) {
      axios
        .post(`${Key.domain}/screeners/add_screener_results`, {
          user_id: currentUser?.id,
          answers_array: answersToScreeners,
          session_token: currentUser?.session_token,
        })
        .then((response) => {
          // console.log(
          //   "questions Addition Response ",
          //   response,
          //   response?.data?.data
          // );
        })
        .catch((err) => {
          if (err) {
            console.error(err);
          }
        });

      // console.log('resumeFileName primary_110033 1', resumeFileName, primaryResumeDetails);
      try {
        const attachments = await getAllAtachments();
        const cv_id =
          (resumeFileName?.resume_id
            ? resumeFileName?.resume_id
            : resumeFileName?.id) || primaryResumeDetails?.id;
        const file_name =
          resumeFileName?.filename || primaryResumeDetails?.filename;
        // console.log('resumeFileName primary_110033 2', resumeFileName, primaryResumeDetails);
        const response = await axios.post(
          `${Key.files_domain}/Jobs/apply_job`,
          {
            user_id: currentUser?.id.toString(),
            job_id: job.id.toString(),
            session_token: currentUser?.session_token,
            recruiter_email: job.contactemail,
            recruiter_name: job.contactname || " ",
            jobTitle: job.title,
            js_fullname: currentUser?.full_name,
            jobSeekerEmail: currentUser?.username,
            cvid: cv_id,
            attachment_name: "",
            resumefile_name: file_name,
          }
        );
        if (response.status === 200) {
          setOpenJobApplyProcess(false);
          setApplyLoading(false);
          createNotification(job.uid, job.title, job.id);
          let msg = "Job Applied Successfully";
          toast.success(msg);
          // after Applye set the progress to zero
          setApplyProgress(0);
          checkApplySave(job?.id);
          //getJobDetails(job?.id)
        } else {
          console.error("response.status === 200 false : apply_job");
          let msg = "Something Went Wrong";
          toast.error(msg);
        }
      } catch (error) {
        if (error.response && error.response.status == 400) {
          if (
            error.response.data.data === "Access denied.. Please Login" ||
            error.response.data.data === "Access denied"
          ) {
            setAlert(true);
          }
        }

        console.error("Error fetching calculate_jsprofile_strength:", error);
      }
    } else {
      sessionStorage.setItem("redirect_url_id", selectedJob?.id);
      sessionStorage.setItem("redirect_url_type", "job");
      sessionStorage.setItem("redirect_url", "yes");
      history.push("/Login");
    }
  };

  const saveJob = (job) => {
    //

    if (currentUser) {
      if(Object.keys(primaryResumeDetails).length > 0 && primaryResumeDetails?.id){
        axios
        .post(`${Key.domain}/Jobs/save_job`, {
          user_id: currentUser?.id,
          job_id: job.id,

          session_token: currentUser?.session_token,

          cvid: primaryResumeDetails?.id,
        })

        .then((response) => {
          if (response.data.data.affectedRows > 0) {
            setsaved(true);
            let msg = "Job Saved Successfully";
            toast.success(msg);
          } else {
            let msg = "Something Went Wrong";
            toast.error(msg);
          }
        });
      }else{
        let msg = "Please Upload Resume";
        toast.warning(msg);
      }
    } else {
      sessionStorage.setItem("redirect_url_id", selectedJob?.id);
      sessionStorage.setItem("redirect_url_type", "job");
      sessionStorage.setItem("redirect_url", "yes");
      history.push("/Login");
    }
  };

  const handleOpenJobApplyProcess = async () => {
    if (currentUser) {
      getScreenersBasedOnCompanySelected(selectedJob?.company_id);
      // console.log("Job Details : ", selectedJobCompanyDetails, selectedJob);
      if (allResumes?.length !== 0) {
        setOpenJobApplyProcess(true);
      } else {
        let msg = "Please Upload Resume";
        toast.warning(msg);
      }
    } else {
      sessionStorage.setItem("redirect_url_id", selectedJob?.id);
      sessionStorage.setItem("redirect_url_type", "job");
      sessionStorage.setItem("redirect_url", "yes");
      history.push("/Login");
    }
  };
  const handleCloseJobApplyProcess = () => {
    setOpenJobApplyProcess(false);
    setScreenersData([]);
    setSelectedScreenersQuestions([]);
    setApplyProgress(0);
  };
  const handlemailClose = () => {
    setmailShow(false);
  };
  const handlemailShow = (selectedJob, selectedJobCompanyDetails) => {
    if (sessionStorage.getItem("id") !== null) {
      let mailid =
        selectedJob.contactemail !== "" && selectedJob.contactemail !== null
          ? selectedJob.contactemail
          : selectedJobCompanyDetails?.contactemail !== null
          ? selectedJobCompanyDetails?.contactemail
          : "";
      settomail(mailid);
      setmailShow(true);
      setcandfullname(currentUser?.full_name);
    } else {
      history.push({
        pathname: "/Login",
      });
    }
  };
  const handlemessageShow = () => {
    if (sessionStorage.getItem("id") !== null) {
      setmessageShow(true);
    } else {
      history.push({
        pathname: "/Login",
      });
    }
  };
  const handlemessageClose = () => {
    setmessageShow(false);
  };
  const handleChangeApplyJob = (event) => {
    setSelectedValueApplyJob(event.target.value);
    const file = allResumes?.filter(
      (res) => res.resume_id == event.target.value
    );
    setResumeFileName(file[0]);
  };

  const controlProps = (item) => ({
    checked: selectedValueApplyJob == item,
    onChange: handleChangeApplyJob,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const redirectToJobView = (job) => {
    sessionStorage.setItem("job_Name", job?.title);
    history.push({
      pathname: "/job/view/Job_" + job?.id,
      state: {
        Jobs: "yes",
      },
    });
  };
  const redirectToCompanyView = (compId) => {
    history.push("/company/view/comp_" + compId);
  };

  let isTabletOrMobile = window.innerWidth;
  const [jobSelected, setJobSelected] = useState(false);
  const [showJobDetails, setShowJobDetails] = useState(true);
  const [showRecruiterDetails, setShowRecruiterDetails] = useState(false);

  useEffect(() => {
    // Handle initial view and desktop/mobile changes
    // setShowJobDetails(true);
    if (window.innerWidth <= 768) {
      setShowJobDetails(false); // Show details initially on desktop, hide when mobile
      setShowRecruiterDetails(false);
    } else {
      setShowRecruiterDetails(true);
      setShowJobDetails(true); // Hide details on mobile or when job is selected
    }

    // console.log("location is : ", location);
  }, []);

  // function for validating Correct phone Number
  function isValidPhoneNumber(phoneString) {
    // Regular expression for a 10-digit phone number with optional parentheses and hyphens
    // const phoneRegex = /^\(?([0-9]{3})\)?[-.]?([0-9]{3})[-.]?([0-9]{4})$/;
    const phoneRegex = /^\(?([0-9]{3})\)?[ -.]?([0-9]{3})[ -.]?([0-9]{4})$/;

    // console.log("value :", phoneString);
    // console.log(phoneRegex.test(phoneString));

    // Checking if the string matches the phone number format Or not
    return phoneRegex.test(phoneString);
  }

  const Bullet = styled("span")({
    display: "inline-block",
    width: "3px",
    height: "3px",
    borderRadius: "50%",
    backgroundColor: "currentColor",
  });

  return (
    <Paper
      elevation={1}
      sx={{ height: window.innerHeight - 56, overflow: "scroll" }}
    >
      {/* Job Details ----------------------------------------------------------------------------------------------------------------------- */}
      {selectedJob ? (
        <>
          {" "}
          <Grid item xs={12}>
            <Box
              sx={{
                maxHeight: "100vh",
                overflowY: "scroll",
                position: "relative",
              }}
              ref={gridRef}
              component={Paper}
              elevation={1}
            >
              {showToolbar && Object.keys(selectedJob).length !== 0 && (
                <Toolbar
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 3,
                    backgroundColor: "#fff",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  <Card sx={{ boxShadow: "none", width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Stack direction="column">
                        <Typography
                          // onClick={() => redirectToJobView(selectedJob)}
                          component="body"
                          paragraph
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: 600,
                            cursor: "pointer",
                            margin: 0,
                            fontSize: styles.fontSize16,
                            "&:hover": {
                              textDecoration: "underline",
                              textDecorationColor: "#2196f3",
                            },
                          }}
                        >
                          {selectedJob?.title}
                        </Typography>
                        <Breadcrumbs
                          separator={<Bullet />}
                          aria-label="breadcrumb"
                        >
                          {selectedJob?.company_name && (
                            <Typography
                              paragraph
                              sx={{
                                margin: 0,
                                fontWeight: 400,
                                textTransform: "capitalize",
                                fontSize: styles.fontSize12,
                                cursor: "pointer",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={() =>
                                redirectToCompanyView(selectedJob?.company_id)
                              }
                            >
                              {selectedJob?.company_name}
                            </Typography>
                          )}
                          <Typography
                            paragraph
                            sx={{
                              margin: 0,
                              fontWeight: 400,
                              textTransform: "capitalize",
                              fontSize: styles.fontSize12,
                            }}
                          >
                            {getJobLocation(selectedJob)}
                          </Typography>
                          {/* <Typography
                          paragraph
                          sx={{
                            margin: 0,
                            color: "#757575",
                            fontWeight: 500,
                       gridRefApply to     fontSize: "14px",
                          }}
                        >
                          {selectedJob.created !== undefined &&
                          selectedJob.created !== null
                            ? moment(selectedJob.created).fromNow() !==
                              "Invalid date"
                              ? moment(selectedJob.created).fromNow()
                              : moment("2022-03-31T06:26:04.138Z").fromNow()
                            : moment("2022-03-31T06:26:04.138Z").fromNow()}
                        </Typography> */}
                        </Breadcrumbs>
                      </Stack>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "end",
                        }}
                      >
                        <Stack direction="row" gap={1}>
                          {parseInt(selectedJob?.jobapplylink) === 1 &&
                          selectedJob?.joblink &&
                          selectedJob?.joblink !== "" ? (
                            <Button
                              variant="contained"
                              disableElevation
                              size="small"
                              sx={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                "&:hover": {
                                  backgroundColor: "#406882 !important",
                                  color: "white !important",
                                },
                                bgcolor: "#53778e",
                                textTransform: "capitalize",
                              }}
                              onClick={() => applyJob(selectedJob)}
                              target="_blank"
                              href={selectedJob?.joblink}
                              disabled={apply}
                            >
                              {apply ? "Applied" : "Apply"}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              disableElevation
                              size="small"
                              sx={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                bgcolor: "#53778e",
                                color: " white",
                                textTransform: "capitalize",
                              }}
                              // onClick={() => applyJob(selectedJob)}
                              onClick={() => handleOpenJobApplyProcess()}
                              disabled={apply}
                            >
                              {apply ? "Applied" : "Apply"}
                            </Button>
                          )}
                          <Button
                            onClick={() => saveJob(selectedJob)}
                            variant="outlined"
                            size="small"
                            disabled={save}
                            sx={{
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              textTransform: "capitalize",
                              borderColor: "#53778e",
                              color: "#53778e",
                            }}
                          >
                            {save ? "Saved" : "Save"}
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Card>
                </Toolbar>
              )}
              {selectedJob && Object.keys(selectedJob).length !== 0 ? (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        borderRadius: "8px",
                        boxShadow: "none",
                        padding: "10px",
                      }}
                    >
                      <Stack direction="column" gap={2}>
                        <Stack direction="column">
                          <Typography
                            component="body"
                            variant={isMatchLGL ? "h5" : "h6"}
                            sx={{
                              textTransform: "capitalize",
                              fontWeight: 500,
                              cursor: "pointer",
                              fontSize: styles.fontSize24,
                              "&:hover": {
                                textDecoration: "underline",
                                textDecorationColor: "#2196f3",
                              },
                            }}
                            // onClick={() => redirectToJobView(selectedJob)}
                          >
                            {selectedJob?.title}
                          </Typography>
                          <Breadcrumbs
                            separator={<Bullet />}
                            aria-label="breadcrumb"
                          >
                            {selectedJob?.company_name && (
                              <Typography
                                paragraph
                                sx={{
                                  margin: 0,
                                  fontWeight: 400,
                                  textTransform: "capitalize",
                                  fontSize: styles.fontSize14,
                                  cursor: "pointer",
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                }}
                                onClick={() =>
                                  redirectToCompanyView(selectedJob?.company_id)
                                }
                              >
                                {selectedJob?.company_name}
                              </Typography>
                            )}
                            <Typography
                              paragraph
                              sx={{
                                margin: 0,
                                fontWeight: 400,
                                textTransform: "capitalize",
                                fontSize: styles.fontSize14,
                              }}
                            >
                              {selectedJob ? getJobLocation(selectedJob) : ""}
                            </Typography>
                            <Typography
                              paragraph
                              sx={{
                                margin: 0,
                                color: "#757575",
                                fontWeight: 500,
                                fontSize: styles.fontSize14,
                              }}
                            >
                              {selectedJob.modified !== undefined &&
                              selectedJob.modified !== null
                                ? moment(selectedJob.modified).fromNow() !==
                                  "Invalid date"
                                  ? moment(selectedJob.modified).fromNow()
                                  : moment("2022-03-31T06:26:04.138Z").fromNow()
                                : moment("2022-03-31T06:26:04.138Z").fromNow()}
                            </Typography>
                          </Breadcrumbs>
                        </Stack>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Stack direction="column" gap={1}>
                            <Tooltip
                              title={
                                <Typography>
                                  {/* Your complete text here */}
                                  <BusinessCenterRounded
                                    sx={{
                                      height: styles.icon20,
                                      width: styles.icon20,
                                      color: "#757575",
                                      marginRight: "5px",
                                    }}
                                  />
                                  {selectedJob.jobtype_name !== undefined &&
                                  selectedJob.jobtype_name !== null
                                    ? selectedJob.jobtype_name.join(", ")
                                    : "Full Time"}
                                  &nbsp;&nbsp;
                                  {selectedJob.noofjobs !== undefined &&
                                  selectedJob.noofjobs !== null &&
                                  parseInt(selectedJob.noofjobs) !== 0
                                    ? `(Positions : ${selectedJob.noofjobs})`
                                    : "(Positions : 1)"}
                                </Typography>
                              }
                              arrow
                              placement="top"
                              enterTouchDelay={500}
                            >
                              <Typography
                                paragraph
                                sx={{
                                  margin: 0,
                                  display: "flex",
                                  alignItems: "end",
                                  gap: "2px",
                                  fontSize: styles.fontSize14,
                                  minWidth: "80px",
                                  maxWidth: styles.jobDetailsWidth400,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {/* Your abbreviated content here */}
                                <BusinessCenterRounded
                                  sx={{
                                    height: styles.icon20,
                                    width: styles.icon20,
                                    color: "#757575",
                                    marginRight: "5px",
                                  }}
                                />
                                {selectedJob.jobtype_name !== undefined &&
                                selectedJob.jobtype_name !== null
                                  ? selectedJob.jobtype_name.join(", ")
                                  : "Full Time"}
                                &nbsp;&nbsp;
                                {selectedJob.noofjobs !== undefined &&
                                selectedJob.noofjobs !== null &&
                                parseInt(selectedJob.noofjobs) !== 0
                                  ? `(Positions : ${selectedJob.noofjobs})`
                                  : "(Positions : 1)"}
                              </Typography>
                            </Tooltip>

                            <Tooltip
                              title={
                                selectedJob.workpermit_name !== undefined &&
                                selectedJob.workpermit_name !== null
                                  ? selectedJob.workpermit_name.join(", ")
                                  : "H1"
                              }
                              arrow
                              placement="top"
                              enterTouchDelay={500}
                            >
                              <Typography
                                paragraph
                                sx={{
                                  margin: 0,
                                  fontSize: styles.fontSize14,
                                  display: "flex",
                                  alignItems: "end",
                                  gap: "2px",
                                  maxWidth: styles.jobDetailsWidth400,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <MdFlight
                                  style={{
                                    height: styles.icon20,
                                    width: styles.icon20,
                                    color: "#757575",
                                    marginRight: "5px",
                                  }}
                                />
                                {selectedJob.workpermit_name !== undefined &&
                                selectedJob.workpermit_name !== null
                                  ? selectedJob.workpermit_name.join(", ")
                                  : "H1"}
                              </Typography>
                            </Tooltip>
                            {selectedJob.source === "JNP" && (
                              <>
                                <Typography
                                  paragraph
                                  sx={{
                                    margin: 0,
                                    fontSize: styles.fontSize14,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                  }}
                                >
                                  <FaUserGraduate
                                    style={{
                                      height: styles.icon20,
                                      width: styles.icon20,
                                      color: "#757575",
                                      marginRight: "5px",
                                    }}
                                  />
                                  {selectedJob.qualifications !== undefined &&
                                  selectedJob.qualifications !== null &&
                                  selectedJob.qualifications !== ""
                                    ? selectedJob.qualifications
                                    : "Bachelor's Of Degree"}
                                </Typography>
                                <Typography
                                  paragraph
                                  sx={{
                                    margin: 0,
                                    fontSize: styles.fontSize14,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                  }}
                                >
                                  <MdOutlineWorkHistory
                                    style={{
                                      height: styles.icon20,
                                      width: styles.icon20,
                                      color: "#757575",
                                      marginRight: "5px",
                                    }}
                                  />
                                  {selectedJob?.experience === "0"
                                    ? "0 - 3 Years"
                                    : selectedJob?.experience}
                                </Typography>
                              </>
                            )}

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              <FaListCheck
                                style={{
                                  height: styles.icon20,
                                  width: styles.icon20,
                                  color: "#757575",
                                  marginRight: "5px",
                                }}
                              />
                              <Tooltip
                                title={
                                  matchedSkills.length > 0
                                    ? `${matchedSkills.length} of ${matchJobSkills.length} skills match your profile - you may be a good fit`
                                    : `Skills: ${matchJobSkills.join(",")}`
                                }
                                arrow
                                placement="top"
                                enterTouchDelay={500}
                              >
                                <Typography
                                  paragraph
                                  sx={{
                                    margin: 0,
                                    fontSize: styles.fontSize14,
                                    cursor: "pointer",
                                    maxWidth: styles.jobDetailsWidth400,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                  onClick={scrollToSkillMatch}
                                >
                                  {matchedSkills.length > 0
                                    ? `${matchedSkills.length} of ${matchJobSkills.length} skills match your profile - you may be a good fit`
                                    : `${matchJobSkills.join(",")}`}
                                </Typography>
                              </Tooltip>
                            </Box>
                          </Stack>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "end",
                            }}
                          >
                            <Stack direction="row" gap={1}>
                              {parseInt(selectedJob.jobapplylink) === 1 ? (
                                <Button
                                  variant="contained"
                                  disableElevation
                                  size="small"
                                  sx={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                    fontSize: styles.fontSize13,
                                    "&:hover": {
                                      backgroundColor: "#406882 !important",
                                      color: "white !important", // Replace with the hover color you want
                                    },
                                    bgcolor: "#53778e",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={() => applyJob(selectedJob)}
                                  target="_blank"
                                  href={selectedJob.joblink}
                                  disabled={apply}
                                >
                                  {apply ? "Applied" : "Apply"}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  disableElevation
                                  size="small"
                                  sx={{
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                    bgcolor: "#53778e",
                                    color: " white",
                                    textTransform: "capitalize",
                                    fontSize: styles.fontSize13,
                                  }}
                                  // onClick={() => applyJob(selectedJob)}
                                  onClick={() => handleOpenJobApplyProcess()}
                                  disabled={apply}
                                >
                                  {apply ? "Applied" : "Apply"}
                                </Button>
                              )}

                              <Button
                                onClick={() => saveJob(selectedJob)}
                                disabled={save}
                                variant="outlined"
                                size="small"
                                sx={{
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                  fontSize: styles.fontSize13,
                                  textTransform: "capitalize",
                                  borderColor: "#53778e",
                                  color: "#53778e",
                                }}
                              >
                                {save ? "Saved" : "Save"}
                              </Button>
                            </Stack>
                          </Box>
                        </Box>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card sx={{ boxShadow: "none", padding: "10px" }}>
                      <Typography
                        paragraph
                        sx={{ fontWeight: 500, fontSize: styles.fontSize13 }}
                      >
                        About the job
                      </Typography>
                      <Typography paragraph>
                        {selectedJob.description !== null &&
                        selectedJob.description !== undefined &&
                        selectedJob.description
                          ? !selectedJob.description.includes("ahref") &&
                            !selectedJob.description.includes("divclass") &&
                            !selectedJob.description.includes("aclass") &&
                            !selectedJob.description.includes("pclass") &&
                            !selectedJob.description.includes("liclass")
                            ? parse(selectedJob.description)
                            : selectedJob.description.replace(/<[^>]+>/g, "")
                          : ""}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <div ref={skillMatchRef}>
                      {Object.keys(primaryResumeDetails).length > 0 ? (
                        <Card
                          sx={{
                            boxShadow: "none",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            p: 2,
                            minHeight: "900px",
                            // bgcolor:'#ffefcd'
                          }}
                        >
                          <CardHeader
                            title={
                              <Typography
                                paragraph
                                style={{
                                  fontSize: styles.fontSize18,
                                  fontWeight: 400,
                                  margin: 0,
                                }}
                              >
                                How you{" "}
                                <span
                                  style={{
                                    fontWeight: 500,
                                    // textDecoration: "underline",
                                    color: "#e4a43c",
                                  }}
                                >
                                  {` (${primaryResumeDetails?.application_title})`}
                                </span>{" "}
                                match
                              </Typography>
                            }
                          />
                          <CardContent
                            sx={{
                              bgcolor: "#ffefcd",
                              // bgcolor:'#CFE4FF',
                              height: "auto",
                              maxheight: "40vh",
                              overflow: "scroll",
                              borderRadius: "6px",
                            }}
                          >
                            {matchedSkills.length / 2 >
                            matchResumeSkills.length / 2 ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  "&:hover": {
                                    textu: {
                                      textDecoration: "underline",
                                      color: "blue",
                                    },
                                  },
                                }}
                              >
                                <WorkspacePremiumRounded
                                  sx={{
                                    color: "#e4a43c",
                                    height: "36px",
                                    width: "36px",
                                    mr: "5px",
                                  }}
                                />
                                <p
                                  style={{
                                    fontSize: styles.fontSize14,
                                    margin: 0,
                                  }}
                                  // onClick={matchedSkills}
                                  // onClick={() => setMatchedSkillsOpen(true)}
                                >
                                  <span
                                    className={`${
                                      matchedSkills?.length > 0
                                        ? "match-text"
                                        : ""
                                    }`}
                                  >
                                    {matchedSkills?.length} of{" "}
                                    {matchJobSkills.length > 0
                                      ? matchJobSkills.length
                                      : 0}{" "}
                                    skills matched it may be a good fit based on
                                    skills with experience for&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      background: "#e4a43c",
                                      fontWeight: 500,
                                      paddingInline: "7px",
                                      color: "#fff",
                                      borderRadius: "4px",
                                      margin: 0,
                                    }}
                                  >
                                    {primaryResumeDetails?.application_title}
                                  </span>
                                </p>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  "&:hover": {
                                    ".match-text": {
                                      color: "#406882",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    },
                                  },
                                }}
                              >
                                <AssignmentLateRounded
                                  sx={{
                                    color: "#e4a43c",
                                    height: "36px",
                                    width: "36px",
                                    mr: "5px",
                                  }}
                                />
                                <Typography
                                  variant="body1"
                                  paragraph
                                  style={{ fontSize: styles.fontSize14 }}
                                  // onClick={matchedSkills}
                                  // onClick={() =>
                                  //   matchedSkillsList?.length > 0 &&
                                  //   setMatchedSkillsOpen(true)
                                  // }
                                >
                                  <span
                                    className={`${
                                      matchedSkills?.length > 0
                                        ? "match-text"
                                        : ""
                                    }`}
                                  >
                                    {matchedSkills?.length} of{" "}
                                    {matchJobSkills.length > 0
                                      ? matchJobSkills.length
                                      : 0}{" "}
                                    skills matched based on skills with
                                    experience for&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      background: "#e4a43c",
                                      fontWeight: 500,
                                      paddingInline: "7px",
                                      color: "#fff",
                                      borderRadius: "4px",
                                      margin: 0,
                                    }}
                                  >
                                    {primaryResumeDetails?.application_title}
                                  </span>
                                </Typography>
                              </Box>
                            )}
                            {matchedSkills.length > 0 ? (
                              <List
                                sx={{
                                  height: "auto",
                                  maxHeight: "30vh",
                                  overflowY: "scroll",
                                }}
                              >
                                <Grid container spacing={0}>
                                  {matchedSkills?.map((skill, index) => (
                                    <Grid item xs={6}>
                                      <ListItem key={index} sx={{ p: 0 }}>
                                        <ArrowRightOutlined />{" "}
                                        <ListItemText primary={skill} />
                                      </ListItem>
                                    </Grid>
                                  ))}
                                </Grid>
                              </List>
                            ) : null}
                          </CardContent>
                          <CardContent>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <TableContainer
                                  component={Paper}
                                  sx={{ maxHeight: 400 }}
                                >
                                  <Table
                                    aria-label="customized table"
                                    stickyHeader
                                    size="small"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell>
                                          Job Skills
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {matchJobSkills?.map((row) => (
                                        <StyledTableRow key={row}>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {row}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                              <Grid item xs={6}>
                                <TableContainer
                                  component={Paper}
                                  sx={{ maxHeight: 400 }}
                                >
                                  <Table
                                    aria-label="customized table"
                                    stickyHeader
                                    size="small"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell>
                                          Resume Skills
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {matchResumeSkills?.map((row) => (
                                        <StyledTableRow key={row}>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {row}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      ) : (
                        <Card
                          sx={{
                            boxShadow: "none",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            p: 2,
                            minHeight: "900px",
                            // bgcolor:'#ffefcd',
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <TableContainer
                              component={Paper}
                              sx={{ width: 400 }}
                            >
                              <Table
                                aria-label="customized table"
                                stickyHeader
                                size="small"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>
                                      <Box sx={{ textAlign: "center" }}>
                                        Job Skills
                                      </Box>
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedJob?.ai_skills?.map((row) => (
                                    <StyledTableRow key={row}>
                                      <StyledTableCell
                                        component="th"
                                        scope="row"
                                      >
                                        {row}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Card>
                      )}
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Card>No Data Found...</Card>
              )}
            </Box>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Paper
            elevation={2}
            sx={{
              height: "50px",
              overflow: "scroll",
              borderRadius: "8px",
              padding: "5px",
            }}
          >
            <>No Data Found </>
          </Paper>
        </Grid>
      )}
      <Modal
        open={openJobApplyProcess}
        // onClose={handleCloseJobApplyProcess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleApplyModal} component={Card}>
          <CardHeader
            sx={{ pb: 1, pt: 1, borderBottom: 1, borderBottomColor: "divider" }}
            title={
              <Typography sx={{}}>
                Apply to {selectedJob?.companyname}{" "}
              </Typography>
            }
            action={
              <CloseRounded
                onClick={handleCloseJobApplyProcess}
                sx={{ cursor: "pointer" }}
              />
            }
          />
          <CardContent sx={{ pt: 1 }}>
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={applyProgress} />
            </Box>
            <Box sx={{ display: applyProgress < 50 ? "block" : "none" }}>
              <Typography paragraph sx={{ mb: 2, fontWeight: 500 }}>
                Contact info
              </Typography>
              <Stack direction="row" gap={1} sx={{ mb: 2 }}>
                <Avatar
                  alt={currentUser?.full_name}
                  src={`${Key.files_domain}/dashboard/profile_image/${currentUser?.id}/${currentUser?.image}`}
                  sx={{ height: "60px", width: "60px" }}
                />
                <Stack direction="column">
                  <Typography paragraph sx={{ m: 0, fontWeight: 500 }}>
                    {currentUser?.full_name}
                  </Typography>
                  <Typography
                    paragraph
                    sx={{ m: 0, fontSize: "14px", color: "#757575" }}
                  >
                    {primaryResumeDetails?.application_title}
                  </Typography>
                  <Typography
                    paragraph
                    sx={{ m: 0, fontSize: "12px", color: "#757575" }}
                  >
                    {primaryResumeDetails?.cityname +
                      ", " +
                      primaryResumeDetails?.shortRegion +
                      ", " +
                      primaryResumeDetails.countryname}
                  </Typography>
                </Stack>
              </Stack>
              <Typography paragraph sx={{ mb: 0 }}>
                <span style={{ fontWeight: 500, fontSize: "14px" }}>
                  Email Address:{" "}
                </span>
                {currentUser?.username}
              </Typography>
              <Typography paragraph sx={{ mb: 0 }}>
                <span style={{ fontWeight: 500, fontSize: "14px" }}>
                  Mobile Phone Number:{" "}
                </span>
                {currentUser?.phone_number !== null
                  ? currentUser?.phone_number
                  : "918273734"}
              </Typography>
            </Box>
            <Box sx={{ display: applyProgress > 49 ? "block" : "none" }}>
              <Typography paragraph sx={{ mb: 2, fontWeight: 500 }}>
                Resume
              </Typography>

              <Grid container spacing={2}>
                {allResumes?.map((resume, index) => (
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        boxShadow: "none",
                        borderRadius: "8px",
                        border: "1px solid #ddd",
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={1.5}
                          sx={{
                            bgcolor:
                              resume.filetype === ".pdf"
                                ? "#d32f2f"
                                : "#1976d2",
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {resume.filetype === ".pdf" ? "PDF" : "Docs"}
                        </Grid>
                        <Grid item xs={9.5} sx={{ p: 2 }}>
                          <Typography paragraph sx={{ mb: 0, fontWeight: 500 }}>
                            {resume.filename}
                          </Typography>
                          <Typography
                            paragraph
                            sx={{ mb: 0, fontSize: "12px", color: "#757575" }}
                          >
                            {resume.created !== undefined &&
                            resume.created !== null
                              ? moment(resume.created).fromNow() !==
                                "Invalid date"
                                ? moment(resume.created).fromNow()
                                : moment("2022-03-31T06:26:04.138Z").fromNow()
                              : moment("2022-03-31T06:26:04.138Z").fromNow()}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Radio
                            {...controlProps(resume.resume_id)}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 28,
                              },
                            }}
                          />
                          {/* <Radio
checked={selectedValueApplyJob == resume.resume_id}
onChange={handleChangeApplyJob}
value={resume.resume_id}
name='size-radio-button-demo'
inputProps={{ 'aria-label': resume.resume_id }}
/> */}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
                mt: 2,
              }}
            >
              <Stack direction="row" gap={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ display: applyProgress > 0 ? "block" : "none" }}
                  onClick={() => {setApplyProgress(0); 
                    sessionStorage.removeItem("redirect_url_id");
                    sessionStorage.removeItem("redirect_url_type");
                    sessionStorage.removeItem("redirect_url");}}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ display: 50 > applyProgress > 0 ? "block" : "none" }}
                  onClick={async () => {
                    setApplyProgress(50);
                    await getAllResumes();
                  }}
                >
                  Next
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ display: applyProgress === 50 ? "block" : "none" }}
                  onClick={async () => {
                    setApplyProgress(80);
                  }}
                >
                  Next{" "}
                </Button>
                <Button
                  variant="contained"
                  sx={{ display: applyProgress > 79 ? "block" : "none" }}
                  onClick={() => {
                    applyJob(selectedJob);
                  }}
                >
                  Apply
                </Button>
              </Stack>
            </Box>
          </CardContent>
        </Box>
      </Modal>
      {Object.keys(primaryResumeDetails).length > 0 ? (
        <Dialog
          open={messageshow}
          onClose={handlemessageClose}
          fullWidth
          maxWidth="sm"
          aria-labelledby="dialog-title"
        >
          <DialogTitle id="dialog-title">
            <Typography variant="h6">Message</Typography>
            <IconButton
              aria-label="close"
              onClick={handlemessageClose}
              style={{ position: "absolute", right: "10px", top: "2px" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Messagepopup handlemessageClose={handlemessageClose} />
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}

      <Dialog
        open={mailshow}
        onClose={handlemailClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">
          <Typography variant="h6"> Send Mail To Recruiter</Typography>
          <IconButton
            aria-label="close"
            onClick={handlemailClose}
            style={{ position: "absolute", right: "10px", top: "2px" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Sendcandidatetomail
            handlemailClose={handlemailClose}
            tomail={tomail}
            candfullname={candfullname}
            fromempEmail={currentUser?.username}
          />
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default JobDetail;

const styleApplyModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  borderRadius: "8px",
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

// const BlurredBackdrop = styled(Backdrop)(({ theme }) => ({
//   backdropFilter: "blur(8px)",
//   backgroundColor: "rgba(255, 255, 255, 0.5)",
// }));

// function BlurredModal({ open, onClose, children }) {
//   return (
//     <Modal
//       open={open}
//       onClose={onClose}
//       BackdropComponent={BlurredBackdrop}
//       BackdropProps={{
//         sx: { backdropFilter: "blur(2px)" },
//       }}
//     >
//       {children}
//     </Modal>
//   );
// }

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0,
  borderRadius: "6px",
  maxHeight: "70vh",
  overflow: "scroll",
};
