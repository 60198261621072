import React, { useEffect, useRef, useState } from 'react'
import AdvancedSearch from './AdvancedSearch';
import { useLocation } from 'react-router-dom';
import {
    Grid,
    Paper,
    Box,
    Typography,
    Stack,
    Card,
    useMediaQuery,
    useTheme,
    Badge,
    Modal,
    CardContent,
    CardHeader,
    LinearProgress,
  } from "@mui/material";
  import PropTypes from "prop-types";
  import Tabs from "@mui/material/Tabs";
  import Tab from "@mui/material/Tab";
  import axios from "axios";
  import {
    Avatar,
    Button,
    ButtonGroup,
    Divider,
    IconButton,
    List,
    ListItemButton,
    Tooltip,
    MuiTooltip,
    Toolbar,
  } from "@mui/material";
  import Dialog from "@mui/material/Dialog";
  import DialogActions from "@mui/material/DialogActions";
  import DialogContent from "@mui/material/DialogContent";
  import DialogContentText from "@mui/material/DialogContentText";
  import DialogTitle from "@mui/material/DialogTitle";
  import moment from "moment";
  import {
    AddRounded,
    CloseRounded,
    EditRounded,
    FilterListRounded,
  } from "@mui/icons-material";
import { openAIExtracteAISearchValues } from './extractSearchValuesAI';
import ResumeMain from '../general/ResumesMain';

const AI_Main_Search = () => {
    const location = useLocation();
    const [openAiForm1Modal, setOpenAiForm1Modal] = useState(false);
    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(10);
    const [openAImessage, setOpenAImessage] = useState("");
    const [aiData, setAIData] = useState(null);
    const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
    const progressRef = useRef(() => {});

    useEffect(() => {
        setOpenAiForm1Modal(true);
    }, [])


    useEffect(() => {
        progressRef.current = () => {
          if (progress === 0) {
            setProgress(0);
            setBuffer(10);
          } else {
            const diff = Math.random() * 10;
            const diff2 = Math.random() * 10;
            setProgress(progress + diff);
            setBuffer(progress + diff + diff2);
          }
        };
      }, [progress]);
    
      useEffect(() => {
        const timer = setInterval(() => {
          progressRef.current();
        }, 500);
    
        return () => {
          clearInterval(timer);
        };
      }, [progress]);


      const submitAIHandler = async () => {
        // console.log("AIMessage", openAImessage);
        setProgress(2);
        const data = await new Promise((resolve, reject) => {
          openAIExtracteAISearchValues(openAImessage, (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          });
        });
        setAIData(data);
        setOpenAiForm1Modal(false);
        setAdvancedSearchOpen(true);
        // await dispatch(updateResumeSearchParams({
        //   jobType: data.jobtype,
        //   state: data.state,
        //   title: data.job_title,
        //   visaType: data.visatype,
        //   skills: data.skills,
        //   relatedTitles: data.job_roles,
        //   experience: data.experience
        // }));
        // console.log("AIMessage AI Result", data);
      };

  return (
    <div>
        <Modal
        open={openAiForm1Modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box component={Card} sx={AiForm1ModalStyles}>
          <CardHeader
            title={
              <h2
                className=""
                style={{
                  fontSize: false ? "17px" : "19px",
                  color: "#406882",
                  fontWeight: 600,
                }}
              >
                {" "}
                Find A PERFECT RESUME WITH{" "}
                <span
                  style={{
                    backgroundColor: "#e97d44",
                    padding: "4px",
                    borderRadius: "6px",
                    color: "#fff",
                  }}
                >
                  AI
                </span>
                &nbsp;
                <sub style={{ fontWeight: "100", fontSize: "12px" }}>
                  ( Beta Version )
                </sub>
              </h2>
            }
            action={
              <IconButton
                // onClick={handleCloseSearchHandler}
                onClick={() => setOpenAiForm1Modal(false)}
              >
                <CloseRounded />
              </IconButton>
            }
          />
          <CardContent>
            <form autocomplete="on">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <textarea
                    type="text"
                    style={{
                      userSelect: "none",
                      borderRadius: "6px",
                      padding: "10px 0px 0px 10px",
                    }}
                    rows={6}
                    class="form-control no-drag"
                    value={
                      openAImessage !== ""
                        ? openAImessage.replace(/"/g, "")
                        : ""
                    }
                    placeholder="Paste Job Description here..."
                    onChange={(e) => {
                      setOpenAImessage(e.target.value);
                    }}
                  ></textarea>
                </Grid>
                <Grid item xs={12} textAlign="end">
                  <Button
                    type="button"
                    variant="contained"
                    disabled={progress > 0}
                    // onClick={() => { submit(1); setProgress(2); }}
                    onClick={submitAIHandler}
                    sx={{
                      bgcolor: "rgba(64, 104, 130, 0.9)",
                      "&:hover": { bgcolor: "#406882" },
                    }}
                  >
                    AI Search
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: progress > 0 ? "block" : "none" }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress
                      variant="buffer"
                      value={progress}
                      valueBuffer={buffer}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Box>
      </Modal>
      <AdvancedSearch
        isOpen={advancedSearchOpen}
        setAdvancedSearchOpen={setAdvancedSearchOpen}
        setOpenAiForm1Modal={setOpenAiForm1Modal}
        setProgress={setProgress}
        aiData={aiData}
      />
      {
        !advancedSearchOpen && !openAiForm1Modal &&
        <ResumeMain />
      }
    </div>
  )
}

export default AI_Main_Search;


const AiForm1ModalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "1px solid #ddd",
    boxShadow: 24,
    // p: 4,
    borderRadius: "8px",
  };