
import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100px',
  height: '100px'
};

const AddressMap = ({ address }) => {
  const [mapCenter, setMapCenter] = useState({ lat: 37.5485, lng: -121.9886 });
  const [error, setError] = useState(null);

  useEffect(() => {
    // console.log('Address changed:', address); 

    const fetchCoordinates = async () => {
      const apiKey = 'AIzaSyDoLvf__xB8K418QeEXwpT2SqMtzTrSC2s';
      const encodedAddress = encodeURIComponent(address);
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        // console.log('Fetched data:', data);

        if (data.status === 'OK') {
          const location = data.results[0].geometry.location;
          setMapCenter({ lat: location.lat, lng: location.lng });
        } else {
          setError(data.error_message || 'Unable to fetch coordinates');
          console.error(data.error_message);
        }
      } catch (err) {
        setError('An error occurred while fetching the coordinates.');
      }
    };

    fetchCoordinates();
  }, [address]);

  return (
    <LoadScript googleMapsApiKey="AIzaSyDoLvf__xB8K418QeEXwpT2SqMtzTrSC2s">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={14}
        options={{
          disableDefaultUI: true,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false
        }}
      >
        <Marker position={mapCenter} />
      </GoogleMap>
      {/* {error && <div>{error}</div>} */}
    </LoadScript>
  );
};

export default AddressMap;
