import React, { useEffect, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import GC_Approvals_Denails from "./GC_Approvals_Denails";

const GC = ({ companyData }) => {
  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        PERM Labor Certification Approvals for {companyData?.name}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <GC_Approvals_Denails companyData={companyData} />

        <Typography variant="body2" sx={{ mt: 2, mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year extends from October 1 until September 30.
        </Typography>
        {/* <Typography variant="body2" sx={{ mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year spans from October 1st to September 30th.
        </Typography> */}
      </Box>
    </Box>
  );
};

export default GC;
