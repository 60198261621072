import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  useMediaQuery,
} from "@mui/material";
import { useResponsiveStyles } from "../../../../../Styles/responsiveStyles";
import { useDispatch, useSelector } from "react-redux";
import { updateResumePaginationParams } from "../../../../Actions/resumeSearchActions";

const RowSelect = () => {
  const styles = useResponsiveStyles();
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.searchResumeReducer);
  const {
    searchParams = {},
    filterParams = {},
    paginationParams = {},
    results = [],
    numFound,
    selectedResume = null,
    error,
  } = searchState || {};

  const isMatchLGL = useMediaQuery("(min-width:1025px)");
  const handleRowsChange = (event) => {
    dispatch(updateResumePaginationParams({ ["rows"]: event.target.value }));
  };

  return (
    <FormControl sx={{ minWidth: isMatchLGL ? 140 : 100 }} size="small">
      <InputLabel id="job-rows-label" sx={{ fontSize: styles.fontSize14 }}>
        Rows per page
      </InputLabel>
      <Select
        labelId="job-rows-label"
        id="job-rows-select"
        value={paginationParams.rows}
        onChange={handleRowsChange}
        input={<OutlinedInput label="Rows per page" />}
        sx={{ fontSize: styles.fontSize14 }}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={30}>30</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </FormControl>
  );
};

export default RowSelect;
