import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Paper, Link, Typography, Tooltip, Button } from "@mui/material";
import { useEffect, useState } from "react";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 250,
    editable: false,
    renderCell: (params) => (
      <Box>
        {params.row.source === "visa" ? (
          params.row.name
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              alignItems: "center",
              height: "auto",
            }}
          >
            <Tooltip title={params.row.name} followCursor>
              <Typography
                variant="body2"
                sx={{
                  width: 180,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {params.row.name}
              </Typography>
            </Tooltip>
            <Box>
              {params.row.linkedin && (
                <Box
                  component={Link}
                  sx={{
                    paddingInline: "7px",
                    borderRadius: "4px",
                    "&:hover": {
                      boxShadow: "0px 10px 20px -5px rgba(6, 36, 92, 0.06)",
                      transform: "translateY(-5px)",
                      transition: "all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1)",
                    },
                  }}
                  href={params.row.linkedin}
                  target="_blank"
                >
                  <img
                    src="https://cdn.uconnectlabs.com/wp-content/uploads/sites/46/2022/08/Linkedin-Logo-e1660320077673-300x93.png"
                    width={50}
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    ),
  },
  {
    field: "role",
    headerName: "Role",
    width: 120,
    editable: false,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 120,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 120,
    editable: false,
  },
  {
    field: "address",
    headerName: "Address",
    width: 120,
    editable: false,
  },
  {
    field: "city",
    headerName: "City",
    width: 100,
    editable: false,
  },
  {
    field: "state",
    headerName: "State",
    width: 100,
    editable: false,
  },
];

export default function EmployeeListView({ companyData, employeeData }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const rowDataApolo = employeeData.map((data) => ({
      id: data?.ID,
      name: data?.EMP_CONTACT_NAME,
      role: data?.EMP_DECL_TITLE,
      phone: data?.EMP_CONTACT_PHONE || "N/A",
      email: data?.EMP_CONTACT_EMAIL || "N/A",
      linkedin: data?.EMP_LINKEDIN_URL || null,
      address: data?.EMP_CONTACT_ADDRESS_1 || "N/A",
      city: data?.EMP_CONTACT_CITY || "N/A",
      state: data?.EMP_CONTACT_STATE_PROVINCE || "N/A",
      source: "apolo",
    }));

    const rowDataVisa = companyData?.visa_contacts_data?.map((data) => ({
      id: data?.id,
      name: data?.contact_name,
      role: data?.title,
      phone: data?.contact_phone || "N/A",
      email: data?.contact_email || "N/A",
      linkedin: null,
      address: data?.contact_address || "N/A",
      city: data?.contact_city || "N/A",
      state: data?.contact_state || "N/A",
      source: "visa",
    }));

    const sortedRows = [...rowDataVisa, ...rowDataApolo].sort((a, b) =>
      a.source === "visa" ? -1 : 1
    );

    setRows(sortedRows);
  }, [companyData, employeeData]);

  const handleExport = () => {
    const csvData = rows.map(row => ({
      ID: row.id,
      Name: row.name,
      Role: row.role,
      Phone: row.phone,
      Email: row.email,
      Address: row.address,
      City: row.city,
      State: row.state,
    }));

    const csvContent = [
      Object.keys(csvData[0]).join(','),
      ...csvData.map(row => Object.values(row).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'employee_data.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Box component={Paper} sx={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        // checkboxSelection
        disableRowSelectionOnClick
        // slots={{
        //   toolbar: () => (
        //     <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
        //       <GridToolbar /> 
        //       {/* https://upload.wikimedia.org/wikipedia/commons/7/73/Microsoft_Excel_2013-2019_logo.svg */}
        //       {/* <Button variant="contained" onClick={handleExport}>
        //         Export to CSV
        //       </Button> */}
        //       <Box
        //     component={Button}
        //     sx={{
        //     //   border: "1px solid #ddd",
        //     //   p: "5px 10px",
        //     //   borderRadius: "8px",
        //       "&:hover": {
        //         boxShadow: "0px 10px 20px -5px rgba(6, 36, 92, 0.06)",
        //         transform: "translateY(-5px)",
        //         transition: "all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1)",
        //       },
        //     }}
        //     onClick={handleExport}
        //   >
        //     {/* <FaLinkedin style={{ color: "#2867B2", fontSize: "30px" }} /> */}
        //     <img
        //       src="https://upload.wikimedia.org/wikipedia/commons/7/73/Microsoft_Excel_2013-2019_logo.svg"
        //       width={20}
        //     />
        //   </Box>
        //     </div>
        //   ),
        // }}
      />
    </Box>
  );
}
