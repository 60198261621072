import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

const rowData = [
  {
    lcaCaseNumber: "I-200-24152-058131",
    h1bJobTitle: "Java Developer",
    cityState: "San Leandro, CA",
    baseSalary: "$96,595",
    startDate: "03-Jun-2024",
    prevailingWageLevel: "Level II",
    status: "Certified",
  },
  {
    lcaCaseNumber: "I-200-24152-058132",
    h1bJobTitle: "Python Developer",
    cityState: "San Leandro, CA",
    baseSalary: "$96,595",
    startDate: "03-Jun-2024",
    prevailingWageLevel: "Level II",
    status: "Certified",
  },
  {
    lcaCaseNumber: "I-200-24152-058133",
    h1bJobTitle: "SQL Developer",
    cityState: "San Leandro, CA",
    baseSalary: "$96,595",
    startDate: "03-Jun-2024",
    prevailingWageLevel: "Level II",
    status: "Certified",
  },
  {
    lcaCaseNumber: "I-200-24152-058134",
    h1bJobTitle: "Salesforce Developer",
    cityState: "San Leandro, CA",
    baseSalary: "$96,595",
    startDate: "03-Jun-2024",
    prevailingWageLevel: "Level II",
    status: "Certified",
  },
  {
    lcaCaseNumber: "I-200-24152-058135",
    h1bJobTitle: "Program Analyst",
    cityState: "San Leandro, CA",
    baseSalary: "$96,595",
    startDate: "03-Jun-2024",
    prevailingWageLevel: "Level II",
    status: "Certified",
  },
  {
    lcaCaseNumber: "I-200-24152-058136",
    h1bJobTitle: "React Developer",
    cityState: "San Leandro, CA",
    baseSalary: "$96,595",
    startDate: "03-Jun-2024",
    prevailingWageLevel: "Level II",
    status: "Certified",
  },
  {
    lcaCaseNumber: "I-200-24152-058137",
    h1bJobTitle: "Full Stack Developer",
    cityState: "San Leandro, CA",
    baseSalary: "$96,595",
    startDate: "03-Jun-2024",
    prevailingWageLevel: "Level II",
    status: "Certified",
  },
  {
    lcaCaseNumber: "I-200-24152-058138",
    h1bJobTitle: "Tableau Developer",
    cityState: "San Leandro, CA",
    baseSalary: "$96,595",
    startDate: "03-Jun-2024",
    prevailingWageLevel: "Level II",
    status: "Certified",
  },
  {
    lcaCaseNumber: "I-200-24152-058139",
    h1bJobTitle: "BI Developer",
    cityState: "San Leandro, CA",
    baseSalary: "$96,595",
    startDate: "03-Jun-2024",
    prevailingWageLevel: "Level II",
    status: "Certified",
  },
];

const LCA_Data = ({ companyData }) => {
  const history = useHistory();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (companyData?.id) {
      getLcaData();
    }
  }, [companyData]);

  const handleClick = (redirect) => {
    history.push(`/companies/${redirect}`);
  };

  const getLcaData = async () => {
    try {
      const response = await axios.post(
        `https://apps.jobsnprofiles.com/company/immigration_data_lca`,
        {
          company_id: companyData?.id,
        }
      );

      if (response.status === 200) {
        const data = response.data.data.data.lca_immigration_data;
        console.log(response.data.data);

        const extractedData = data.map((item, index) => ({
          id: item.id || index,
          lcaCaseNumber: item.CASE_NUMBER,
          h1bJobTitle: item.JOB_TITLE,
          cityState: `${item.WORKSITE_CITY}, ${item.WORKSITE_STATE}`,
          startDate: item.BEGIN_DATE,
          baseSalary: item.PREVAILING_WAGE,
          prevailingWageLevel: item.PW_WAGE_LEVEL,
          status: item.CASE_STATUS,
        }));

        setRows(extractedData);
        console.log(extractedData);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      field: "lcaCaseNumber",
      headerName: "LCA Case Number",
      width: 180,
      renderCell: (params) => (
        <span
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleClick(params.value)}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "h1bJobTitle",
      headerName: "H1B Job Title",
      width: 160,
      renderCell: (params) => (
        <span
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleClick(params.value)}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "cityState",
      headerName: "Location (City, State)",
      width: 200,
      renderCell: (params) => (
        <span
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleClick(params.value)}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "baseSalary",
      headerName: "Base Salary",
      width: 150,
    },
    {
      field: "prevailingWageLevel",
      headerName: "Prevailing Wage Level",
      width: 120,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
    },
  ];

  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
        List of H1B Labor Condition Applications (LCA) for {companyData?.name} -
        Fiscal Year* 2023 & 2024
      </Typography>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        
      <Typography variant="body2" sx={{ mt: 2, mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year extends from October 1 until September 30.
        </Typography>
        {/* <Typography variant="body2" sx={{ mb: 0.5 }}>
        *The U.S. Department of Labor's fiscal year spans from October 1st to September 30th.
        </Typography> */}
      </Box>
    </Box>
  );
};

export default LCA_Data;
