
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Key from "../../clientVariables.json";
import WorkIcon from "@mui/icons-material/Work";
import { ListItemIcon } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  BusinessCenterOutlined,
  BusinessCenterRounded,
  BusinessRounded,
  CloseRounded,
  HistoryOutlined,
  KeyboardReturnRounded,
  LocationOnOutlined,
  LocationOnRounded,
  StarBorderRounded,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { clearURLjobSearchUrlActions, setURLjobSearchUrlActions } from "../Actions/jobSearchUrlActions";
import { useDispatch, useSelector } from "react-redux";
import { useResponsiveStyles } from "../../Styles/responsiveStyles";
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material';

const CustomTextField = styled(Input)(({ theme }) => ({
  "& .MuiInputBase-input::placeholder": {
    color: alpha(theme.palette.text.primary, 0.5),
  },
}));

const SearchComponent = ({ closeSearch, setCurrentPage, setPageStartNum }) => {
  const [searchValue, setSearchValue] = useState("");
  const [jobTitles, setJobTitles] = useState([]);
  const [jobLocations, setJobLocations] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [chipsTitle, setChipsTitle] = useState([]);
  const [chipsLocation, setChipsLocation] = useState([]);
  const [chipsCompany, setChipsCompany] = useState([]);
  const styles = useResponsiveStyles();
  const redirectionSearchJobsUrl = useSelector(
    (state) => state.jobSearchUrlReducer
  );
  const currentUser = useSelector((state) => state.UserReducer.user);

  // const [finalData, setFinalData] = useState([]);


  useEffect(() => {
    const title = redirectionSearchJobsUrl.searchText ? redirectionSearchJobsUrl.searchText : null;
    const loc = redirectionSearchJobsUrl.location ? redirectionSearchJobsUrl.location : null;
    const company = redirectionSearchJobsUrl.companyName ? redirectionSearchJobsUrl.companyName : null;
    if(title){
      setChipsTitle((prev) => [...prev, title]);
      
    }
    if(loc && typeof loc === 'string'){
      if(loc.includes("L_O_C")){
        const spiltLoc = loc.split("L_O_C");
        setChipsLocation((prev) => [...prev, ...spiltLoc]);
      }else{
        setChipsLocation((prev) => [...prev, loc]);
      }
    }
    if(company && typeof company === 'string') {
      if(company.includes(" OR ")){
        const spiltcompany = company.split(" OR ");
        setChipsCompany((prev) => [...prev, ...spiltcompany]);
      }else{
        setChipsCompany((prev) => [...prev, company]);
      }
      
    }
    
  }, []);

  

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
    getLocations(event.target.value);
    getJobTitles(event.target.value);
    getCompanies(event.target.value);
  };

  const handleAddChip = (value, type) => {

    if (type === 'title' && value.trim() !== '') {
      setChipsTitle([...chipsTitle, value.trim()]);
      setSearchValue('');
    }else if (type === 'location' && value.trim() !== '') {
      setChipsLocation([...chipsLocation, value.trim()]);
      setSearchValue('');
    }else if(type === 'company'){
      setChipsCompany([...chipsCompany, value.trim()]);
      setSearchValue('');
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    
    setChipsLocation((chips) => chips.filter((chip) => chip !== chipToDelete));
    setChipsTitle((chips) => chips.filter((chip) => chip !== chipToDelete));
    setChipsCompany((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPageStartNum(0);
    setCurrentPage(1);
    // jobsPageHanlder(searchValue, null);
    const joinTitles = chipsTitle.length > 0 ? chipsTitle.join(", ") : null;
    const joinLocation = chipsLocation.length > 0 ? chipsLocation.join(" L_O_C ") : null;
    const joinCompany = chipsCompany.length > 0 ? chipsCompany.join(" OR ") : null;

    if(joinTitles && joinLocation && joinTitles !== '' && joinLocation !== '' && joinCompany && joinCompany !== ''){
      dispatch(setURLjobSearchUrlActions("", joinTitles, joinLocation, null, joinCompany));
  }
  else if(joinTitles && joinLocation && joinTitles !== '' && joinLocation !== ''){
    dispatch(setURLjobSearchUrlActions("", joinTitles, joinLocation, null, null));
  }
  else if(joinLocation && joinCompany && joinLocation !== '' && joinCompany !== ''){
    dispatch(setURLjobSearchUrlActions("", null, joinLocation, null, joinCompany));
  }
  else if(joinCompany && joinTitles && joinCompany !== '' && joinTitles !== ''){
    dispatch(setURLjobSearchUrlActions("", joinTitles, null, null, joinCompany));
  }
  else if(joinTitles && joinTitles !== ''){
      dispatch(setURLjobSearchUrlActions("", joinTitles, null, "title", null));
  }else if(joinLocation && joinLocation !== ''){
      dispatch(setURLjobSearchUrlActions("", null, joinLocation, 'location', null));
  }else if(joinCompany && joinCompany !== ""){
    dispatch(setURLjobSearchUrlActions("", null, null, 'company', joinCompany));
  }else{
    if(searchValue && searchValue !== ''){
    dispatch(setURLjobSearchUrlActions("", searchValue, null, null, null));
    }else{
      dispatch(clearURLjobSearchUrlActions());
    }
    
  }
    
    // console.log("loc and title", joinTitles, joinLocation, joinCompany);
    closeSearch(false);
    if(currentUser){
      history.push("/jobs");
    }else{
      history.push("/searchJobs_listview");
    }
    
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };


  const jobsPageHanlder = (title, searchtype) => {
    if(searchtype === 'title'){
      handleAddChip(title, searchtype);
        // dispatch(setURLjobSearchUrlActions("", title, null, searchtype));
    }else if(searchtype === 'location'){
      handleAddChip(title, searchtype);
        // dispatch(setURLjobSearchUrlActions("", null, title, searchtype));
    }else if (searchtype === "company"){
      handleAddChip(title, searchtype);
        // dispatch(setURLjobSearchUrlActions("", title, null, searchtype));
    }
    else if(searchtype === null){
        if(jobLocations.length > 0 && jobTitles.length > 0){
            dispatch(setURLjobSearchUrlActions("", jobTitles[0].title, `${jobLocations[0].cityName}, ${jobLocations[0].stateName}`, searchtype, null));
        }else if(jobTitles.length > 0){
            dispatch(setURLjobSearchUrlActions("", jobTitles[0].title, null, searchtype, null));
        }else if(jobLocations.length > 0){
            dispatch(setURLjobSearchUrlActions("", null, `${jobLocations[0].cityName}, ${jobLocations[0].stateName}`, searchtype, null));
        }
    }
    
    // history.push("/jobs");
  }


  const getLocations = async (value) => {
    try {
      const response = await axios.post(`${Key.domain}/Jobs/get_joblocations`, {
        statename: value,
      });
      setJobLocations(response.data.data);
      // const newData = response.data.data.map(({ cityName, stateName }) => ({
      //   title: `${cityName}, ${stateName}`,
      //   type: 'location',
      // }));
      // setFinalData((prev) => [...prev, ...newData]);
      
    } catch (error) {
      console.error(error);
    }
  };

  const getJobTitles = async (value) => {
    try {
      const response = await axios.post(`${Key.domain}/Jobs/get_jobtitles`, {
        jobtitle: value,
      });
      setJobTitles(response.data.data);
      // const newData = response.data.data.map(({ title }) => ({
      //   title: title,
      //   type: 'jobTitle',
      // }));
      // setFinalData((prev) => [...prev, ...newData]);
    } catch (error) {
      console.error(error);
    }
  };

  const getCompanies = async (value) => {
    let searchUrl = `${Key.Solr_url}/jnp_companies/suggest?q.op=OR&q=${value}&suggest.count=30&wt=json`
    try {
      // const response = await axios.post(`${Key.domain}/solr/solr_jobs`, {
      //   url: searchUrl,
      // });
      const response = await axios.post(`${Key.domain}/company/suggestions`, {
        title: value,
      });
      // console.log('get companies', response.data.data.suggest.FuzzySuggester[value].suggestions);
      // console.log('get companies', response.data.data.map((i) => i.name));
      // let names = [];
      //  names = response.data.data.map((i) => names["title"] = i.name);
      setCompanyNames(response.data.data);
      // const newData = response.data.data.map(({ name }) => ({
      //   title: name,
      //   type: 'companyName',
      // }));
      // setFinalData((prev) => [...prev, ...newData]);
    } catch (error) {
      console.error(error);
    }
  }

  const highlightMatch = (text) => {
    let value = searchValue.toLowerCase()

    if(value.includes('c++') || value?.includes('c ++') ){
      return <span key={text}>{text}</span>
    }
      const regex = new RegExp(`(${searchValue})`, "gi");
    
      return text.split(regex).map((part, index) =>
        regex.test(part) ? (
          <span
            key={index}
            className="highlighted" 
            style={{
              color: "#1E88E5",
              //   textDecoration: "underline"
            }}
          >
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )
      );
 
  };


  const highlightMatchLocations = (text) => {
    let value = searchValue?.toLowerCase()

    if(value.includes('c++') ){
      return;
    }
    const regex = new RegExp(`(${searchValue})`, "gi");
    const parts = text.split(regex);
    return (
      <>
        {parts.map((part, index) => {
          if (regex.test(part)) {
            return (
              <span
                key={index}
                className="highlighted" 
                style={{
                  color: "#1E88E5",
                  // textDecoration: "underline"
                }}
              >
                {part}
              </span>
            );
          } else if (index % 2 === 0) {
            return part;
          } else {
            const cityState = JSON.parse(part);
            return (
              <span
                key={index}
                style={{
                  color: "#1E88E5",
                  // textDecoration: "underline"
                }}
              >
                {cityState.cityName}, {cityState.stateName}
              </span>
            );
          }
        })}
      </>
    );
  };

  
  

  const topTitles = [
    { title: "React Developer" },
    { title: "Python Developer" },
    { title: "Java Developer" },
    { title: "Devops Engineer" },
    { title: "Block Chain" },
  ];

  const topCompanies = [
    { company: "Nitya Software Solutions" },
    { company: "Adam It Corp" },
    { company: "Apex Systems" },
    { company: "TEKsystems, Inc" },
    { company: "Planet Technology" },
    { company: "Robert Half" },
    { company: "Spectraforce Technologies" },
  ];

  const topLocations = [
    { loc: "New York City, New York"},
    {loc: "Atlanta, Georgia"},
    {loc: "Texas City, Texas"},
    {loc: "California City, California"}
  ];

  const recentSearches = [
    {title: "Python Developer"},
    {title: "React Developer"},
    {title: "Vio Talk Technologies"},
    {title: "New York City, New York"},
    {title: "Galx Virginia"},
  ];

  const favorites = [
    {title: "React Developer"},
    {title: "New York City, New York"},

  ]

  return (
    <>
      <Toolbar
        sx={{
          minHeight: "60px !important",
          position: "sticky",
          top: 0,
          zIndex: 3,
          bgcolor: "#fff",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
          {/* <Box sx={{ flexGrow: 1 }} component='form' onSubmit={handleSubmit}>
            <Input
              autoFocus
              placeholder="Search by company name"
              fullWidth
              disableUnderline
              value={searchValue}
              onChange={handleInputChange}
            //   onKeyDown={handleKeyDown}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              }
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
              }}
            />
          </Box> */}
               
      <CustomTextField
        autoFocus
        placeholder="Search for job title or skills or company name or location"
        fullWidth
        disableUnderline
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleAddChip();
          }
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon color="primary" />
          </InputAdornment>
        }
        sx={{
          "& .MuiInput-underline:before": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none",
          },
        }}
      />
      <Button variant="contained" onClick={handleSubmit} sx={{fontSize: styles.fontSize13, }}>Find</Button>
        </Box>
      </Toolbar>
      <Divider />
      {(chipsTitle.length > 0 || chipsLocation.length > 0 || chipsCompany.length > 0) && <Grid container sx={{p:2}}>
       {chipsTitle.length > 0 && <Grid item xs={12}>
        <Typography
              paragraph
              sx={{
                fontSize: styles.fontSize12,
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
                m:0,
              }}
            >
              Job Titles:
            </Typography>
            <div>
        {chipsTitle.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={handleDeleteChip(chip)}
            style={{ marginRight: '5px', marginBottom: '5px', fontSize:'9px' }}
          />
        ))}
      </div>
        </Grid>}
        {chipsLocation.length > 0 && <Grid item xs={12}>
        <Typography
              paragraph
              sx={{
                fontSize: styles.fontSize12,
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
                m:0,
              }}
            >
              Location:
            </Typography>
            <div>
        {chipsLocation.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={handleDeleteChip(chip)}
            style={{ marginRight: '5px', marginBottom: '5px', fontSize:'9px' }}
          />
        ))}
      </div>
        </Grid>}
        {chipsCompany.length > 0 && <Grid item xs={12}>
        <Typography
              paragraph
              sx={{
                fontSize: styles.fontSize12,
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
                m:0,
              }}
            >
              Company:
            </Typography>
            <div>
        {chipsCompany.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={handleDeleteChip(chip)}
            style={{ marginRight: '5px', marginBottom: '5px', fontSize:'9px' }}
          />
        ))}
      </div>
        </Grid>}
      </Grid>}
      <Box sx={{ 
        // height: 400, overflow: "scroll",
         p: 2 }}>
        {searchValue && searchValue !== "" ? (
          <Grid container spacing={1}>
            <Grid item xs={4}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Job Titles
            </Typography>
            <List sx={{ maxHeight: 300, overflow: "auto" }}>
              {jobTitles.length > 0 &&
                jobTitles.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.title, "title")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      ".keyboardIcon": {
                        display: "none",
                      },
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        ".keyboardIcon": {
                          display: "block",
                        },
                        "& .highlighted": {
                          textDecoration: "underline",
                        },
                        "& .hoverColor": {
                            color:'#1E88E5'
                        },
                      },
                    }}
                  >
                    <BusinessCenterOutlined className="hoverColor"
                      sx={{ fontSize: 18, mr: 2, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatch(data.title)}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <KeyboardReturnRounded
                      sx={{ color: "#1E88E5" }}
                      className="keyboardIcon"
                    />
                  </ListItem>
                ))}
            </List>
            </Grid>
            <Grid item xs={4}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Job Locations
            </Typography>
            <List sx={{ maxHeight: 300, overflow: "auto" }}>
              {jobLocations.length > 0 &&
                jobLocations.map((data, index) => (
                  <ListItem
                    key={`loc${index}`}
                    onClick={() => jobsPageHanlder(`${data.cityName}, ${data.stateName}`, "location")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      ".keyboardIcon": {
                        display: "none",
                      },
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        ".keyboardIcon": {
                          display: "block",
                        },
                        "& .highlighted": {
                          textDecoration: "underline",
                        },
                        "& .hoverColor": {
                            color:'#1E88E5'
                        },
                      },
                    }}
                  >
                    <LocationOnOutlined className="hoverColor"
                      sx={{ fontSize: 18, mr: 2, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatchLocations(
                        `${data.cityName}, ${data.stateName}`
                      )}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <KeyboardReturnRounded
                      sx={{ color: "#1E88E5" }}
                      className="keyboardIcon"
                    />
                  </ListItem>
                ))}
            </List>
            </Grid>
            <Grid item xs={4}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Companies
            </Typography>
            <List sx={{ maxHeight: 300, overflow: "auto" }}>
              {companyNames.length > 0 &&
                companyNames.map((data, index) => (
                  <ListItem
                    key={`com${index}`}
                    onClick={() => jobsPageHanlder(data.name, "company")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      ".keyboardIcon": {
                        display: "none",
                      },
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        ".keyboardIcon": {
                          display: "block",
                        },
                        "& .highlighted": {
                          textDecoration: "underline",
                        },
                        "& .hoverColor": {
                            color:'#1E88E5'
                        },
                      },
                    }}
                  >
                    <BusinessRounded className="hoverColor"
                      sx={{ fontSize: 18, mr: 2, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatch(data.name)}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <KeyboardReturnRounded
                      sx={{ color: "#1E88E5" }}
                      className="keyboardIcon"
                    />
                  </ListItem>
                ))}
            </List>
            </Grid>
          </Grid>
        ) : (
          <>
            {/* <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Recent
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {recentSearches.length > 0 &&
                recentSearches.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.title, "title")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        "& .hoverColor": {
                            color:'#1E88E5'
                        },
                      },
                    }}
                  >
                    <HistoryOutlined className="hoverColor"
                      sx={{ fontSize: 18, mr: 1, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatch(data.title)}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    
                    <Box sx={{flexGrow:1}} />
                    <Tooltip title="Add to favorite" followCursor>
                    <StarBorderRounded sx={{color:'#1E88E5', fontSize: 18, mr:2}} />
                    </Tooltip>
                    <Tooltip title="Remove from recent" followCursor>
                    <CloseRounded sx={{color:'#1E88E5', fontSize: 18,}} />
                    </Tooltip>
                  </ListItem>
                ))}
            </List>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Favorites
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {favorites.length > 0 &&
                favorites.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.title, "title")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                        "& .hoverColor": {
                            color:'#1E88E5'
                        },
                      },
                    }}
                  >
                    <StarBorderRounded className="hoverColor"
                      sx={{ fontSize: 18, mr: 1, color: "#6f7f95" }}
                    />
                    <ListItemText
                      primary={highlightMatch(data.title)}
                      primaryTypographyProps={{ fontSize: "13px" }}
                    />
                    <Box sx={{flexGrow:1}} />
                    <Tooltip title="Remove from favorites" followCursor>
                    <CloseRounded sx={{color:'#1E88E5', fontSize: 18,}} />
                    </Tooltip>
                  </ListItem>
                ))}
            </List> */}
          </>
        )}

        <Grid container spacing={1} sx={{mt:2}}>
          <Grid item xs={4}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
             Top Job Titles
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {topTitles.length > 0 &&
                topTitles.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.title, "title")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      color: "#1E88E5",
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                      },
                    }}
                  >
                    <BusinessCenterRounded
                      sx={{ fontSize: 14, mr: 1, color: "#2196f3" }}
                    />
                    <ListItemText
                      primary={data.title}
                      primaryTypographyProps={{ fontSize: "12px" }}
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item xs={4}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
              Top Companies
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {topCompanies.length > 0 &&
                topCompanies.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.company, "company")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      color: "#1E88E5",
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                      },
                    }}
                  >
                    <BusinessCenterRounded
                      sx={{ fontSize: 14, mr: 1, color: "#2196f3" }}
                    />
                    <ListItemText
                      primary={data.company}
                      primaryTypographyProps={{ fontSize: "12px" }}
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item xs={4}>
            <Typography
              paragraph
              sx={{
                fontSize: "12px",
                letterSpacing: "0.1rem",
                color: "#576375",
                fontWeight: 600,
              }}
            >
             Top Locations
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {topLocations.length > 0 &&
                topLocations.map((data, index) => (
                  <ListItem
                    key={index}
                    onClick={() => jobsPageHanlder(data.loc, "location")}
                    sx={{
                      border: "1px solid rgba(229, 234, 242, 0.5)",
                      mb: "6px",
                      backgroundColor: "#f3f6f966",
                      borderRadius: "10px",
                      cursor: "pointer",
                      color: "#1E88E5",
                      "&:hover": {
                        color: "#1E88E5",
                        backgroundColor: "#E3F2FD",
                        border: "1px solid #64b5f6",
                      },
                    }}
                  >
                    <LocationOnRounded
                      sx={{ fontSize: 14, mr: 1, color: "#2196f3" }}
                    />
                    <ListItemText
                      primary={data.loc}
                      primaryTypographyProps={{ fontSize: "12px" }}
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SearchComponent;
