import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const columns = [
  // {
  //   field: 'id',
  //   headerName: "ID",
  //   hide: true,
  //   disableColumnMenu: true,
  //   sortable: false,
  // },
  { field: "year", headerName: "Year", width: 100 },
  { field: "initial_approval", headerName: "Initial Approval", width: 120 },
  {
    field: "continuing_approval",
    headerName: "Continuing Approval",
    width: 120,
  },
  { field: "initial_denial", headerName: "Initial Denial", width: 120 },
  { field: "continuing_denial", headerName: "Continuing Denial", width: 120 },
  { field: "approval", headerName: "Approval (%)", width: 120 },
  { field: "denial", headerName: "Denial (%)", width: 120 },
];

export default function H1B_Approvals_Denails({ companyData }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (companyData?.h1_data) {
      const newRows = companyData.h1_data.map((data, index) => {
        
        const totalApprovals = (data?.initial_approval ?? 0) + (data?.continuing_approval ?? 0);
        const totalDenials = (data?.initial_denial ?? 0) + (data?.continuing_denial ?? 0);
        const total = totalApprovals + totalDenials;
  
        
        const approvalRate = total
          ? `${((totalApprovals / total) * 100).toFixed(2)}%`
          : "0%";
        const denialRate = total
          ? `${((totalDenials / total) * 100).toFixed(2)}%`
          : "0%";
  
        return {
          id: data.id,
          year: data?.year || "-",
          initial_approval: data?.initial_approval ?? "-",
          continuing_approval: data?.continuing_approval ?? "-",
          initial_denial: data?.initial_denial ?? "-",
          continuing_denial: data?.continuing_denial ?? "-",
          approval: approvalRate,
          denial: denialRate,
        };
      });
  
      setRows(newRows); 
    }
  }, [companyData]);
  

  const handleExport = () => {
    const csvData = rows.map((row) => ({
      ID: row.id,
      Year: row.year,
      InitialApproval: row.initial_approval,
      ContinuingApproval: row.continuing_approval,
      InitialDenial: row.initial_denial,
      ContinuingDenial: row.continuing_denial,
      Approval: row.approval,
      Denial: row.denial,
    }));

    const csvContent = [
      Object.keys(csvData[0]).join(","),
      ...csvData.map((row) => Object.values(row).join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `H1B_USCIS_Approvals_${companyData?.name || ""}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Box component={Paper} sx={{ height: "auto", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        getRowId={(row) => row.id}
        // checkboxSelection
        disableRowSelectionOnClick
        // slots={{
        //   toolbar: () => (
        //     <div
        //       style={{
        //         display: "flex",
        //         justifyContent: "space-between",
        //         padding: "10px",
        //       }}
        //     >
        //       <GridToolbar />
        //       {/* https://upload.wikimedia.org/wikipedia/commons/7/73/Microsoft_Excel_2013-2019_logo.svg */}
        //       {/* <Button variant="contained" onClick={handleExport}>
        //       Export to CSV
        //     </Button> */}
        //       <Box
        //         component={Button}
        //         sx={
        //           {
        //             //   border: "1px solid #ddd",
        //             //   p: "5px 10px",
        //             //   borderRadius: "8px",
        //             // "&:hover": {
        //             //   boxShadow: "0px 10px 20px -5px rgba(6, 36, 92, 0.06)",
        //             //   transform: "translateY(-5px)",
        //             //   transition: "all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1)",
        //             // },
        //           }
        //         }
        //         onClick={handleExport}
        //       >
        //         {/* <FaLinkedin style={{ color: "#2867B2", fontSize: "30px" }} /> */}
        //         <img
        //           src="https://upload.wikimedia.org/wikipedia/commons/7/73/Microsoft_Excel_2013-2019_logo.svg"
        //           width={20}
        //         />
        //       </Box>
        //     </div>
        //   ),
        // }}
      />
    </Box>
  );
}
