import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const columns = [
  { field: "year", headerName: "Year", width: 100 },
  { field: "certified", headerName: "Certified", width: 120 },
  {
    field: "certifiedExpired",
    headerName: "Certified Expired",
    width: 120,
  },
  { field: "withdrawn", headerName: "Withdrawn", width: 120 },
  { field: "denied", headerName: "Denied", width: 120 },
  { field: "approval", headerName: "Approval (%)", width: 120 },
  { field: "denial", headerName: "Denial (%)", width: 120 },
];

export default function GC_Approvals_Denials({ companyData }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    console.log(companyData?.gc_data);
    const newRows = companyData.gc_data.map((data) => {
      const totalApplications =
        data.certified +
        data.certified_expired +
        data.denied +
        data.withdrawn;
      const approvalRate =
        totalApplications > 0
          ? ((data.certified / totalApplications) * 100).toFixed(2) + "%"
          : "-";
      const denialRate =
        totalApplications > 0
          ? ((data.denied / totalApplications) * 100).toFixed(2) + "%"
          : "-";

      return {
        id: data?.id,
        year: data?.year || "-",
        certified: data?.certified ?? "-",
        certifiedExpired: data?.certified_expired ?? "-",
        withdrawn: data?.withdrawn ?? "-",
        denied: data?.denied ?? "-",
        approval: approvalRate,
        denial: denialRate,
      };
    });
    setRows(newRows);
  }, [companyData]);

  const handleExport = () => {
    const csvData = rows.map((row) => ({
      ID: row.id,
      Year: row.year,
      Certified: row.certified,
      CertifiedExpired: row.certifiedExpired,
      Withdrawn: row.withdrawn,
      Denied: row.denied,
      Approval: row.approval,
      Denial: row.denial,
    }));

    const csvContent = [
      Object.keys(csvData[0]).join(","),
      ...csvData.map((row) => Object.values(row).join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `GC_Approvals_${companyData?.name || ""}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Box component={Paper} sx={{ height: "auto", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        getRowId={(row) => row.id}
        disableRowSelectionOnClick
        slots={{
          toolbar: () => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <GridToolbar />
              <Box
                component={Button}
                onClick={handleExport}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/7/73/Microsoft_Excel_2013-2019_logo.svg"
                  width={20}
                  alt="Export to Excel"
                />
              </Box>
            </div>
          ),
        }}
      />
    </Box>
  );
}
