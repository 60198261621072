export const solrJobsFilterData = {
  sortBy: [
    { id: 1, text: "Recent", value: "1" },
    { id: 2, text: "Relavance", value: "2" },
  ],
  jobTypes: [
    { id: 1, text: "Full-Time", value: "1" },
    { id: 2, text: "Part-Time", value: "2" },
    { id: 10, text: "Contract", value: "10 OR 5 OR 6 OR 11 OR 13 OR 14 OR 15 OR 16" },
    { id: 3, text: "Internship", value: "3" },
    { id: 4, text: "W2 - Permanent", value: "4" },
    // { id: 5, text: "W2 - Contract", value: "5" },
    // { id: 6, text: "1099-Contract", value: "6" },
    // { id: 7, text: "Contract to Hire", value: "7" },
    { id: 8, text: "Need H1B", value: "8" },
    // { id: 9, text: "Hourly Employee", value: "9" },
    // { id: 11, text: "Contract - Corp-to-Corp", value: "11" },
    // { id: 13, text: "Contract - Independent", value: "13" },
    // { id: 14, text: "Contract to Hire - Corp-to-Corp", value: "14" },
    // { id: 15, text: "Contract to Hire - W2", value: "15" },
    // { id: 16, text: "Contract to Hire - Independent", value: "16" },
  ],
  postedDate: [
    { id: 1, text: "Today", value: "1" },
    { id: 2, text: "Last Week", value: "2" },
    { id: 3, text: "Last Month", value: "3" },
    { id: 4, text: "Anytime", value: "4" },
  ],
  visaType: [
    // { id: 3, text: "H1", value: "H1" },
    { id: 13, text: "H1B", value: "H1B OR H1" },
    { id: 1, text: "GC - Green Card", value: "GC - Green Card" },
    { id: 2, text: "USC- U.S. Citizen ", value: "USC- U.S. Citizen" },
    { id: 9, text: "L1", value: "L1" },
    { id: 14, text: "L2 - EAD", value: "L2 - EAD" },
    { id: 12, text: "H4", value: "H4" },
    { id: 10, text: "GC - EAD", value: "GC - EAD" },
    { id: 11, text: "H4 - EAD", value: "H4 - EAD" },
    { id: 15, text: "OPT - EAD", value: "OPT - EAD" },
    
    
    { id: 5, text: "F1", value: "F1" },
    { id: 6, text: "TN", value: "TN" },
    { id: 4, text: "OPT - Optional Practical Training ", value: "OPT - Optional Practical Training" },
    { id: 16, text: "CPT - Curricular Practical Training", value: "CPT - Curricular Practical Training" },
    { id: 7, text: "EAD - Employment Authorization Document", value: "EAD - Employment Authorization Document" },
    { id: 8, text: "Others", value: "Others" },
  ],
  workMode: [
    { text: "Remote Only", id: 1, value: "1" },
    { text: "Onsite(Location)", id: 2, value: "2" },
  ],
  travel: [
    { text: "Not Required", value: "0", id: 0 },
    { text: "Required", value: "1", id: 1 },
  ],
};
