import { Box, Divider, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Details from "./Details";
import axios from "axios";
import Key from "../../clientVariables.json";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Navbar from "../Navbar";

const CompaniesMain = (props) => {
  const currentUser = useSelector((state) => state.UserReducer.user);
  const location = useLocation();
  const [companyData, setCompanyData] = useState();
  // console.log('location.pathname.split("comp_")[1]', location.pathname.split("comp_")[1]);
  useEffect(() => {
    if (location.pathname.includes("comp_")) {
      getCompanyDetails(location.pathname.split("comp_")[1]);
    } else if (props?.company_data) {
      setCompanyData(props?.company_data);
    }
  }, [location.pathname.split("comp_")[1], props]);

  const getCompanyDetails = async (id) => {
    try {
      const response = await axios.post(
        // `${Key.domain}/company/companies_count_details`,
        `${Key.apps_domain}/company/data`,
        {
          // companyid: id,
          company_id: id,
        }
      );
      if (response.status === 200) {
        setCompanyData(response.data.data.data);
      } else {
        toast.error("Something Went Wrtong");
      }
      // console.log("getCompanyDetails", response.data.data.data);
    } catch (error) {
      console.error(error);
      toast.error("Something Went Wrtong");
    }
  };
  return (
    <div>
      {!currentUser && (
        <Box sx={{ mb: 1 }}>
          <Navbar />
        </Box>
      )}

      <Box >
        <Details companyData={companyData} />
      </Box>
    </div>
  );
};

export default CompaniesMain;
